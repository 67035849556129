import React from 'react';
import PropTypes from 'prop-types';

import ShowDateFilter from 'components/ShowListView/ShowDateFilter';


const ShowDateFilterContainer = ({onSelect, dateFilter}) => (
  <ShowDateFilter onSelect={onSelect} dateFilter={dateFilter}/>
);

ShowDateFilterContainer.propTypes = {
  onSelect: PropTypes.func,
  dateFilter: PropTypes.string
};

ShowDateFilterContainer.defaultProps = {};

export default ShowDateFilterContainer;
