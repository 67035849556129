import React from 'react'
import { Grid, Col } from 'react-bootstrap';
import { Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

import './LoginView.css';
import { signIn, signOut } from 'lib/auth/register';
import { useDispatch, useSelector } from 'react-redux';
import { getCognitoUser } from 'selectors/user';

const LoginView = () => {
  const [loginId, setLoginId] = React.useState(null)
  const [password, setPassword] = React.useState()
  const cognitoUser = useSelector(getCognitoUser)
  const dispatch = useDispatch()
  const history = useHistory();

  React.useEffect(() => {
    if (cognitoUser) {
      history.push('/')
    }

  }, [cognitoUser, history])

  const handleSubmit = () => {
    signIn({ email: loginId, password }, (user) => dispatch({ type: 'SET_COGNITO_USER', payload: user }))
  }

  return (
    <Grid className='LoginView'>
      <Form horizontal>
        <FormGroup controlId="formHorizontalEmail">
          <Col sm={4}>
            <ControlLabel>
              Email
            </ControlLabel>
            <FormControl
              type="text"
              label="Email"
              value={loginId}
              onChange={e => setLoginId(e.target.value)}
              placeholder="Username/Email"
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalPassword">
          <Col sm={4}>
            <ControlLabel>
              Password
            </ControlLabel>
            <FormControl
              type="password"
              label="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="password"
            />
          </Col>
        </FormGroup>

        {!cognitoUser ? <Button onClick={handleSubmit}>Submit</Button> : <Button onClick={() => {
          signOut(() => dispatch({ type: 'LOG_OUT_COGNITO_USER' }))
        }}>Sign Out</Button>}
      </Form>
    </Grid>
  )
}

export default LoginView