import React from 'react';
import PropTypes from 'prop-types';

import LoadingIcon from "components/LoadingIcon";

import './CurrentTrackTime.css';

const CurrentTrackTime = ({isBufferedPlaying, time, length, className}) => {
  if (isBufferedPlaying) {
    return (
      <span className={`CurrentTrackTime${className ? ` ${className}` : ''}`}><LoadingIcon/> / {length}</span>
    )
  }
  return (
    <span className={`CurrentTrackTime${className ? ` ${className}` : ''}`}>{time} / {length}</span>
  )
};

CurrentTrackTime.propTypes = {
  time: PropTypes.string,
  length: PropTypes.string
};

CurrentTrackTime.defaultProps = {
  time: "00:00",
  length: "00:00"
};

export default CurrentTrackTime;
