import React from 'react';
import PropTypes from 'prop-types';
import './Song.css';

import Link from 'components/Link';

const jamChars = {
  stop: ", ",
  jam: " > "
};

const Song = ({name, url, jamChar, flag, className, ...props}) => (
  <span className={className ? `Song ${className}` : 'Song'} {...props}>
    <Link url={url}>{name}</Link>
    {
       flag
        ? <sup className="song-flag"> [ {flag} ]</sup>
        : <span />
    }
    { jamChars[jamChar] }
  </span>
);

Song.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  jamChar: PropTypes.oneOf(['stop', 'jam']),
  flag: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Song.defaultProps = {
};

export default Song;
