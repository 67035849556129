import ArchiveTrackHeadingContainer from './ArchiveTrackHeadingContainer';
import { connect } from 'react-redux';
import {
  getActiveRecordingTitle,
  getCurrentTrackTime,
  getCurrentTrackTitle,
  getCurrentTrackLength,
  getActiveRecordingSource, getActiveRecordingShowLink,
  getActiveArchiveRecordingLink,
  getIsBufferedPlaying
} from "selectors/mediaPlayer";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    source: getActiveRecordingSource(state),
    trackTitle: getCurrentTrackTitle(state),
    recordingTitle: getActiveRecordingTitle(state),
    trackLength: getCurrentTrackLength(state),
    trackTime: getCurrentTrackTime(state),
    isBufferedPlaying: getIsBufferedPlaying(state),
    showLink: getActiveRecordingShowLink(state),
    recordingLink: getActiveArchiveRecordingLink(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ArchiveTrackHeadingContainer);
