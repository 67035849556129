import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import { Button, Label } from 'react-bootstrap';
import './GetTicketButton.css';

const GetTicketButton = ({showDate, url, outputText, available, username}) => {
  if (available){
    return (
      <div className="GetTicketButton">
        <Link url={url}
              target="_blank"
              tracking={
                {
                  category: `Show: ${showDate}`,
                  action: "Get Tickets",
                  label: username
                }
              }
        >
          <Button className="active"><i className="fa fa-ticket"/><span>{outputText}</span></Button>
        </Link>
      </div>
    );
  } else {
    return (
      <div className="GetTicketButton">
        <Label><i className="fa fa-ticket"/><span>{outputText}</span></Label>
      </div>
    );
  }


};

GetTicketButton.propTypes = {
  showDate: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  outputText: PropTypes.string.isRequired,
  available: PropTypes.bool,
  username: PropTypes.string,
};

GetTicketButton.defaultProps = {
  available: false,
  username: "unknown",
};

export default GetTicketButton
