import React from 'react';
import PropTypes from 'prop-types';

import StateFilter from 'components/ShowListView/StateFilter';

const StateFilterContainer = ({onSelect, filter, states}) => (
  <StateFilter onSelect={onSelect} filter={filter} states={states}/>
);

StateFilterContainer.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string,
  states: PropTypes.array
};

StateFilterContainer.defaultProps = {
  states: ["NJ", "NY", "AZ", "WI", "CA", "NM"]
};

export default StateFilterContainer;
