import { getActiveRecording, getIsPlaying } from './mediaPlayer';
import { secondsToHHMMSS } from "lib/time";
import sort from "lib/sort";

export const getCurrentTrack = (state) => {
  return state.mediaPlayer.currentTrack;
};

export const getCurrentTrackTitle = (state) => {
  const currentTrack = getCurrentTrack(state);
  return currentTrack ? currentTrack.title : '';
};

export const getCurrentTrackLength = (state) => {
  const currentTrack = getCurrentTrack(state);
  return currentTrack ? currentTrack.length : '';
};

export const getCurrentTrackTime = (state) => {
  return secondsToHHMMSS(state.mediaPlayer.currentTime);
};

export const getNextTrack = (state) => {
  const currentTrack = getCurrentTrack(state);
  if (!currentTrack) {
    return null
  }
  const audioTracks = getActiveArchiveTracks(state);

  const currTrackIdx = audioTracks.map(track => track.name).indexOf(currentTrack.name);
  return currTrackIdx < audioTracks.length - 1 ? audioTracks[currTrackIdx + 1] : null;
};

export const getPrevTrack = (state) => {
  const currentTrack = getCurrentTrack(state);
  if (!currentTrack) {
    return null
  }
  const audioTracks = getActiveArchiveTracks(state);

  const currTrackIdx = audioTracks.map(track => track.name).indexOf(currentTrack.name);
  return currTrackIdx > 0 ? audioTracks[currTrackIdx - 1] : null;
};

export const getArchiveAudioFormat = (state) => {
  //TODO: Store this in state in some meaningful way
  return "VBR MP3";
};

export const getActiveArchiveTracks = (state) => {
  const activeRecording = getActiveRecording(state);
  if (! activeRecording ) {
    return null
  }

  const audioFormat = getArchiveAudioFormat(state);

  if ( activeRecording.source !== 'Archive'){
    return null;
  }

  const files = activeRecording
    && activeRecording.recordingData
    && activeRecording.recordingData.metadata
    && activeRecording.recordingData.metadata
    && activeRecording.recordingData.metadata.files
    && activeRecording.recordingData.metadata.files.audio
    && activeRecording.recordingData.metadata.files.audio[audioFormat];

  if (! files ) {
    return files;
  }

  const tracks = activeRecording.recordingData.metadata.files.audio[audioFormat].map(fileInfo => {
    const { track, length, sortKey, ...info } = fileInfo;
    return {
      'length': length.includes(':') ? length : secondsToHHMMSS(length),
      'track': parseInt(track, 10),
      'sortKey': parseInt(sortKey, 10) || ( sortKey === "0" ? 0 : sortKey ),
      ...info
    }
  });

  return tracks.sort(sort.sortKey.id_asc)
};

export const getActiveArchiveCurrentTrackURL = (state) => {
  const activeRecording = getActiveRecording(state);
  const currentTrack = getCurrentTrack(state);
  return currentTrack
    ? `https://archive.org/download/${activeRecording.recordingData.identifier}/${currentTrack.name}`
    : null
};

export const getActiveArchiveRecordingLink = (state) => {
  const activeRecording = getActiveRecording(state);

  return activeRecording && activeRecording.recordingData && activeRecording.recordingData.identifier
    ? `https://archive.org/details/${activeRecording.recordingData.identifier}`
    : ""
};

export const getIsBufferedPlaying = state => {
  return getIsPlaying(state) && state.mediaPlayer.currentTime === 0;
};
