// Because you can't use regexes as keys in object literal syntax
//  we need another way to define the replacements
//  https://stackoverflow.com/questions/7365027/how-to-use-a-regexp-literal-as-object-key
// We'll use an array of arrays, each subarray is basically a key/value pair
//  position 0 is the regex (the key) and position 1 is the response object to return
//  to performe the redirection
//  for example: [/^foo$/g, { status: 301, location: '/bar'}]
const redirects = [
  [/^\/shows\/2018-04-21$/, {
    routerPath: '/shows/2018-04-21',
    status: 301,
    headers: {
      location: [{
        key: 'Location',
        value: '/shows/2018-04-20/1'
      }]
    }
  }],
  [/^\/shows\/2017-09-03$/, {
    routerPath: '/shows/2017-09-03',
    status: 301,
    headers: {
      location: [{
        key: 'Location',
        value: '/shows/2017-09-02/1'
      }]
    }
  }],
  [/^\/songs\/dirt-bath$/, {
    routerPath: '/songs/dirt-bath',
    status: 301,
    headers: {
      location: [{
        key: 'Location',
        value: '/songs/dirtbath'
      }]
    }
  }],
  [/^\/alexa$/, {
    routerPath: '/alexa',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://forum.spaffnerds.com/topic/703/spaffnerds-alexa-skill'
      }]
    }
  }],
  [/^\/privacy$/, {
    routerPath: '/privacy',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://forum.spaffnerds.com/topic/704/terms-of-service-privacy-policy'
      }]
    }
  }],
  [/^\/privacy-policy$/, {
    routerPath: '/privacy-policy',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://forum.spaffnerds.com/topic/704/terms-of-service-privacy-policy'
      }]
    }
  }],
  [/^\/tos$/, {
    routerPath: '/tos',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://forum.spaffnerds.com/topic/1/terms-of-service-privacy-policy'
      }]
    }
  }],
  [/^\/terms-of-service$/, {
    routerPath: '/terms-of-service',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://forum.spaffnerds.com/topic/1/terms-of-service-privacy-policy'
      }]
    }
  }],
  [/^\/help$/, {
    routerPath: '/help',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://forum.spaffnerds.com/category/9/help'
      }]
    }
  }],
  [/^\/store$/, {
    routerPath: '/store',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://store.spaffbase.com/'
      }]
    }
  }],
  [/^\/youtube$/, {
    routerPath: '/youtube',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://www.youtube.com/channel/UCTZnzVFq9shpJxqoDFP7Dyg/playlists'
      }]
    }
  }],
  [/^\/facebook$/, {
    routerPath: '/facebook',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://facebook.com/spaffnerds'
      }]
    }
  }],
  [/^\/twitter$/, {
    routerPath: '/twitter',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://twitter.com/spaffnerds_com'
      }]
    }
  }],
  [/^\/tour-games$/, {
    routerPath: '/tour-games',
    status: 303,
    headers: {
      location: [{
        key: 'Location',
        value: 'https://forum.spaffnerds.com/category/18/tour-games'
      }]
    }
  }],
];

module.exports = {
  redirects: redirects
};

