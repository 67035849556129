import sort from 'lib/sort';
import { SongTypeFilters } from 'actions/songs';

const applySongTypeFilter = (songs, filter) => {
  switch (filter){
    case SongTypeFilters.ORIGINAL:
      return songs.filter(song => song.writtenBy.band === 'Spafford' && !song.isJam);
    case SongTypeFilters.COVER:
      return songs.filter(song => song.writtenBy.band !== 'Spafford');
    case SongTypeFilters.JAM:
      return songs.filter(song => song.isJam);
    default:
      return songs
  }
};

const applyFilters = (songs, state) => {
  let filtered = songs;
  filtered = applySongTypeFilter(filtered, getSongTypeFilter(state));
  return filtered;
};

const applySort = (songs) => {
  let sorted = songs;
  sorted = sorted.sort(sort.songs.id_asc);
  return sorted;
};

export const getSongTypeFilter = state => {
  return state.songs && state.songs.songTypeFilter
};

export const sortAndFilterSongs = state => {
  const songList = getSongs(state);
  return applySort(applyFilters(songList, state))
};

export const isSongListFetching = state => {
  return state.songs && state.songs.list && state.songs.list.isFetching
};

export const getSongs = state => {
  return (state.songs && state.songs.list && state.songs.list.items) || [];
};

export const getSelectedSong = state => {
  return state.songs && state.songs.selected
};

export const getSelectedSongId = state => {
  const { id } = getSelectedSong(state) || {};
  return id;
};

export const getSelectedSongIndex = state => {
  const songs = sortAndFilterSongs(state);
  const selectedSongId = getSelectedSongId(state);
  const songIndex = songs.findIndex(song => song._id === selectedSongId);
  if (songIndex < 0){
    return null
  }

  return songIndex;
};

export const getNextSong = state => {
  const selectedIndex = getSelectedSongIndex(state);
  // if we didn't find one, or it's the last one
  if (selectedIndex === null || selectedIndex === getSongs(state).length - 1){
    return null
  }
  const sortedSongs = sortAndFilterSongs(state);
  return sortedSongs[selectedIndex + 1];
};

export const getPrevSong = state => {
  const selectedIndex = getSelectedSongIndex(state);
  // if we didn't find one, or it's the first
  if (selectedIndex === null || selectedIndex === 0){
    return null
  }
  const sortedSongs = sortAndFilterSongs(state);
  return sortedSongs[selectedIndex - 1];
};

export const getSongStat = state => {
  const selectedId = getSelectedSongId(state);
  const stats = state.songs.stats;
  if (! stats || ! stats[selectedId]){
    return undefined
  }
  return stats[selectedId].stat;
};

export const getSongStatError = (state) => {
  const selectedId = getSelectedSongId(state);
  const stats = state.songs.stats;
  if (! stats || ! stats[selectedId]){
    return undefined
  }
  const stat = stats[selectedId];
  if ( stat.isFetching || ! stat.isError ) {
    return undefined
  }
  return stat.error;
};
