import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, HelpBlock, FormGroup } from 'react-bootstrap';
import './UserSubmission.css';

class UserSubmission extends Component {
  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {}
  }
  handleChange(id, e){
    const { value } = e.target;
    this.setState({[id]: value});
  }

  handleClick(e) {
    const { onSubmit } = this.props;
    onSubmit(e, this.state)
  }

  render() {
    const { inputs, onCancel, disabled } = this.props;
    const { value } = this.state;
    return (
      <div className="UserSubmission">
        { inputs.map((input) => {
          const { id, label, help } = input;
          return (
            <FormGroup key={id} controlId={id}>
              <ControlLabel>{label}</ControlLabel>
              <FormControl {...input}
                disabled={disabled}
                onChange={(e) => this.handleChange(id, e)}
                value={value}
              />
              {help && <HelpBlock>{help}</HelpBlock>}
            </FormGroup>
          )})
        }
        <div className='pull-right'>
          { disabled
            ? <Button className='spinner'><i className='fa fa-spinner fa-spin'/></Button>
            : [ <Button key='cancel' className='active' onClick={onCancel}>Cancel</Button>,
                <Button key='submit' onClick={this.handleClick}>Submit</Button> ]
          }
        </div>
      </div>
    )
  }
}

UserSubmission.propTypes = {
  inputs: PropTypes.array,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string
};

UserSubmission.defaultProps = {
  inputs: []
};

export default UserSubmission;
