import React from 'react';
import PropTypes from 'prop-types';

import VenueFilter from 'components/ShowListView/VenueFilter';

const VenueFilterContainer = ({onSelect, filter, venues}) => (
  <VenueFilter onSelect={onSelect} filter={filter} venues={venues}/>
);

VenueFilterContainer.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string,
  venues: PropTypes.array
};

VenueFilterContainer.defaultProps = {
  venues: []
};

export default VenueFilterContainer;
