import React from 'react';
import PropTypes from 'prop-types';

import MPArchiveTitleContainer from 'containers/MediaPlayerContainer/MPArchiveTitleContainer/index';
import MPYouTubeTitleContainer from 'containers/MediaPlayerContainer/MPYouTubeTitleContainer/index';

const MediaPlayerTitleContainer = ({source}) => {
  switch(source) {
    case "YouTube":
      return (
        <MPYouTubeTitleContainer className="hidden-xs"/>
      );
    case "Archive":
      return (
        <MPArchiveTitleContainer />
      );
    default:
      return null
  }
};

MediaPlayerTitleContainer.propTypes = {
  source: PropTypes.oneOf(['YouTube', 'Archive'])
};

MediaPlayerTitleContainer.defaultProps = {
};

export default MediaPlayerTitleContainer;
