import React from 'react';
import Helmet from 'react-helmet';
import { Grid } from 'react-bootstrap';
// import PropTypes from 'prop-types';

import DetailNavRow from "components/DetailView/DetailNavRow";
import DetailSlugRow from "components/DetailView/DetailSlugRow";
import DetailContentRow from "components/DetailView/DetailContentRow/DetailContentRow";
import ShowYearCard from "components/UserDetailView/ShowYearCard/ShowYearCard";
// import UserSongStats from "components/UserDetailView/UserSongStats";
import LoadingIcon from 'components/LoadingIcon';

import './LoadingUserDetailView.css';
import StatCard from "components/UserDetailView/StatCard/StatCard";

const DummySongCard = () => {
  return (
    <StatCard
      count={<LoadingIcon size={20}/>}
      text="Song"
      cardBody={<div/>}
      cardHeader={
        <div>
          <h3>
            Loading <LoadingIcon/>
          </h3>
        </div>
      }/>
  );
};

const LoadingUserDetailView = () => {
  return (
    <Grid className="LoadingUserDetailView">
      <Helmet>
        <title>User Stats</title>
      </Helmet>
      <DetailNavRow placement="user">
        <span><LoadingIcon size={20}/> Loading <LoadingIcon size={20}/></span>
      </DetailNavRow>
      <DetailSlugRow>
        <span>shows attended</span>
      </DetailSlugRow>
      <DetailContentRow>
        <ShowYearCard year={<span>XXXX</span>} shows={[]} count={<LoadingIcon size={20}/>} />
        <ShowYearCard year={<span>XXXX</span>} shows={[]} count={<LoadingIcon size={20}/>} />
        <ShowYearCard year={<span>XXXX</span>} shows={[]} count={<LoadingIcon size={20}/>} />
      </DetailContentRow>
      <DetailContentRow>
        <div>
          <div className="UserSongStats">
            <h3>Song Counts</h3>
            <DummySongCard/>
            <DummySongCard/>
            <DummySongCard/>
            <DummySongCard/>
            <DummySongCard/>
            <DummySongCard/>
          </div>
        </div>
      </DetailContentRow>
    </Grid>
  )
};

LoadingUserDetailView.propTypes = {
};

LoadingUserDetailView.defaultProps = {
};

export default LoadingUserDetailView;
