import SharingMenuContainer from './SharingMenuContainer';
import { connect } from 'react-redux';
import {getUsername} from "selectors/user";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: getUsername(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SharingMenuContainer);
