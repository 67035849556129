export const getAll = state => {
  return state.userRecordings
};

export const isError = state => {
  return state.userRecordings.isError;
};

export const isFetching = state => {
  return state.userRecordings.isFetching;
};

export const isSuccess = state => {
  return ! isError(state) && ! isFetching(state) && state.userRecordings.data
};