import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ShowListView from 'components/ShowListView';

import { ShowDateFilters } from 'actions/shows';
import LoadingShowListView from "components/ShowListView/LoadingShowListView/LoadingShowListView";

//TODO: Convert to const =>
class ShowListViewContainer extends Component {
 render(){
    const { showList, isFetching, recordingFilter } = this.props;

    if (isFetching){
      return <LoadingShowListView/>
    } else {
      return (
        <ShowListView
          showList={showList}
          recordingFilter={recordingFilter}
        />
      )
    }
  }
}

ShowListViewContainer.propTypes = {
  isFetching: PropTypes.bool,
  showList: PropTypes.array,
  recordingsFilter: PropTypes.string,
  dateFilter: PropTypes.oneOf(Object.keys(ShowDateFilters)),
  dateFilterValue: PropTypes.string
};

ShowListViewContainer.defaultProps = {};

export default ShowListViewContainer;
