import React from 'react';
import PropTypes from 'prop-types';

import './LoadingIcon.css';

import ellipses from './ellipses.gif';

const LoadingIcon = ({size}) => {
  return <img className="LoadingIcon" height={size} width={size} src={ellipses} alt="loading" />
};

LoadingIcon.propTypes = {
  size: PropTypes.number
};

LoadingIcon.defaultProps = {
  size: 20
};

export default LoadingIcon;
