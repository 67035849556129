export const SET_ACTIVE_RECORDING = 'SET_ACTIVE_RECORDING';
export const CLEAR_ACTIVE_RECORDING = 'CLEAR_ACTIVE_RECORDING';
export const SET_CURRENT_TRACK = 'SET_CURRENT_TRACK';
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME';
export const SET_PLAY_STATUS = 'SET_PLAY_STATUS';

export function setActiveRecording(recording, venue) {
    const recCopy = Object.assign({}, recording);
    recCopy.venue = venue;
    return {
        type: SET_ACTIVE_RECORDING,
        recording: recCopy
    }
}
export function clearActiveRecording() {
    return {
        type: CLEAR_ACTIVE_RECORDING
    }
}
//TODO: use index and file type
export function setCurrentTrack(track) {
    return {
        type: SET_CURRENT_TRACK,
        track: track
    }
}
export function setCurrentTime(time) {
    return {
        type: SET_CURRENT_TIME,
        time: time
    }
}
export function setPlayStatus(isPlaying) {
    return {
        type: SET_PLAY_STATUS,
        isPlaying: isPlaying
    }
}