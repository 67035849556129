export const secondsToHHMMSS = (numberOfSeconds)  => {
  let hours   = Math.floor(numberOfSeconds / 3600);
  let minutes = Math.floor((numberOfSeconds - (hours * 3600)) / 60);
  let seconds = Math.floor(numberOfSeconds - (hours * 3600) - (minutes * 60));

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}

  return hours > 0
    ? `${hours}:${minutes}:${seconds}`
    : `${minutes}:${seconds}`;
};

