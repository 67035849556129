import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import Link from 'components/Link';
import PayPalDonateContainer from 'containers/PayPalDonateContainer';
import './NavigationLinks.css';

const NavigationLinks = ({ forumUrl, isLoggedIn, className, slideout }) => {
  return (
    <ul
      className={
        className ? `NavigationLinks ${className}` : 'NavigationLinks nav'
      }
    >
      <li>
        <Tooltip text='Shows' placement='bottom' className='hidden-xs'>
          <Link
            url='/shows'
            title='Shows'
            onClick={() => slideout && slideout.close()}
          >
            <i className='fa fa-fw fa-list-ol' />
            <span className='visible-xs-inline'>Shows</span>
          </Link>
        </Tooltip>
      </li>
      <li>
        <Tooltip text='Songs' placement='bottom' className='hidden-xs'>
          <Link
            url='/songs'
            title='Songs'
            onClick={() => slideout && slideout.close()}
          >
            <i className='fa fa-fw fa-music' />
            <span className='visible-xs-inline'>Songs</span>
          </Link>
        </Tooltip>
      </li>
      <li>
        <Tooltip text='Forum' placement='bottom' className='hidden-xs'>
          <Link url={`${forumUrl}/categories`} title='Forum'>
            <i className='fa fa-fw fa-list' />
            <span className='visible-xs-inline'>Forum</span>
          </Link>
        </Tooltip>
      </li>
      <li>
        <Tooltip text='Store' placement='bottom' className='hidden-xs'>
          <Link
            url={`/store`}
            title='Store'
            tracking={{
              label: 'Store',
              action: 'Store',
              category: 'Store'
            }}
          >
            <i className='fa fa-fw fa-shopping-cart' />
            <span className='visible-xs-inline'>Store</span>
          </Link>
        </Tooltip>
      </li>
      <li>
        <Tooltip text='Tour Games' placement='bottom' className='hidden-xs'>
          <Link
            url={'/tourgames'}
            title='Tour Games'
            tracking={{
              label: 'TourGames',
              action: 'TourGames',
              category: 'TourGames'
            }}
            onClick={() => slideout && slideout.close()}
          >
            <i className='fa fa-fw fa-gamepad' />
            <span className='visible-xs-inline'>Tour Games</span>
          </Link>
        </Tooltip>
      </li>
      {!isLoggedIn ? (
        ''
      ) : (
        <li>
          <Tooltip text='Unread' placement='bottom' className='hidden-xs'>
            <Link url={`${forumUrl}/unread`} id='unread-count' title='Unread'>
              <i className='fa fa-fw fa-inbox unread-count' />
              <span className='visible-xs-inline'>Unread</span>
            </Link>
          </Tooltip>
        </li>
      )}
      <li>
        <Tooltip text='Recent' placement='bottom' className='hidden-xs'>
          <Link url={`${forumUrl}/recent`} title='Recent'>
            <i className='fa fa-fw fa-clock-o' />
            <span className='visible-xs-inline'>Recent</span>
          </Link>
        </Tooltip>
      </li>
      {isLoggedIn ? (
        ''
      ) : (
        <li className='visible-xs-inline'>
          <Link url={`${forumUrl}/register`} title='Register'>
            <i className='fa fa-fw fa-pencil' />
            <span className='visible-xs-inline'>Register</span>
          </Link>
        </li>
      )}
      {isLoggedIn ? (
        ''
      ) : (
        <li className='visible-xs-inline'>
          <Link url={`${forumUrl}/login`} title='Login'>
            <i className='fa fa-fw fa-sign-in' />
            <span className='visible-xs-inline'>Login</span>
          </Link>
        </li>
      )}
      <li className='visible-xs-inline'>
        <PayPalDonateContainer className='payPalMobile' buttonText='Donate' />
      </li>
    </ul>
  );
};

NavigationLinks.propTypes = {
  forumUrl: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  className: PropTypes.string
};

NavigationLinks.defaultProps = {
  forumUrl: 'https://forum.spaffnerds.com',
  isLoggedIn: false
};

export default NavigationLinks;
