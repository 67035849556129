import React from 'react';
import PropTypes from 'prop-types';

import ContentButton from '../../ContentButton';

import './RecordingButton.css';

import audioIcon from 'images/recordingicons/audio.png';
import mixlrIcon from 'images/recordingicons/mixlr.png';
import nugsIcon from 'images/recordingicons/nugs.png';
import soundcloudIcon from 'images/recordingicons/soundcloud.svg';
import videoIcon from 'images/recordingicons/video.png';
import youtubeIcon from 'images/recordingicons/youtube.svg';
import ClientSideButton from '../../../ClientSideButton/ClientSideButton';

//TODO: Perhaps make some sort of recording button factory or content buttons config factory within recording button
const RecordingButton = ({recording, venue, type, date, clickHandler, isPlaying, isLive}) => {
  const textFn = {
    Audio: (rec) => rec.recordingData.description,
    Mixlr: (rec) => {
      switch (recording.recordingType){
        case "Aud":
          switch ((rec.recordingData.content || '').toLowerCase()){
            case 'stream':
              return 'Live Stream';
            case 'hidden':
              return `Admin Only: ${rec.recordingData.username}`;
            default:
              return "Aud: " + rec.recordingData.username;
          }
        case "Soundboard":
          return "Soundboard";
        default:
          return "Other";
      }
    },
    "nugs.net": (rec) => {
      switch (rec.recordingType){
        case "Soundboard":
          return "nugs.net Audio";
        case "Video":
          return "nugs.net Video";
        default:
          return "Other";
      }
    },
    SoundCloud: (rec) => {
      return Array.isArray(rec.recordingData.content)
        ? "Partial Show"
        : "Full Show"
    },
    Video: (rec) => rec.recordingData.description,
    YouTube: (rec) => {
      if (rec.recordingData.content.includes("Stream")) {
        return rec.recordingData.content
      }
      return Array.isArray(rec.recordingData.content)
        ? "Partial Show"
        : "Full Show"
    }
  };

  switch(type) {
    case "Audio":
      return (
        <ContentButton className="RecordingButton"
                      img={audioIcon}
                      alt={type}
                      href={recording.recordingData.url}
                      text={textFn[type](recording)}
                      target="_blank"
                      tracking={{category: `Show: ${date}`, action: 'Listen', label: recording.recordingData.url}}>
        </ContentButton>
      )
    case "Mixlr":
      return (
        <ContentButton className="RecordingButton"
                      img={mixlrIcon}
                      alt={type}
                      href={recording.recordingData.url}
                      text={textFn[type](recording)}
                      target="_blank"
                      tracking={{category: `Show: ${date}`, action: 'Listen', label: recording.recordingData.url}}>
        </ContentButton>
      )
    case "nugs.net":
      let nugsUrl = `http://nugs.net/${recording.recordingData.containerID}.html`;
      return (
        <ContentButton className="RecordingButton"
                      img={nugsIcon}
                      alt={type}
                      href={nugsUrl}
                      text={textFn[type](recording)}
                      target="_blank"
                      tracking={{category: `Show: ${date}`, action: 'Listen', label: nugsUrl}}>
        </ContentButton>
      )
    case "SoundCloud":
      return (
        <ContentButton className="RecordingButton"
                      img={soundcloudIcon}
                      alt={type}
                      href={recording.recordingData.url}
                      text={textFn[type](recording)}
                      target="_blank"
                      tracking={{category: `Show: ${date}`, action: 'Listen', label: recording.recordingData.url}}>
        </ContentButton>
      )
    case "Video":
      return (
        <ContentButton className="RecordingButton"
                      img={videoIcon}
                      alt={type}
                      href={recording.recordingData.url}
                      text={textFn[type](recording)}
                      target="_blank"
                      tracking={{category: `Show: ${date}`, action: 'Listen', label: recording.recordingData.url}}>
        </ContentButton>
      )
    case "YouTube":
      return (
        <ClientSideButton className="RecordingButton"
                      img={youtubeIcon}
                      alt={type}
                      onClick={() => { clickHandler(recording); return false; }}
                      text={textFn[type](recording)}
                      tracking={{category: `Show: ${date}`, action: 'Listen', label: recording.recordingData.url}}>
          <i className={`play-status fa ${isPlaying ? 'fa-stop' : 'fa-play'}`} />
        </ClientSideButton>
      )
    default:
      break;
  }  
};

RecordingButton.propTypes = {
  type: PropTypes.oneOf([
    "Archive", "Audio", "Mixlr",
    "nugs.net", "SoundCloud", "Video",
    "YouTube"]).isRequired,
  recording: PropTypes.shape({
    recordingType: PropTypes.oneOf(['Soundboard', 'Aud', 'Video', 'Audio']),
    recordingData: PropTypes.shape({
      data: PropTypes.string,
      description: PropTypes.string,
      publisher: PropTypes.string,
      username: PropTypes.string,
      pageUrl: PropTypes.string,
      url: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string
      ])
    })
  }).isRequired,
  date: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  playIcon: PropTypes.string
};

RecordingButton.defaultProps = {};

export default RecordingButton;
