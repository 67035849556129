import React from "react";
import PropTypes from "prop-types";
import tracker from 'lib/tracker';

import "./PayPalDonate.css";

const PayPalDonate = ({ payPalAccount, className, buttonText,
                        user,
                        onMouseOver, onMouseOut, onFocus, onBlur, onClick }) => {

  let submitButton = undefined;
  const registerSubmitButton = (button) => {
    submitButton = button;
  };

  return (
    <div className={`PayPalDonate${className ? ` ${className}` : ''}`}
         onMouseOver={(e) => onMouseOver && onMouseOver(e)}
         onMouseOut={(e) => onMouseOut && onMouseOut(e)}
         onFocus={(e) => onFocus && onFocus(e)}
         onBlur={(e) => onBlur && onBlur(e)}
         onClick={(e) => {
           submitButton && submitButton.click();
           onClick && onClick(e);
         }}
    >
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_blank"
      >
        {/* paypal account credentials (account email) */}
        <input type="hidden" name="business" value={payPalAccount || "damian@peaceofsoftware.com"} />

        {/* < Specify a Donate button. */}
        <input type="hidden" name="cmd" value="_donations" />

        {/* <!-- Specify details about the contribution --> */}
        <input type="hidden" name="item_name" value="Spaffnerds.com" />
        <input
          type="hidden"
          name="item_number"
          value="Of Nerds, By Nerds, and For Nerds."
        />
        <input type="hidden" name="currency_code" value="USD" />

        {/* <!-- Display the payment button. --> */}
        <button
          type="submit"
          alt="Donate"
          className="donateButton"
          ref={(btn) => registerSubmitButton(btn)}
          onClick={() => tracker.trackEvent("Donate", "PayPal", user)}
        >
          <i className="fa fa-paypal" aria-hidden="true" />
          {buttonText && <span className="payPalButtonText">{buttonText}</span>}
        </button>
        <img
          alt=""
          width="1"
          height="1"
          src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
        />
      </form>
    </div>
  )
};

PayPalDonate.propTypes = {
  payPalAccount: PropTypes.string,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  user: PropTypes.string
};

PayPalDonate.defaultProps = {
  user: undefined
};

export default PayPalDonate
