import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import './YouTubeTitle.css';
import TextTicker from "components/TextTicker/TextTicker";
import Link from "components/Link";

const YouTubeTitle = ({title, content, showLink, className}) => {
  return (
    <Col>
      <span className={`YouTubeTitle${className ? ` ${className}` : ''}`}>
        <span className="hidden-xs">
          {
            Array.isArray(content)
              ? content.join(", ")
              : content
          }
          <Link className="show-link" onClick={(e) => { e.stopPropagation() }} url={showLink}>
            <span className="show-date">{title}</span>
          </Link>
        </span>
        <span className="hidden-sm hidden-md hidden-lg">
          <div className="text-center">
          {
            Array.isArray(content)
              ? content.join(", ")
              : content
          }
          </div>
          <hr className='break' />
          <Link className="show-link" onClick={(e) => { e.stopPropagation() }} url={showLink}>
            <TextTicker items={[title]}/>
          </Link>
        </span>
      </span>

    </Col>
  )
};

YouTubeTitle.propTypes = {
  content: PropTypes.node
};

YouTubeTitle.defaultProps = {
  content: "Spafford Live Video"
};

export default YouTubeTitle;
