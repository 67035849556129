import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'react-bootstrap';
import StatsDropdownButton from 'components/UserDetailView/StatsDropdownButton';

import './StatCard.css';

const StatCard = ({count, text, cardHeader, cardBody, className }) => {
  return (
    <Col xs={12} sm={6} md={4}>
      <div className={className ? `StatCard ${className}` : 'StatCard'}>
        <StatsDropdownButton count={count} text={text}>
          <div className="card" data-slideout-ignore>
            <div className="card-header">
              {cardHeader}
            </div>
            <div className="card-body">
              {cardBody}
            </div>
          </div>
        </StatsDropdownButton>
      </div>
    </Col>
  )
};

StatCard.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  cardHeader: PropTypes.node.isRequired,
  cardBody: PropTypes.node.isRequired,
  className: PropTypes.string
};

StatCard.defaultProps = {
};

export default StatCard;
