import ArchiveRecordingButtonContainer from './ArchiveRecordingButtonContainer';
import { connect } from 'react-redux';
import {getVisibleShow} from "selectors/shows";
import {fetchShowRecordings} from "actions/shows";
import {
  getActiveArchiveTracks,
  isActiveRecordingFetchingTracks,
  isRecordingPlaying
} from "selectors/mediaPlayer";
import {setActiveRecording, setPlayStatus} from 'actions/mediaPlayer';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fnSetRecording: (recording, venue) => {
      dispatch(setActiveRecording(recording, venue));
      dispatch(setPlayStatus(true));
    },
    fnFetchRecordingData: showId => {
      console.warn(`fnFetchRecData: ${showId}`);
      dispatch(fetchShowRecordings(showId));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    show: getVisibleShow(state),
    tracks: getActiveArchiveTracks(state),
    isPlaying: isRecordingPlaying(state, ownProps.recording),
    isFetching: isActiveRecordingFetchingTracks(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ArchiveRecordingButtonContainer);
