import LoadingNavButtonContainer from './LoadingNavButtonContainer';
import { connect } from 'react-redux';
import {isShowListFetching} from "selectors/shows";
import {isSongListFetching} from "selectors/songs";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  const { placement } = ownProps;
  let isFetching = () => false;
  switch (placement) {
    case "show":
      isFetching = isShowListFetching;
      break;
    case "song":
      isFetching = isSongListFetching;
      break;

    default:
      break;
  }
  return {
    isLoading: isFetching(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoadingNavButtonContainer);
