import { API_POST } from 'middleware/api/post';

export const TOUR_GAMES_RUN_CALCULATE_REQUEST =
  'TOUR_GAMES_RUN_CALCULATE_REQUEST';
export const TOUR_GAMES_RUN_CALCULATE_SUCCESS =
  'TOUR_GAMES_RUN_CALCULATE_SUCCESS';
export const TOUR_GAMES_RUN_CALCULATE_FAILURE =
  'TOUR_GAMES_RUN_CALCULATE_FAILURE';

/**
 * Calls the API endpoint to run the caluclation on a given show
 * @param {String} tourId
 * @param {String} showId
 * @param {String} gameType
 * @returns
 */
export function runCalculate(tourId, showId, gameType = 'SpaffnerdsTourGames') {
  return {
    [API_POST]: {
      authenticated: true,
      endpoint: `/tourGames/admin/${tourId}/${gameType}/calculate/${showId}`,
      shouldDispatch: function (store) {
        return true;
      },
      types: [
        TOUR_GAMES_RUN_CALCULATE_REQUEST,
        TOUR_GAMES_RUN_CALCULATE_SUCCESS,
        TOUR_GAMES_RUN_CALCULATE_FAILURE
      ]
    }
  };
}
