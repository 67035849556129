import { SET_ADD_RECORDING, CLEAR_ADD_RECORDING } from "actions/recordings";

function recordings(state = {}, action) {
  switch (action.type) {
    case SET_ADD_RECORDING:
      return {
        addRecording: true,
        streamOnly: !! action.streamOnly
      };
    case CLEAR_ADD_RECORDING:
      return {};
    default:
      return state
  }
}

export default recordings;