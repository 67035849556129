import { combineReducers } from 'redux';
import {
  INVALIDATE_ATTENDANCE,
  ATTENDANCE_REQUEST,
  ATTENDANCE_SUCCESS,
  ATTENDANCE_FAILURE
} from 'actions/attendance'

const initialAttendanceState = () => ({
  isFetching: false,
  didInvalidate: false,
  items: []
});

function attendanceShowId(state = initialAttendanceState(), action){
  switch (action.type){
    case INVALIDATE_ATTENDANCE:
      return Object.assign({}, state, {
        isError: false,
        didInvalidate: true
      });
    case ATTENDANCE_REQUEST:
      return Object.assign({}, state[action.id], {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case ATTENDANCE_SUCCESS:
      return Object.assign({}, state[action.id], {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        items: action.response,
        lastUpdate: action.receivedAt
      });
    case ATTENDANCE_FAILURE:
      return Object.assign({}, state[action.id], {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });
    default:
      return state;
  }
}
function attendanceByShowId(state = {}, action) {
  switch (action.type){
    case INVALIDATE_ATTENDANCE:
    case ATTENDANCE_REQUEST:
    case ATTENDANCE_SUCCESS:
    case ATTENDANCE_FAILURE:
      return Object.assign({}, state, {
        [action.id]: attendanceShowId(state[action.id], action)
      });
    default:
      return state
  }
}

const attendance = combineReducers({
  byShowId: attendanceByShowId
});

export default attendance;
