import { API_GET } from "middleware/api/get";

export const SONG_REQUEST = 'SONG_REQUEST';
export const SONG_SUCCESS = 'SONG_SUCCESS';
export const SONG_FAILURE = 'SONG_FAILURE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function fetchSongs() {
  return {
    [API_GET]: {
      endpoint: '/songs',
      shouldDispatch: function(store) {
        const songs = store.getState().songs.list;
        if (! songs.items || ! songs.items.length ){
          return true;
        } else if (songs.isFetching) {
          return false;
        } else {
          return songs.didInvalidate;
        }
      },
      types: [SONG_REQUEST, SONG_SUCCESS, SONG_FAILURE]
    }
  }
}

