import MediaPlayerContainer from './MediaPlayerContainer';
import { connect } from 'react-redux';

import {clearActiveRecording, setPlayStatus} from 'actions/mediaPlayer';
import {getActiveArchiveTracks, getActiveRecording, getActiveRecordingSource} from "selectors/mediaPlayer";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearRecording: () => {
      dispatch(clearActiveRecording());
      dispatch(setPlayStatus(false));
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    recording: getActiveRecording(state),
    tracks: getActiveArchiveTracks(state),
    source: getActiveRecordingSource(state),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MediaPlayerContainer);
