import React from 'react';
// import PropTypes from 'prop-types';
import { redirects } from 'lib/html/redirects'
import { Route } from 'react-router-dom';

import './RouteRedirector.css';

const makeRoutes = () => {
  return redirects.map(routeRedirect => {
    const path = routeRedirect[1].routerPath;
    const location = routeRedirect[1].headers.location[0].value;
    return (
      <Route
        key={path}
        path={path}
        component={() => {
          window.location = location;
          return null;
        }}
      />
    )
  })

};
const RouteRedirector = () => {
  return (
    <div className="RouteRedirector">
      { makeRoutes() }
    </div>
  )
};

RouteRedirector.propTypes = {
};

RouteRedirector.defaultProps = {
};

export default RouteRedirector;
