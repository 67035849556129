import { combineReducers } from 'redux';

import { 
  SET_ACTIVE_RECORDING, 
  CLEAR_ACTIVE_RECORDING, 
  SET_CURRENT_TRACK,
  SET_CURRENT_TIME,
  SET_PLAY_STATUS
} from 'actions/mediaPlayer';

import {
  SHOW_RECDATA_REQUEST,
  SHOW_RECDATA_SUCCESS,
  SHOW_RECDATA_FAILURE
} from 'actions/shows'

function activeRecording(state = null, action){
  switch(action.type) {
    case SET_ACTIVE_RECORDING:
      return action.recording;
    case CLEAR_ACTIVE_RECORDING:
      return null;

    case SHOW_RECDATA_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });

    case SHOW_RECDATA_SUCCESS:
      const identifier = state.recordingData.identifier;
      const newRecording = action.response.recordings.find(rec => rec.recordingData.identifier === identifier);
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        recordingData: newRecording.recordingData,
        lastUpdate: action.receivedAt
      });

    case SHOW_RECDATA_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });

    default:
      return state;
  }
}

function currentTrack(state = null, action) {
    switch(action.type) {
        case SET_CURRENT_TRACK:
          return action.track;
        case CLEAR_ACTIVE_RECORDING:
          return null;
        default:
          return state;
    }
}

function currentTime(state = 0, action) {
  switch(action.type) {
    case SET_CURRENT_TRACK:
      return 0;
    case SET_CURRENT_TIME:
      return action.time;
    default:
      return state;
  }
}

function isPlaying(state = false, action) {
  switch(action.type) {
    case SET_PLAY_STATUS:
      return action.isPlaying;
    default:
      return state;
  }
}

const mediaPlayerReducers = combineReducers({
  activeRecording,
  currentTrack,
  currentTime,
  isPlaying
});

export default mediaPlayerReducers;
