import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import playIcon from 'images/icon_play_red.png';
import pauseIcon from 'images/icon_pause_red.png';
import prevIcon from 'images/icon_prev.png';
import nextIcon from 'images/icon_next.png';

import './ArchiveTitle.css';
import ArchiveTrackHeadingContainer from "containers/MediaPlayerContainer/ArchiveTrackHeadingContainer";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";

class ArchiveTitle extends Component {
  togglePlayPause = function(isPlaying) {
    isPlaying ? this.audioElement.pause() : this.audioElement.play();
    this.props.setPlayerState(!isPlaying);
  };

  skipTime(time){
    if ( ! this.props.isBufferedPlaying ) {
      this.audioElement.currentTime += time
    }
  }

  playIfPossible(isPlaying){
    // when we render, if we're playing and the audioelement exists
    if ( isPlaying && this.audioElement && this.audioElement.paused && ! this.noAutoplay ) {
      // start playing
      const playPromise = this.audioElement.play();
      // handle errors
      if ( playPromise !== undefined ){
        playPromise.then(() => {
          // autoplaying success
          this.props.setPlayerState(true);
        }).catch(error => {
          if ( error.toString().match(/^NotAllowedError.*/)) {
            // autoplaying failure
            this.noAutoplay = true;
          }
          this.props.setPlayerState(false);
        })
      }
    }
  }
  render(){
    const { playTrack, updateTime } = this.props;
    const { currentTrackURL, nextTrack, prevTrack, isPlaying, isBufferedPlaying } = this.props;
    this.playIfPossible(isPlaying);

    return (
      <Col className="ArchiveTitle" xs={12} sm={12} md={12} lg={12}>
        <div className="button-container">
          <button onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.noAutoplay = false; playTrack(prevTrack); }}>
            <img src={prevIcon} alt="play previous song" />
          </button>
          <button onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.skipTime(-5)}}>
            <i className="fa fa-backward" />
          </button>
          { isBufferedPlaying
            ? <button>
                <LoadingIcon/>
              </button>
            : <button onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.togglePlayPause(isPlaying); }}>
                <img src={isPlaying ? pauseIcon : playIcon} alt="play/pause song" />
              </button>
          }
          <button onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.skipTime(5)}}>
            <i className="fa fa-forward" />
          </button>
           <button onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.noAutoplay = false; playTrack(nextTrack); }}>
            <img src={nextIcon} alt="play next song" />
          </button>
        </div>

        <ArchiveTrackHeadingContainer className="hidden-xs" />

        <audio
          ref={audio => { this.audioElement = audio; }}
          autoPlay="autoplay"
          type="audio/mp3"
          src={currentTrackURL}
          onPlaying={() => {
              ! this.noAutoplay && this.props.setPlayerState(true)
            }
          }
          onEnded={() => nextTrack && playTrack(nextTrack)}
          onTimeUpdate={() => updateTime(this.audioElement.currentTime)} />
      </Col>
    )
  }
}

ArchiveTitle.propTypes = {
  playTrack: PropTypes.func,
  updateTime: PropTypes.func,
  setPlayerState: PropTypes.func,
  isPlaying: PropTypes.bool,
  nextTrack: PropTypes.object,
  prevTrack: PropTypes.object,
  currentTrackURL: PropTypes.string,
};

ArchiveTitle.defaultProps = {
};

export default ArchiveTitle;
