import LinkContainer from './LinkContainer';
import { connect } from 'react-redux';
import {getIsPlaying} from "selectors/mediaPlayer";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isMediaPlaying: getIsPlaying(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LinkContainer);
