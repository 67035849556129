import React from 'react';
import PropTypes from 'prop-types';

import DropDownFilter from 'components/ShowListView/DropDownFilter';
import { SongTypeFilters } from 'actions/songs';

import './SongTypeFilter.css'

const SongTypeFilter = ({filter, onSelect}) => {

  let keys = [
    "All Songs",
    "Original",
    "Cover",
    "Jam",
  ];
  let values = [
    null,
    SongTypeFilters.ORIGINAL,
    SongTypeFilters.COVER,
    SongTypeFilters.JAM,
  ];

  const index = !filter ? 0 : values.findIndex(value => value === filter) || 0;

  return (
    <DropDownFilter
      before="Song Type"
      text={keys[index]}
      onSelect={onSelect}
      keys={keys}
      values={values}
    />
  )
};

SongTypeFilter.propTypes = {
  filter: PropTypes.oneOf([...(Object.keys(SongTypeFilters).map(k => SongTypeFilters[k])), null]),
  onSelect: PropTypes.func
};

SongTypeFilter.defaultProps = {
  filter: null
};

export default SongTypeFilter;

