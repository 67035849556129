import React from 'react'
import { Row, Col, Badge, Label } from 'react-bootstrap';
import PropTypes from 'prop-types'

import './AttendanceListHeading.css'
import AttendanceButtonContainer from "containers/AttendanceButtonContainer";

const AttendanceListHeading = ({children, past, attendanceCount}) => (
  <div className="AttendanceListHeading">
    <Row>
      <Col xs={6}>
        <h3>Nerd Herd</h3>
      </Col>
      <Col xs={6}>
        <Label className="lbl-nerd right pull-right hidden-xs"><span>{attendanceCount}</span><span>{`Attend${past ? 'ed' : 'ing'}`}</span></Label>
        <Badge className="lbl-nerd right pull-right hidden-lg hidden-md hidden-sm"><span>{attendanceCount}</span><span>{`Attend${past ? 'ed' : 'ing'}`}</span></Badge>
      </Col>
    </Row>
    <Row>
      <Col  className="text-center" style={{paddingBottom: 20, paddingTop: 10}}>
        <AttendanceButtonContainer />
      </Col>
    </Row>
  </div>
);

AttendanceListHeading.propTypes = {
  past: PropTypes.bool,
  attendanceCount: PropTypes.number,
  children: PropTypes.node
};

AttendanceListHeading.defaultProps = {};

export default AttendanceListHeading
