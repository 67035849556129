import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Link from 'components/Link';

import PropTypes from 'prop-types';

import './PlayedDatesRow.css';

const PlayedDatesRow = ({firstPlayedDate, lastPlayedDate, hasLyrics, lyricsAnchor}) => (
  <div className="PlayedDatesRow">
    <hr />
    <Row>
      <Col sm={hasLyrics ? 4 : 6} xs={12}>
        <Link to={`/shows/${firstPlayedDate}`}>
          <Button className="btn-nerd">
            <span className="text">FTP:</span>
            <span className="date">{firstPlayedDate}</span>
          </Button>
        </Link>
      </Col>
      <Col sm={hasLyrics ? 4 : 6} xs={12}>
        <Link to={`/shows/${lastPlayedDate}`}>
          <Button className="btn-nerd">
            <span className="text">LTP:</span>
            <span className="date">{lastPlayedDate}</span>
          </Button>
        </Link>
      </Col>
      {
        hasLyrics
          ? (
            <Col sm={4} xs={12}>
              <a href={`#${lyricsAnchor}`}>
                <Button className="btn-nerd right">
                  <span>Lyrics</span>
                </Button>
              </a>
            </Col>
          )
          : ""
      }
    </Row>
  </div>
);

PlayedDatesRow.propTypes = {
  firstPlayedDate: PropTypes.node.isRequired,
  lastPlayedDate: PropTypes.node.isRequired,
  hasLyrics: PropTypes.bool,
  lyricsAnchor: PropTypes.string
};

PlayedDatesRow.defaultProps = {
  hasLyrics: false,
  lyricsAnchor: 'lyrics'
};

export default PlayedDatesRow;
