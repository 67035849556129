import {
  TOUR_GAMES_GET_LEADERBOARD_REQUEST,
  TOUR_GAMES_GET_LEADERBOARD_SUCCESS,
  TOUR_GAMES_GET_LEADERBOARD_FAILURE
} from 'actions/tourGamesLeaderboard';

function initialTourGamesLeaderboardState() {
  return {
    isFetching: false,
    isError: false,
    didInvalidate: false
  };
}

function tourGamesLeaderboard(
  state = initialTourGamesLeaderboardState(),
  action
) {
  switch (action.type) {
    case TOUR_GAMES_GET_LEADERBOARD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case TOUR_GAMES_GET_LEADERBOARD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        leaderboard: action.response,
        lastUpdate: action.receivedAt
      });
    case TOUR_GAMES_GET_LEADERBOARD_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });
    default:
      return state;
  }
}

export default tourGamesLeaderboard;
