import React from 'react';
import Helmet from 'react-helmet';
import { Grid } from 'react-bootstrap';

// import PropTypes from 'prop-types';

import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';
import DetailContentRow from 'components/DetailView/DetailContentRow';
import SongListTable from 'components/SongListTable';
import SongTypeFilterContainer from "containers/SongTypeFilterContainer";

import './LoadingSongListView.css';
import LoadingIcon from "components/LoadingIcon/LoadingIcon";

const LoadingSongListView = () => {
  const songList = [1,2,3,4,5,6,7,8,9,10,11,12,13].map(_id => {
    return {
      _id: _id,
      name: "???????????",
      firstPlayed: { date: "????-??-??", venue: {name: "?????????????????????", city:"????????????????", state:"??"}},
      isJam: false,
      writtenBy: { band: "Spafford"}
    }
  });
  return (
    <Grid className="LoadingSongListView">
      <Helmet>
        <title>Loading Songs</title>
      </Helmet>
      <DetailNavRow>
        <span className="hidden-xs"><LoadingIcon /> Loading <LoadingIcon /></span>
        <span className="hidden-sm hidden-md hidden-lg"><LoadingIcon /> Loading <LoadingIcon /> Songs</span>
      </DetailNavRow>
      <DetailSlugRow>
        <div>Showing <LoadingIcon/> Songs</div>
      </DetailSlugRow>
      <DetailContentRow className="text-center">
        <SongTypeFilterContainer/>
      </DetailContentRow>
      <DetailContentRow heading="Songs">
        <SongListTable songList={songList} noLinks={true}/>
      </DetailContentRow>
    </Grid>
  );
};

LoadingSongListView.propTypes = {
};

LoadingSongListView.defaultProps = {
};

export default LoadingSongListView;
