import React from 'react';

import './ReloadButton.css';
import Icon from "../Icons/Icon";

const ReloadButton = ({className, onClick, loading}) => {
  return (
    <div className={`ReloadButton${className ? ` ${className}` : ''}`}>
      <Icon onClick={! loading ? onClick : () => {console.log("no")}} faIconClass={`fa-refresh${loading ? " fa-spin disabled" : ""}`} text="Refresh" disabled={loading}/>
    </div>
  )
};

ReloadButton.propTypes = {
};

ReloadButton.defaultProps = {
};

export default ReloadButton;
