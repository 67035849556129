import React from 'react';

import CacheTimeDisplay from 'components/ReloadShowsRow/CacheTimeDisplay';

const ShowCacheTimeDisplayContainer = ({lastUpdateTime}) => (
  <CacheTimeDisplay dataPoint="Shows" lastUpdateTime={lastUpdateTime} />
);

ShowCacheTimeDisplayContainer.propTypes = {
};

ShowCacheTimeDisplayContainer.defaultProps = {
};

export default ShowCacheTimeDisplayContainer;
