import React from 'react';
import PropTypes from 'prop-types';

import SharingMenu from 'components/SocialSharing/SharingMenu';

const SharingMenuContainer = ({user, ...props}) => (
  <SharingMenu user={user} {...props} />
);

SharingMenuContainer.propTypes = {
  user: PropTypes.string
};

SharingMenuContainer.defaultProps = {
};

export default SharingMenuContainer;
