import SongListViewContainer from './SongListViewContainer';
import { connect } from 'react-redux';

import { getSongTypeFilter, sortAndFilterSongs } from "selectors/songs";

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

const mapStateToProps = (state, ownProps) => {
    return {
      songList: sortAndFilterSongs(state),
      songTypeFilter: getSongTypeFilter(state),
      isFetching: state.songs.list.isFetching
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SongListViewContainer);
