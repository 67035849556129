import React from 'react';
import { Row, Col, Label } from 'react-bootstrap';

import PropTypes from 'prop-types';

import './CoverOrOriginalRow.css';

const CoverOrOriginalRow = ({cover, band}) => {
  return (
    <Row className="CoverOrOriginalRow">
      <Col lgOffset={4} lg={4}>
        <h4>
          <Label className={cover ? "cover" : "original"}>
            <span>{cover ? "Originally By: " : "By: "}</span>
            <span>{band}</span>
          </Label>
        </h4>
      </Col>
    </Row>
  )
};

CoverOrOriginalRow.propTypes = {
  cover: PropTypes.bool,
  band: PropTypes.string
};

CoverOrOriginalRow.defaultProps = {
};

export default CoverOrOriginalRow;
