import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'react-bootstrap';
import Tooltip from 'components/Tooltip';

import './FactBadge.css';

const FactBadge = ({placement, tooltip, fa, className}) => {
  const badge = (
    <Badge className={className ? `FactBadge ${className}` : 'FactBadge'}>
      <i className={`fa ${fa}`} />
    </Badge>
  );

  if ((className && className.includes("disabled") !== -1) || ! tooltip){
    return badge;
  }

  return (
    <Tooltip text={tooltip} placement={placement}>
      {badge}
    </Tooltip>
  )
};

FactBadge.propTypes = {
  placement: PropTypes.string,
  tooltip: PropTypes.string,
  fa: PropTypes.string,
  className: PropTypes.string
};

FactBadge.defaultProps = {
  placement: "top"
};

export default FactBadge;
