import React, { Component } from 'react'
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { Grid } from 'react-bootstrap';
import { Route } from 'react-router-dom';

import SubNavBar from "components/SubNavBar";
import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';

import SetlistContentRow from './SetlistContentRow';
import NoSetlistContentRow from "./NoSetlistContentRow";
import AvailableRecordingsRow from './AvailableRecordingsRow';
import LoadingShowDetailView from './LoadingShowDetailView';

import AttendanceListContainer from 'containers/AttendanceListContainer';
import AddRecordingRowContainer from "containers/AddRecordingRowContainer";

import { showDetailViewMetaData } from "lib/html/meta";
import { makeShowLink } from "lib/shows";

import './ShowDetailView.css';
import ShowDetailReloadButtonContainer from "../../containers/ShowDetailReloadButtonContainer";
import PullDownRefreshContainer from "containers/PullDownRefreshContainer";

class ShowDetailView extends Component {

  render() {
    const {
      visibleShow, nextShow, prevShow, isPastShow,
      username, userRolesInsecure,
      addRecording, streamOnly, isShowLive, isShowRecent} = this.props;
    if (! visibleShow ) {
      return <LoadingShowDetailView />
    }
    let showDate = visibleShow.date.substr(0,10);
    const metaData = showDetailViewMetaData(showDate, visibleShow.venue);

    return (
      <PullDownRefreshContainer>
        <Grid className="ShowDetailView">
          <Helmet>
            <title>{ metaData.title }</title>
            <meta name="keywords" content={ metaData.keywords }/>
            <meta name="description" content={ metaData.description } />
          </Helmet>
          <Route exact path="/" component={SubNavBar} />
          <DetailNavRow nextUrl={nextShow && makeShowLink(nextShow.date, nextShow.multiShowIndex)}
                        nextText="Next Show"
                        prevUrl={prevShow && makeShowLink(prevShow.date, prevShow.multiShowIndex)}
                        prevText="Previous Show"
                        placement="show"
                        reloadButton={<ShowDetailReloadButtonContainer />}
          >

            {showDate}
          </DetailNavRow>
          <DetailSlugRow>
            <span className="underline">{`${visibleShow.venue.city}, ${visibleShow.venue.state} @ ${visibleShow.venue.name}`}</span>
          </DetailSlugRow>
          {
            ( visibleShow.sets && visibleShow.sets.length )
              ? <SetlistContentRow sets={visibleShow.sets}/>
              : <NoSetlistContentRow tickets={visibleShow.tickets}
                                     username={username}
                                     showDate={showDate}
              />
          }
          <AvailableRecordingsRow
            recordings={visibleShow.recordings}
            addRecording={addRecording}
            isPastShow={isPastShow}
            isShowLive={isShowLive}
            isShowRecent={isShowRecent}
            userRolesInsecure={userRolesInsecure}
          />
          { addRecording
            ? <AddRecordingRowContainer streamOnly={streamOnly}/>
            : ''
          }


          <AttendanceListContainer/>
        </Grid>
      </PullDownRefreshContainer>
    );
  }
}

ShowDetailView.propTypes = {
  visibleShow: PropTypes.shape({
    date: PropTypes.string,
    venue: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    sets: PropTypes.array
  }).isRequired,
  nextShow: PropTypes.shape({
    date: PropTypes.string
  }),
  prevShow: PropTypes.shape({
    date: PropTypes.string
  }),
  isShowLive: PropTypes.bool,
  isShowRecent: PropTypes.bool,
  streamOnly: PropTypes.bool,
  userRolesInsecure: PropTypes.array
};

ShowDetailView.defaultProps = {};

export default ShowDetailView
