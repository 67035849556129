import React, { useEffect } from 'react';
import { Grid } from 'react-bootstrap';
import LeaderBoard from '../LeaderBoard';
import DisplayPicksHeader from './DisplayPicksHeader';
import useTourGames from 'hooks/useTourGames';
import useUser from 'hooks/useUser';
import { useParams } from 'react-router';
import { TEST_TOUR_GAMES_ID } from '../../constants';

import './TourGamesHomeView.css';

const TourGamesHomeView = () => {
  const { showId } = useParams();
  const {
    ticket,
    isError,
    handleError,
    getGameShow,
    getTicket,
    getPicksForShow
  } = useTourGames();
  const { isUserLoggedIn, userId } = useUser();
  const gameShow = getGameShow(showId);
  const picksForShow = getPicksForShow(gameShow.showId, ticket);

  useEffect(() => {
    if (!ticket && isUserLoggedIn) {
      getTicket(userId, TEST_TOUR_GAMES_ID);
    }
    // eslint-disable-next-line
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (isError && isUserLoggedIn) {
      handleError('There was an error getting your ticket.');
    }
    // eslint-disable-next-line
  }, [isError]);

  return (
    <Grid className='TourGamesHomeView'>
      <DisplayPicksHeader
        gameShow={gameShow}
        picksForShow={picksForShow}
        ticket={ticket}
      />
      <LeaderBoard />
    </Grid>
  );
};

export default TourGamesHomeView;
