import VenueFilterContainer from './VenueFilterContainer';
import { connect } from 'react-redux';

import { setVenueFilter } from 'actions/shows';
import { filteredVenues } from 'selectors/shows';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSelect: filter => dispatch(setVenueFilter(filter))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    filter: state.shows.venueFilter,
    venues: filteredVenues(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VenueFilterContainer);
