export const isError = (state) => state?.tourGamesAdmin?.isError;

export const isFetching = (state) => state?.tourGamesAdmin?.isFetching;

export const isCalculateSuccess = (state) =>
  state?.tourGamesAdmin?.isRunSuccess;

export const isSetNextShowSuccess = (state) =>
  state?.tourGamesAdmin?.isSetNextShowSuccess;

export const isSetNextShowError = (state) =>
  state?.tourGamesAdmin?.isSetNextShowError;
