import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './SortButton.css';

class SortButton extends Component {
 constructor(props){
    super(props);
    this.clickHandler = this.clickHandler.bind(this);

    const { startDir } = this.props;
    this.state = {
      sortDirection: startDir && startDir === 'asc' ? 1 : -1, // ascending
    }
  }

  clickHandler(e){
    const { isActive, sortType, onSortClick } = this.props;
    let { sortDirection } = this.state;

    // only switch directions if we're active
    if (isActive){
      sortDirection = sortDirection * -1;
      this.setState({sortDirection: sortDirection});
    }
    onSortClick && onSortClick(sortType, sortDirection);
  }

 render() {
   const {currentSort, sortType, isActive} = this.props;
   const {sortDirection} = this.state;

   return (
     <Button
       className={isActive ? "SortButton active" : "SortButton"}
       active={currentSort === sortType}
       onClick={this.clickHandler}
     >
       <i className={`fa fa-sort-${sortType}-${sortDirection > 0 ? "asc" : "desc"}`}/>
     </Button>
   );
 }
}
//  onSortClick(sortType, sortDirection):
//    fired when the button is clicked,
//     sortDirection > 0 for asc, < 0 for desc
SortButton.propTypes = {
  isActive: PropTypes.bool,
  startDir: PropTypes.oneOf(['asc', 'desc']),
  sortType: PropTypes.oneOf(['alpha', 'numeric']),
  onSortClick: PropTypes.func
};

SortButton.defaultProps = {
  isActive: false,
  startDir: "asc",
  sortType: "alpha",
};

export default SortButton;
