import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ShowDetailView from 'components/ShowDetailView';
import ErrorView from "components/ErrorView";

class ShowDetailViewContainer extends Component {
  extractParams(props) {
    const { match } = props;
    const { params } = match;
    return params
  }

  constructor(props){
    super(props);
    const { id, index } = this.extractParams(this.props);
    this.props.onIdChange && this.props.onIdChange(id, index);
  }

  componentWillReceiveProps(nextProps){
    const { id:newId, index:newIndex } = this.extractParams(nextProps);
    const { id:oldId, index:oldIndex } = this.extractParams(this.props);
    if (oldId !== newId || oldIndex !== newIndex){
      this.props.onIdChange && this.props.onIdChange(newId, newIndex);
    }
  }

  render(){
    const {visibleShow, isFetching, onNoVisibleShow, selectedShowId, isShowLive, isShowRecent, isPastShow, userRolesInsecure, ...props} = this.props;
    const { visibleShowError } = this.props;
    if (!visibleShow) {
      if (visibleShowError){
        const { error, status, responseType, url } = visibleShowError;
        return (
          <ErrorView
            status={status}
            message={error.message}
            stack={error.stack}
            responseType={responseType}
            url={url}
          />
        )
      }
      if (!isFetching && selectedShowId) {
        onNoVisibleShow(selectedShowId);
      }
    }
    return <ShowDetailView
      visibleShow={visibleShow}
      isShowLive={isShowLive}
      isShowRecent={isShowRecent}
      isPastShow={isPastShow}
      userRolesInsecure={userRolesInsecure}
      {...props}
    />
  }
}

ShowDetailViewContainer.propTypes = {
  visibleShow: PropTypes.shape({
    date: PropTypes.string,
    venue: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    sets: PropTypes.array
  }),
  isShowLive: PropTypes.bool,
  isShowRecent: PropTypes.bool,
  userRolesInsecure: PropTypes.array,
  streamOnly: PropTypes.bool
};

ShowDetailViewContainer.defaultProps = {};

export default ShowDetailViewContainer;
