import { API_PUT } from "middleware/api/put";

export const USERSHOWTOGGLE_REQUEST = 'USERSHOWTOGGLE_REQUEST';
export const USERSHOWTOGGLE_SUCCESS = 'USERSHOWTOGGLE_SUCCESS';
export const USERSHOWTOGGLE_FAILURE = 'USERSHOWTOGGLE_FAILURE';
export const USERSHOWTOGGLE_INVALIDATE = 'USERSHOWTOGGLE_INVALIDATE';

export const USERSHOWTOGGLE_DISABLE = 'USERSHOWTOGGLE_DISABLE';
export const USERSHOWTOGGLE_ENABLE = 'USERSHOWTOGGLE_ENABLE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function fetchUserShowToggle(userId, showId, toggle) {
  return {
    [API_PUT]: {
      payload: {
        userId: userId,
        showId: showId,
        toggle: toggle
      },
      authenticated: true,
      endpoint:`/users/${userId}/shows`,
      shouldDispatch: function(store) {
        return true;
      },
      types: [USERSHOWTOGGLE_REQUEST, USERSHOWTOGGLE_SUCCESS, USERSHOWTOGGLE_FAILURE]
    }
  }
}

export function invalidateUserShowToggle(){
  return {
    type: USERSHOWTOGGLE_INVALIDATE
  }
}

export function disableUserShowToggle(){
  return {
    type: USERSHOWTOGGLE_DISABLE
  }
}

export function enableUserShowToggle(){
  return {
    type: USERSHOWTOGGLE_ENABLE
  }
}
