import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';

import MediaPlayerHeading from "components/MediaPlayer/MediaPlayerHeading";
import MediaPlayerBodyContainer from "containers/MediaPlayerContainer/MediaPlayerBodyContainer";

import './MediaPlayer.css';

class MediaPlayer extends Component {
  constructor(props) {
    super(props);
    const { source } = props;
    this.state = {
      expanded: source === 'YouTube'
    };
  }

  render(){
    const { closePlayer } = this.props;
    const { expanded } = this.state;

    return (
        <div className={`MediaPlayer${expanded ? ' expanded' : ''}`}>
          <Panel
            expanded={expanded}
            onToggle={() => this.setState({ expanded: !expanded })}
          >
            <MediaPlayerHeading
              isOpen={expanded}
              closePlayer={closePlayer}
            />
            <Panel.Collapse className="container">
              <MediaPlayerBodyContainer/>
            </Panel.Collapse>
          </Panel>
        </div>
    )
  }
}

MediaPlayer.propTypes = {
  expanded: PropTypes.bool,
};

MediaPlayer.defaultProps = {};

export default MediaPlayer;