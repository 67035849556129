import {
  getUserProfile,
  isLoggedIn,
  isAdmin as isAdminSelector,
  isTourGamesBeta as isTgBeta,
  getCognitoUser
} from 'selectors/user';

import { useSelector } from 'react-redux';

export const useUser = () => {
  const userProfile = useSelector(getUserProfile);
  const cognitoUser = useSelector(getCognitoUser)
  const isUserLoggedIn = useSelector(isLoggedIn);
  const userId = userProfile?._id ?? cognitoUser?.attributes['custom:nerds_test_id'];
  const isAdmin = useSelector(isAdminSelector);
  const isTourGamesBeta = useSelector(isTgBeta);
  

  return {
    userProfile: !userProfile ? {_id: cognitoUser?.attributes['custom:nerds_test_id'], username: cognitoUser?.attributes?.preferred_username} : userProfile,
    isUserLoggedIn,
    userId,
    isAdmin,
    isTourGamesBeta,
    cognitoUser
  };
};
