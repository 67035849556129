import {
  USERPROFILE_REQUEST,
  USERPROFILE_SUCCESS,
  USERPROFILE_FAILURE,
  USERPROFILE_INVALIDATE
} from 'actions/user'

import { USERSHOWTOGGLE_SUCCESS } from "actions/userShowToggle";

function initialUserState(){
  return {
    isFetching: false,
    isError: false,
    didInvalidate: false,
    profile: {},
    cognitoUser: undefined
  }
}

function user(state = initialUserState(), action) {
  switch (action.type) {
   case USERPROFILE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case USERPROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        profile: action.response,
        lastUpdate: action.receivedAt
      });
    case USERPROFILE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });
    case USERPROFILE_INVALIDATE:
      return {
        isFetching: false,
        isError: false,
        didInvalidate: true
      };
    case USERSHOWTOGGLE_SUCCESS:
      let newShows = state.profile.shows.slice(0);
      if (action.payload.toggle) {
        newShows.push(action.response)
      } else {
        newShows = state.profile.shows.filter(show => show.showId !== action.response.showId)
      }
      let newProfile = Object.assign({}, state.profile, {
        shows: newShows
      });
      return Object.assign({}, state, {
        profile: newProfile
      });
      case 'SET_COGNITO_USER':
        return Object.assign({}, state, {
          cognitoUser: action?.payload
        })
      case 'LOG_OUT_COGNITO_USER':
        return Object.assign({}, state, {
          cognitoUser: undefined,
          profile: {},
          isFetching: false,
          isError: false,
          didInvalidate: true
        })
    default:
      return state;
  }
}

export default user;
