import StateFilterContainer from './StateFilterContainer';
import { connect } from 'react-redux';

import { setStateFilter } from 'actions/shows';
import { filteredStates } from 'selectors/shows';

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      onSelect: filter => dispatch(setStateFilter(filter))
    };
};

const mapStateToProps = (state, ownProps) => {
    return {
      filter: state.shows.stateFilter,
      states: filteredStates(state)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StateFilterContainer);
