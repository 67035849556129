import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import Link from "components/Link";

import './AttendanceButtonLoggedOut.css';

const AttendanceButtonLoggedOut = ({forumUrl, showId, showDate}) => {
  const tracker = { category: `Show: ${showDate}`, action: `Login`, label: `ShowId: ${showId}`  };
  return (
    <div className="AttendanceButtonLoggedOut">
      <Link url={`${forumUrl}/login`} tracking={tracker}>
        <Button bsStyle="default" className="active">
          <i className="fa fa-check-square" />
          <span>Track Your Shows</span>
        </Button>
      </Link>
    </div>
  )
};

AttendanceButtonLoggedOut.propTypes = {
  forumUrl: PropTypes.string.isRequired,
  showId: PropTypes.string.isRequired,
  showDate: PropTypes.string.isRequired
};

AttendanceButtonLoggedOut.defaultProps = {
  forumUrl: "https://forum.spaffnerds.com"
};

export default AttendanceButtonLoggedOut;
