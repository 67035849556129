import React from 'react';
import PropTypes from 'prop-types';

import MediaPlayerBody from 'components/MediaPlayer/MediaPlayerBody';

const MediaPlayerBodyContainer = ({source}) => (
  <MediaPlayerBody source={source}/>
);

MediaPlayerBodyContainer.propTypes = {
  source: PropTypes.string
};

MediaPlayerBodyContainer.defaultProps = {};

export default MediaPlayerBodyContainer;
