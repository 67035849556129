import React from 'react';
import PropTypes from 'prop-types'
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';

import RouteRedirector from "components/Root/RouteRedirector";
import App from 'components/App';

import ScrollToTop from 'components/ScrollToTop';

import withTracker from './withTracker';

const Root = ({store, history}) => (
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTop>
        <RouteRedirector/>
        <Route path="/" component={withTracker(App)} />
      </ScrollToTop>
    </Router>
  </Provider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired
};

export default Root;
