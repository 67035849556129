import React from "react"
import PropTypes from "prop-types"

import PayPalDonateContainer from 'containers/PayPalDonateContainer';
import Tooltip from 'components/Tooltip';
import Link from "components/Link"

import "./LoggedOutProfileLinks.css"

const LoggedOutProfileLinks = ({ forumUrl }) => (
  <ul className="nav navbar-nav navbar-right hidden-xs">
    <li>
      <Tooltip text="Donate" placement="bottom" className="hidden-xs">
        <PayPalDonateContainer />
      </Tooltip>
    </li>
    <li>
      <Link url={`${forumUrl}/search`}>
        <i className="fa fa-search fa-fw" title="Search" />
      </Link>
    </li>
    <li>
      <Link url={`${forumUrl}/register`}>Register</Link>
    </li>
    <li>
      <Link url={`${forumUrl}/login`}>Login</Link>
    </li>
  </ul>
);

LoggedOutProfileLinks.propTypes = {
  forumUrl: PropTypes.string
};

LoggedOutProfileLinks.defaultProps = {
  forumUrl: "https://forum.spaffnerds.com"
};

export default LoggedOutProfileLinks
