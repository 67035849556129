import React from 'react';
import DetailNavRow from 'components/DetailView/DetailNavRow';
import PlayButton from './PlayButton';

const PlayHeader = () => {
  return (
    <>
      <DetailNavRow>
        <span>Welcome to the Spaffnerds Tour Games</span>
      </DetailNavRow>
      <PlayButton />
    </>
  );
};

export default PlayHeader;
