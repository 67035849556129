import React from 'react';
import { Panel, Button} from 'react-bootstrap'

// import PropTypes from 'prop-types';
const csv_example="https://spaffnerds-public-static-assets.s3.amazonaws.com/ShowBulkUploadTemplate.csv";
const xls_example="https://spaffnerds-public-static-assets.s3.amazonaws.com/ShowBulkUploadTemplate.xlsx";

const HowTo = () => (
  <Panel className="HowTo" id="collapsible-panel-example-2" defaultCollapsed style={{border: "none"}}>
      <Panel.Title toggle className="text-center">
        <Button>Help</Button>
      </Panel.Title>
    <Panel.Collapse>
      <Panel.Body>
        <p>
          This tool is provided so that administrators can easily upload a batch of shows before a tour announcement.
          To use it, you need to create a CSV file which has an appropriate set of fields to represent the required data for a show.
          Examples are provided below, along with descriptions of the fields in the csv.
        </p>
        <hr />
        <p>
          Once you have a CSV file ready you can press the <strong>Choose File</strong> button and select your file.
        </p>
        <p>
          The shows in your file will be automatically loaded into your local spaffnerds data.
          That means you can click the "shows" link in the nav and get a preview of what your loaded shows would look like.
          <strong>These shows are NOT loaded into the database and are NOT available to all users.</strong>
        </p>
        <hr />
        <p>
          You should review the results and be sure that everything looks the way you want.
          If it does, you can press the <strong>Upload Shows</strong> button and your batch will be loaded into the real spaffnerds database.
          <strong>This will make them available to the public, so don't do this before you're ready to release them.</strong>
        </p>
        <p>
          If things don't look right, just hit the <strong>Reset</strong> button or refresh the page and your test run will be cleared out.
        </p>
        <hr />
        <p>
          You can use the following examples to get started
        </p>
        <p className="text-center"><Button href={csv_example}>CSV Example</Button><Button href={xls_example}>Excel Example</Button></p>
        <ul>
          <li>
            <ul className="field-info">
              <li>ShowDate</li>
              <li>Only specify the date, ie: 5/17/2020</li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>MultiShowIndex</li>
              <li>
                If there's only one show on a day, just leave this blank or set it to 0.
                If there is more than one show on a given date, you need to specify a number.
                Counting begins at 0, so the first show is 0 and the next show is 1.
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>StartTime</li>
              <li>
                This field is used by the twitter bot to decide which show a tweeted song belongs to.
                If there's only one show on a day, just leave this blank.
                <br />
                If there is more than one show on a given date, you need to specify the time that this show starts.
                <br />
                <strong>Times are UTC time.</strong>
                If the show is on the EAST COAST you need to add 4 or 5 hours to your desired start time.
                <br />
                <strong>A 9:00PM EST start time would mean you would need to enter 1:00 AM on the next day.</strong>
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>EndTime</li>
              <li>
                This field is used by the twitter bot to decide which show a tweeted song belongs to.
                If there's only one show on a day, just leave this blank.
                <br />
                If there is more than one show on a given date, you need to specify the time that this show ends.
                <br />
                <strong>Times are UTC time.</strong>
                If the show is on the EAST COAST you need to add 4 or 5 hours to your desired start time.
                <br />
                <strong>A 2:00AM EST end time would mean you would need to enter 6:00 AM.</strong>
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>VenueName</li>
              <li>The name of the venue</li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>VenueCity</li>
              <li>The city of the venue</li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>VenueState</li>
              <li>The standard 2 letter abbreviation for the state.  IE: "AZ".
                <br />
                If the show is international, try to find a 2 letter abbreviation to put in this field.
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>VenueCountry</li>
              <li>The standard 3 letter abbreviation for the country.  IE: "USA".
                <br />
                If the show is international, use the 3 letter code found <a href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes">here</a>
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>FestivalName</li>
              <li>If the show is a festival, specify the name here.  This will be saved as a note on the show</li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>Supporting</li>
              <li>If Spafford is opening for a band, specify that band name here.  This will be saved as a note on the show</li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>SpecialGuest</li>
              <li>If a band is opening for Spafford, specify that band name here.  This will be saved as a note on the show</li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>ShowNotes</li>
              <li>A ";" delimited list of ":" delimited key/value pairs.
                Each pair will be translated into a note and applied to the show.
                <br />
                Use the standard fields for Festival, Supporting, and SpecialGuest when appropriate.
                <br />
                For example: <strong>Note1:Some text;Note2:Some Other Text</strong>
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>PresaleStartDate</li>
              <li>The date and time that tickets go on presale.  This field is optional.
                <br />
                <strong>Times are UTC time.</strong>
                If the tickets go on sale at an east coast time you need to add 4 or 5 hours to your desired start time.
                <br />
                <strong>A 10:00AM EST on sale would mean you would need to enter 12:00 PM or 1:00 PM depending on DST</strong>
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>PresaleEndDate</li>
              <li>The date and time that presale ends. This field is optional, though should be specified if you have a <strong>PresaleStartDate</strong> unless presale goes right into regular onsale.
                <br />
                <strong>Times are UTC time.</strong>
                If the tickets presale ends an east coast time you need to add 4 or 5 hours to your desired start time.
                <br />
                <strong>A 10:00AM EST end time would mean you would need to enter 12:00 PM or 1:00 PM depending on DST</strong>
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>PresaleCode</li>
              <li>If there is a ticket code for presale, specify it here. This field is optional</li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>PresaleURL</li>
              <li>The ticket URL for presale.  This field is optional but you should specify it if you have a PresaleStartDate.</li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>OnsaleDate</li>
              <li>The date and time that tickets go on sale.  This field is optional, but you probably want it..
                <br />
                <strong>Times are UTC time.</strong>
                If the tickets go on sale at an east coast time you need to add 4 or 5 hours to your desired start time.
                <br />
                <strong>A 10:00AM EST on sale would mean you would need to enter 12:00 PM or 1:00 PM depending on DST</strong>
              </li>
            </ul>
          </li>
          <li>
            <ul className="field-info">
              <li>OnsaleURL</li>
              <li>The ticket URL for regular onsale.  This field is optional but you should specify it so that tickets are made available.</li>
            </ul>
          </li>
        </ul>
      </Panel.Body>
    </Panel.Collapse>
  </Panel>
);

HowTo.propTypes = {};

HowTo.defaultProps = {};

export default HowTo;


/*
PresaleStartDate
PresaleEndDate
PresaleCode
PresaleURL
OnsaleDate
OnsaleURL
*/
