import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';

import ShowListViewContainer from 'containers/ShowListViewContainer';
import ShowDetailViewContainer from 'containers/ShowDetailViewContainer';
import SongListViewContainer from 'containers/SongListViewContainer';
import SongDetailViewContainer from 'containers/SongDetailViewContainer';
import UserDetailViewContainer from 'containers/UserDetailViewContainer';
import HomepageShowDetailViewContainer from 'containers/HomepageShowDetailViewContainer';
import MobileNavContainer from 'containers/UserProfileContainer/MobileNavContainer';
import ShowBulkUploadViewContainer from 'containers/ShowBulkUploadViewContainer';
import TourGamesHomeView from 'components/TourGamesHomeView';
import TourGamesPicksView from 'components/TourGamesPicksView';
import TourGamesWelcomeView from 'components/TourGamesWelcomeView';
import TourGamesUserTicketView from 'components/TourGamesUserTicketView';

import Slider from 'components/Slider';
import SliderHeader from 'components/Slider/SliderHeader';
import DesktopNav from 'components/Nav/DesktopNav';
import Footer from 'components/Footer';
import Growl from 'components/Growl';

import brandLogoUrl from './images/logo.png';
import './App.css';
import Fullstory from 'components/Fullstory/Fullstory';
import TourGamesAdmin from 'components/TourGamesAdmin';

import LoginView from 'components/LoginView';
import RegisterView from 'components/RegisterView';
import ConfirmRegistrationView from 'components/ConfirmRegistrationView/ConfrimRegistrationView';

class App extends Component {
  constructor(props) {
    super(props);
    this.props.onCheckForShows();
    this.props.onCheckForSongs();
    this.props.onCheckForUserProfile();
    this.props.OnCheckForLeaderboard();
    this.props.onCheckForNextTourGamesShow();
    this.fullStorySessionId = Math.floor(Math.random() * 10000);
  }
  render() {
    let brandLinkUrl = '/';

    return (
      <div className='App'>
        <Fullstory sessionId={this.fullStorySessionId} />
        <Helmet>
          <title>Spaffnerds: Of Nerds, By Nerds, and For Nerds</title>
          <meta
            name='keywords'
            content='Spafford Music Jam Bands Funk Soul Rock'
          />
          <meta
            name='description'
            content='Spaffnerds is a fan based community driven website for the band Spafford.'
          />
          <meta
            property='og:description'
            content='Spaffnerds is a fan based community driven website for the band Spafford.'
          />
          <meta
            property='og:image'
            content='https://spaffnerds.com/static/images/fav/ms-icon-310x310.png'
          />
          <meta property='og:type' content='website' />
          {/* <meta property="og:url" content={this.context.router.location.pathname} /> */}
          <meta property='og:site_name' content='Spaffnerds' />
        </Helmet>
        <Growl />
        <Slider
          className='mainBody'
          panelId='sliderPanel'
          menuId='menu'
          header={
            <SliderHeader
              brandLogoUrl={brandLogoUrl}
              brandLinkUrl={brandLinkUrl}
            >
              <DesktopNav />
            </SliderHeader>
          }
          menu={<MobileNavContainer />}
        >
          <Switch>
            {/* Changes to routes must be reflected in social sharing */}
            {/* please see: /lib/html/README.md for more */}
            <Route
              path='/shows/:id/:index?'
              component={ShowDetailViewContainer}
            />
            <Route path='/shows' component={ShowListViewContainer} />
            <Route path='/songs/:id' component={SongDetailViewContainer} />
            <Route path='/songs' component={SongListViewContainer} />
            <Route path='/users/:id' component={UserDetailViewContainer} />
            <Route
              path='/admin/shows/upload'
              component={ShowBulkUploadViewContainer}
            />
            <Route exact path='/tourgames/admin' component={TourGamesAdmin} />
            <Route
              exact
              path='/tourgames/:showId/play'
              component={TourGamesPicksView}
            />
            <Route
              exact
              path='/tourgames/:showId'
              component={TourGamesHomeView}
            />
            <Route exact path='/tourgames' component={TourGamesWelcomeView} />
            <Route
              exact
              path='/tourgames/:userId/ticket/'
              component={TourGamesUserTicketView}
            />
            <Route exact path='/login' component={LoginView} />
            <Route exact path='/confirm' component={ConfirmRegistrationView} />
            <Route exact path='/register' component={RegisterView} />
            <Route path='/' component={HomepageShowDetailViewContainer} />
          </Switch>
        </Slider>
        <Footer />
      </div>
    );
  }
}

App.propTypes = {};

App.defaultProps = {};

export default App;
