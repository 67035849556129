export const config = {
  "aws_project_region": process.env.REACT_APP_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITY_POOL,
  "aws_cognito_region": process.env.REACT_APP_REGION,
  "aws_user_pools_id": process.env.REACT_APP_USER_POOL,
  "aws_user_pools_web_client_id": process.env.REACT_APP_USER_POOL_CLIENT,
  "oauth": {
      "domain": process.env.REACT_APP_DOMAIN,
      "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": process.env.REACT_APP_SIGN_IN_REDIRECT,
      "redirectSignOut":  process.env.REACT_APP_SIGN_OUT_REDIRECT,
      "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ]
};
