import React, { useState, useEffect } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSongs } from 'selectors/songs';
import useTourGames from 'hooks/useTourGames';
import useUser from 'hooks/useUser';
import { tourGamesPicksFormHelper as helper } from './tourGamesPicksFormHelper';
import sort from 'lib/sort';
import PropTypes from 'prop-types';
import { TEST_TOUR_GAMES_ID } from '../../../constants';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import './TourGamesPicksForm.css';
import PicksSelector from './PicksSelector';

const TourGamesPicksForm = ({ show }) => {
  const songs = useSelector((state) => getSongs(state));
  const sortedSongs = songs.sort(sort.songs.name_asc);

  const { ticket, addPicks, getPicksForShow, isFetching } = useTourGames();
  const { userId } = useUser();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(true);
  const picksForShow = getPicksForShow(show.showId, ticket);

  const [firstSetPick, setFirstSetPick] = useState(
    helper.getSongForPick(picksForShow?.set1, sortedSongs)
  );
  const [secondSetPick, setSecondSetPick] = useState(
    helper.getSongForPick(picksForShow?.set2, sortedSongs)
  );
  const [encorePick, setEncorePick] = useState(
    helper.getSongForPick(picksForShow?.encore, sortedSongs)
  );

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const cancel = () => {
    return history.goBack();
  };

  const onSubmit = () => {
    const picks = {
      set1: firstSetPick._id,
      set2: secondSetPick._id,
      encore: encorePick._id
    };
    addPicks(userId, TEST_TOUR_GAMES_ID, show.showId, ticket?.ticketId, picks);
    setIsEdit(false);
  };

  useEffect(() => {
    setIsSubmitDisabled(!Boolean(firstSetPick && secondSetPick && encorePick));
  }, [firstSetPick, secondSetPick, encorePick, setIsSubmitDisabled]);

  useEffect(() => {
    const picks = picksForShow;
    if (picks && !isFetching && !isEdit) {
      return history.push(`/tourgames/${show.showId}`);
    }
    // eslint-disable-next-line
  }, [ticket, isFetching, isEdit]);

  if (isFetching) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <form className='TourGamesPicksForm'>
      <FormGroup>
        <PicksSelector
          label={'First Set:'}
          name={'firstSet'}
          onChange={setFirstSetPick}
          pick={firstSetPick}
          songs={sortedSongs}
        />

        <PicksSelector
          label={'Second Set:'}
          name={'secondSet'}
          onChange={setSecondSetPick}
          pick={secondSetPick}
          songs={sortedSongs}
        />

        <PicksSelector
          label={'Encore:'}
          name={'encore'}
          onChange={setEncorePick}
          pick={encorePick}
          songs={sortedSongs}
        />

        <div style={{ textAlign: 'center' }}>
          <Button className='' onClick={cancel}>
            Cancel
          </Button>
          <Button
            className='SubmitButton'
            onClick={onSubmit}
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        </div>
      </FormGroup>
    </form>
  );
};

TourGamesPicksForm.propTypes = {
  show: PropTypes.object.isRequired
};

export default TourGamesPicksForm;
