import React from 'react';
import { Popover as BootstrapPopover, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Popover.css';

const Popover = ({ heading, body,
                   trigger, placement, rootClose,
                   id, children,
                   ...props}) => {
  let popover = (
    <BootstrapPopover
      id={id}
      title={heading}
      {...props}
    >
      {body}
    </BootstrapPopover>
  );
  return (
    <OverlayTrigger
      trigger={trigger}
      className="Popover"
      placement={placement}
      overlay={popover}
      rootClose={rootClose}
    >
      <span tabIndex={0}>
        {children}
      </span>
    </OverlayTrigger>
  )
};

Popover.propTypes = {
  heading: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  trigger: PropTypes.oneOf(['click', 'hover', 'focus']),
  placement: PropTypes.oneOf(['bottom', 'top', 'left', 'right']).isRequired,
  rootClose: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node.isRequired
};

Popover.defaultProps = {
  id: "Popover",
  placement: "bottom",
  trigger: "click",
  rootClose: true
};

export default Popover;
