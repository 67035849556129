
exports.homepageViewMetaData = (showDate, venue) => {
  return exports.showDetailViewMetaData(showDate, venue);
};

exports.showListViewMetaData = (recordingsFilter) => {
  const titleMap = {
    yes: "Shows With Recordings",
    no: "Shows Without Recordings",
    nugs: "Shows With nugs.net Official Recordings",
    soundboard: "Shows With Soundboard Recordings",
    audio: "Shows With Audio Recordings",
    "free-soundboard": "Shows with Free Soundboard Recordings",
    aud: "Shows With Audience Recordings",
    'nugs-audio': "Shows With nugs.net Official Audio",
    video: "Shows With Video",
    'video-all': "Shows With Full Show Video",
    'video-some': "Shows With Partial Show Video",
    'nugs-video': "Shows With nugs.net Official Video",
  };

  const descriptionMap = {
    yes: "shows the band has played where there are recordings.",
    no: "shows the band has played where there aren't recordings.",
    nugs: "shows the band has played where there is an official nugs release.",
    audio: "shows the band has played where there are audio recordings.",
    soundboard: "shows the band has played where there are soundboard audio recordings.",
    "free-soundboard": "shows the band has played where there are free, shareable, soundboards.",
    aud: "shows the band has played where there are audience recordings.",
    'nugs-audio': "shows the band has played where there is an official nugs audio release.",
    video: "shows the band has played where there are video recordings.",
    'video-all': "shows the band has played where there are full show video recordings.",
    'video-some': "shows the band has played where there are partial show video recordings.",
    'nugs-video': "shows the band has played where there is an official nugs video release.",
  };

  const titleSlug = titleMap[recordingsFilter] || "Shows";
  const descriptionSlug = descriptionMap[recordingsFilter] || "all shows the band has played.";

  let title = `Spafford ${titleSlug} - Spaffnerds: Of Nerds, By Nerds, and For Nerds`;
  let description = `Spaffnerd's history of the shows and venues where the band Spafford has played concerts.  This includes ${descriptionSlug}`;
  let keywords = `Spafford ${titleSlug} Concerts History Music Venues Recordings`;

  return {
    title: title,
    description: description,
    keywords: keywords,
    body: description
  }
};

exports.showDetailViewMetaData = (showDate, venue) => {
   return {
    title: `Spafford Setlist: ${showDate} @ ${venue.name} in ${venue.city}, ${venue.state || venue.country} - Spaffnerds: Of Nerds, By Nerds, and For Nerds`,
    keywords: `Spafford Setlist: ${showDate} @ ${venue.name} in ${venue.city}, ${venue.state || venue.country}`,
    description: `Spafford&#x27;s setlist and recordings from ${showDate} @ ${venue.name} in ${venue.city}, ${venue.state || venue.country}`,
    body: `Spafford Setlist: ${showDate} @ ${venue.name} in ${venue.city}, ${venue.state || venue.country} - Spaffnerds: Of Nerds, By Nerds, and For Nerds`
  };
};

exports.songListViewMetaData = (songFilter) => {

  let titleMap = {
    original: "Original Songs",
    cover: "Covers",
    jam: "Named Jams"
  };

  let descriptionMap = {
    original: "original songs",
    cover: "cover songs",
    jam: "named jams"
  };

  let titleSlug = titleMap[songFilter] || "Songs";
  let descriptionSlug = descriptionMap[songFilter] || "all songs";

  let title = `Spafford ${titleSlug} - Spaffnerds: Of Nerds, By Nerds, and For Nerds`;
  let description = `A list of ${descriptionSlug} that Spafford has played with lyrics, first date and venue where they were performed.`;
  let keywords = "Spafford Songs Lyrics Original Covers Jams First Played Venue";

  return {
    title: title,
    description: description,
    keywords: keywords,
    body: description
  }

};

exports.songDetailViewMetaData = (song) => {
  const { name, isJam, writtenBy } = song;
  const { band } = writtenBy;

  let title = "";
  let description = "";
  let keywords = "";

  if (band === 'Spafford' && !isJam){
    title = `Spafford: ${name} - Song History and Lyrics - Spaffnerds: Of Nerds, By Nerds, and For Nerds`;
    description = `Spafford: ${name} original song. Lyrics, song facts, first time played, last time played, and more.`;
    keywords = `Spafford ${name} Lyrics Original Song First Played Venue`;
  } else if (isJam){
    title = `Spafford: ${name} - Song History - Spaffnerds: Of Nerds, By Nerds, and For Nerds`;
    description = `Spafford: ${name}. Song facts, first time played, last time played, and more.`;
    keywords = `Spafford ${name} First Played Venue`;
  } else {
    title = `Spafford: ${name} - Song History - Spaffnerds: Of Nerds, By Nerds, and For Nerds`;
    description = `Spafford: ${name} by ${band} cover. Song facts, first time played, last time played, and more.`;
    keywords = `Spafford ${name} ${band} Cover Song First Played Venue`;
  }

  return {
    title: title,
    description: description,
    keywords: keywords,
    body: description
  }
};

exports.userDetailViewMetaData = (username) => {
  const name = username || 'A user';
  return {
    title: `Spafford Stats: ${name}'s stats for Spafford shows`,
    keywords: "Spafford, Stats, Setlist, Songs, Shows, Attendance",
    description: `${name}'s personal Spafford stats and history`,
    body: `${name}'s personal Spafford stats and history`
  }
};