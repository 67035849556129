import MPYouTubeTitleContainer from './MPYouTubeTitleContainer';
import { connect } from 'react-redux';

import {
  getActiveRecordingDate,
  getActiveRecordingShowLink,
  getActiveRecordingSource,
  getActiveRecordingTitle,
  getActiveYouTubeContent
} from 'selectors/mediaPlayer/index';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    source: getActiveRecordingSource(state),
    showDate: getActiveRecordingDate(state),
    content: getActiveYouTubeContent(state),
    title: getActiveRecordingTitle(state),
    showLink: getActiveRecordingShowLink(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MPYouTubeTitleContainer);
