import FlushShowsButtonContainer from './FlushShowsButtonContainer';
import { connect } from 'react-redux';
import {fetchShows, invalidateShows} from "../../actions/shows";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFlush: () => {
      dispatch(invalidateShows());
      dispatch(fetchShows());
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FlushShowsButtonContainer);
