import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { unregister } from 'registerServiceWorker';

import pollAuthStatus from 'lib/workers/pollAuthStatus';
import Root from 'components/Root';

import configureStore from 'configureStore';

import { Amplify } from 'aws-amplify';
import { config } from './aws-config';

const { store, history } = configureStore();
const persistor = persistStore(store);

Amplify.configure(config);

render(
  <PersistGate loading={<div>Loading</div>} persistor={persistor}>
    <Root store={store} history={history} />
  </PersistGate>,
  document.getElementById('root')
);

unregister();
pollAuthStatus(store);
