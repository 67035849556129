import React from 'react'
import PropTypes from 'prop-types';

import LoggedInProfileLinks from "./LoggedInProfileLinks";
import LoggedOutProfileLinks from "./LoggedOutProfileLinks";

const ProfileLinks = ({isLoggedIn, ...props}) => {
  if (isLoggedIn) {
    return <LoggedInProfileLinks {...props} />
  } else {
    return <LoggedOutProfileLinks {...props} />
  }
};

ProfileLinks.propTypes = {
  isLoggedIn: PropTypes.bool,
};

ProfileLinks.defaultProps = {
  isLoggedIn: false,
};

export default ProfileLinks
