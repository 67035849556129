import React from 'react';
import PropTypes from 'prop-types';

import DetailContentRow from 'components/DetailView/DetailContentRow';

import './AttendanceListErrorRow.css';

const AttendanceListErrorRow = () => {
  return (
    <div className="AttendanceListRow">
      <hr />
      <DetailContentRow heading="Attendance Load Error">
        There was an error loading the attendance for this show.  Please try again later
      </DetailContentRow>
    </div>
  )
};

AttendanceListErrorRow.propTypes = {
  helloWorld: PropTypes.string
};

AttendanceListErrorRow.defaultProps = {
  helloWorld: "Hello World!"
};

export default AttendanceListErrorRow;
