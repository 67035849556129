import AttendanceButtonContainer from './AttendanceButtonContainer';
import { connect } from 'react-redux';

import { displayGrowl, growlLevels } from "lib/growl";

import {
  disableUserShowToggle,
  enableUserShowToggle,
  fetchUserShowToggle,
  invalidateUserShowToggle
} from "actions/userShowToggle";

import { getVisibleShowDate, getVisibleShowId, isVisibleShowInPast } from "selectors/shows";
import { didUserAttendShow, getUsername } from "selectors/user";
import { isDisabled, isFetching, isSuccess, isError } from "selectors/userShowToggle";
import { invalidateAttendance } from "actions/attendance";
import { invalidateUserStats } from "actions/userStats";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: (userId, showId, toggle) => dispatch(fetchUserShowToggle(userId, showId, toggle)),
    onError: (message) => {
      dispatch(disableUserShowToggle());
      displayGrowl(`Error: ${message}`, growlLevels.WARN, {
        onClose: () => dispatch(enableUserShowToggle()),
      });
      dispatch(invalidateUserShowToggle());
    },
    onSuccess: (userId, showId, message) => {
      dispatch(disableUserShowToggle());
      dispatch(invalidateAttendance(showId));
      dispatch(invalidateUserStats(userId));
      displayGrowl(`Sweet! ${message}`, growlLevels.SUCCESS, {
        onClose: () => dispatch(enableUserShowToggle()),
      });
      dispatch(invalidateUserShowToggle());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const showId = getVisibleShowId(state);
  return {
    userId: getUsername(state),
    showId: showId,
    showDate: getVisibleShowDate(state),
    didAttend: didUserAttendShow(state, showId),
    past: isVisibleShowInPast(state),
    isError: isError(state),
    isSuccess: isSuccess(state),
    disabled: isFetching(state) || isError(state) || isDisabled(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AttendanceButtonContainer);
