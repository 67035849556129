import React from 'react'
import { Grid, Col } from 'react-bootstrap';
import { Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import { signUp } from 'lib/auth/register';
import { useHistory } from 'react-router-dom';
import { getCognitoUser } from 'selectors/user';
import { useDispatch, useSelector } from 'react-redux';

import './RegisterView.css';

const RegisterView = () => {
  const [userInfo, setUserInfo] = React.useState()
  const cognitoUser = useSelector(getCognitoUser)
  const history = useHistory();
  const dispatch = useDispatch()
  

  const handleTextChange = (event) => {
    const { value, name } = event?.target ?? {}
    setUserInfo(info => ({ ...info, [name]: value }))
  }


  React.useEffect(() => {
    if(cognitoUser){
      history.push('/confirm')
    }

  }, [cognitoUser, history])

  const handleSubmit = () => {
    signUp(userInfo, (user) => dispatch({type: 'SET_COGNITO_USER', payload: user}))
  }

  return (
    <Grid className='RegisterView'>
        <Form horizontal>
          <FormGroup controlId="formHorizontalFirstName">
            <Col componentClass={ControlLabel} sm={2}>
              First Name
            </Col>
            <Col sm={4}>
              <FormControl
                type="text"
                value={userInfo?.firstName}
                name={'firstName'}
                onChange={handleTextChange}
                placeholder="First Name"
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="formHorizontalLastName">
            <Col componentClass={ControlLabel} sm={2}>
              Last Name
            </Col>
            <Col sm={4}>
              <FormControl
                type="text"
                name='lastName'
                value={userInfo?.lastName}
                onChange={handleTextChange}
                placeholder="Last Name"
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="formHorizontalEmail">
            <Col componentClass={ControlLabel} sm={2}>
              Email
            </Col>
            <Col sm={4}>
              <FormControl
                type="text"
                name='email'
                value={userInfo?.email}
                onChange={handleTextChange}
                placeholder="Email"
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="formHorizontalUsername">
            <Col componentClass={ControlLabel} sm={2}>
              Username
            </Col>
            <Col sm={4}>
              <FormControl
                type="text"
                name='username'
                value={userInfo?.username}
                onChange={handleTextChange}
                placeholder="Username"
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="formHorizontalPassword">
            <Col componentClass={ControlLabel} sm={2}>
              Password
            </Col>
            <Col sm={4}>
              <FormControl
                type="password"
                name='password'
                label="Password"
                value={userInfo?.password}
                onChange={handleTextChange}
                placeholder="password"
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="formHorizontalConfirmPassword">
            <Col componentClass={ControlLabel} sm={2}>
              Confirm Password
            </Col>
            <Col sm={4}>
              <FormControl
                type="password"
                name='confirmPassword'
                label="Confirm Password"
                value={userInfo?.confirmPassword}
                onChange={handleTextChange}
                placeholder="Confirm Password"
              />
            </Col>
          </FormGroup>

          <Col sm={2} />
          <Col>
            <Button
              disabled={userInfo?.password !== userInfo?.confirmPassword}
              onClick={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmit()
                }
              }}
            >
              Submit
            </Button>
          </Col>
        </Form>
    </Grid>
  )
}

export default RegisterView