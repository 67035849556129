import React from 'react';
import { Table } from 'react-bootstrap';
import Link from 'components/Link';
import useTourGames from 'hooks/useTourGames';
import './Leaderboard.css';
import { TEST_TOUR_GAMES_ID } from '../../constants';

const LeaderBoard = () => {
  const { leaderboard, tourId } = useTourGames();

  const rowsToDisplay = leaderboard?.map((player, index) => (
    <tr>
      <td>{index + 1}</td>
      <td>
        <Link url={`/tourgames/${player.userId}/ticket`}>
          {player.userName}
        </Link>
      </td>
      <td>{player.score}</td>
    </tr>
  ));

  return (
    <div className='Leaderboard'>
      <h2>LeaderBoard</h2>
      <p>
        Sponsored by{' '}
        <a
          href='https://play.nugs.net'
          target='_blank'
          rel='noopener noreferrer'
        >
          nugs.net
        </a>
      </p>
      <h3>{tourId ?? TEST_TOUR_GAMES_ID}</h3>
      <a href='https://forum.spaffnerds.com/category/18/tour-games'>
        Tour Games Cheat Sheet & Rules{' '}
      </a>
      <Table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>{rowsToDisplay}</tbody>
      </Table>
    </div>
  );
};

export default LeaderBoard;
