import ArchiveBodyContainer from './ArchiveBodyContainer';
import { connect } from 'react-redux';
import { getActiveArchiveTracks, getCurrentTrack, getIsPlaying } from "selectors/mediaPlayer";
import { setCurrentTrack, setPlayStatus } from "actions/mediaPlayer";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    playTrack: (track) => {
      dispatch(setCurrentTrack(track));
      dispatch(setPlayStatus(true));
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    tracks: getActiveArchiveTracks(state),
    isPlaying: getIsPlaying(state),
    playingTrack: getCurrentTrack(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ArchiveBodyContainer);
