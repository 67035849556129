import { combineReducers } from 'redux';
import {
  INVALIDATE_SONGS,
  SONG_REQUEST,
  SONG_SUCCESS,
  SONG_FAILURE,
  SET_VISIBLE_SONG,
  INVALIDATE_SONG_STAT,
  SONGSTATS_REQUEST,
  SONGSTATS_SUCCESS,
  SONGSTATS_FAILURE,
  SET_SONGTYPE_FILTER,
  CLEAR_SONGTYPE_FILTER
} from 'actions/songs'

function initialSongState(){
  return {
    isFetching: false,
    didInvalidate: false,
    items: [],
    stats: []
  }
}

function list(state = initialSongState(), action) {
  switch (action.type) {
    case INVALIDATE_SONGS:
      return Object.assign({}, state, {
        didInvalidate: true,
        isError: false
      });

    case SONG_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case SONG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        items: action.response,
        lastUpdate: action.receivedAt
      });
    case SONG_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false,
      });
     default:
      return state;
  }
}

function songTypeFilter(state = null, action){
  switch (action.type){
    case SET_SONGTYPE_FILTER:
      return action.filter;
    case CLEAR_SONGTYPE_FILTER:
      return null;
    default:
      return state;
  }
}

function stat(state = {}, action){
  switch (action.type){
    case INVALIDATE_SONG_STAT:
      return Object.assign({}, state[action.id], {
        didInvalidate: true,
        isError: false
      });
    case SONGSTATS_REQUEST:
      return Object.assign({}, state[action.id], {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case SONGSTATS_SUCCESS:
      return Object.assign({}, state[action.id], {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        stat: action.response,
        lastUpdate: action.receivedAt
      });
    case SONGSTATS_FAILURE:
      return Object.assign({}, state[action.id], {
        isFetching: false,
        isError: true,
        error: action.response,
        didInvalidate: false
      });
    default:
      return state;
  }
}
function stats(state = {}, action){
  switch (action.type){
    case INVALIDATE_SONG_STAT:
    case SONGSTATS_REQUEST:
    case SONGSTATS_SUCCESS:
    case SONGSTATS_FAILURE:
      return Object.assign({}, state, {
        [action.id]: stat(state[action.id], action)
      });
    default:
      return state;
  }
 }

//TODO: Make the default 'latest' and then setup something to parse that
function selected(state = {}, action){
  switch (action.type) {
    case SET_VISIBLE_SONG:
      return {
        id: action.id,
        index: action.index
      };
    default:
      return state;
  }
}

const songs = combineReducers({
  list,
  songTypeFilter,
  stats,
  selected
});

export default songs;