import React from 'react';
import PropTypes from 'prop-types';
import { FullStory as ReactFullStory } from 'react-fullstory-component';

import './Fullstory.css';

const Fullstory = ({sessionId, custom}) => {
  const settings = {
    debug: process.env.REACT_APP_FULLSTORY_DEBUG === 'true',
    host: 'fullstory.com',
    orgKey: process.env.REACT_APP_FULLSTORY_ORG
  };
  return (
    <ReactFullStory
      sessionId={sessionId}
      settings={settings}
      custom={custom}
    />

  )
};

Fullstory.propTypes = {
  sessionId: PropTypes.string,
  custom: PropTypes.object
};

Fullstory.defaultProps = {
  custom: {}
};

export default Fullstory;
