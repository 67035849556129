import useTourGamesAdmin from 'hooks/useTourGamesAdmin'
import useTourGames from 'hooks/useTourGames'
import React, { useEffect } from 'react'
import { Button, Table } from 'react-bootstrap'
import { TEST_TOUR_GAMES_ID } from '../../../constants'
import { displayGrowl, growlLevels } from 'lib/growl'
import Loading from 'components/Loading'
import { useHistory } from 'react-router-dom'

const AdminShowList = () => {
  const {
    isCalculateSuccess,
    isError,
    isSetShowSuccess,
    isSetShowError,
    isFetching,
    runCalculate,
    setNextshow
  } = useTourGamesAdmin()
  const history = useHistory()

  const { showList, tourId, getTourShows } = useTourGames()

  const dateLimit = new Date()
  dateLimit.setDate(dateLimit.getDate() - 20)

  const getShowId = show => {
    return show.multiShowIndex > 0
      ? `${show?.dats?.substr(0, 10)}-0${show?.multiShowIndex}`
      : `${show?.date.substr(0, 10)}`
  }

  const handleCalculateClick = show => {
    runCalculate(TEST_TOUR_GAMES_ID, getShowId(show))
  }

  const handleSetShowClick = show => {
    setNextshow(TEST_TOUR_GAMES_ID, getShowId(show))
  }

  useEffect(() => {
    getTourShows(TEST_TOUR_GAMES_ID)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isError) {
      displayGrowl('Error running calculate', growlLevels.WARN)
    }
  }, [isError])

  useEffect(() => {
    if (isCalculateSuccess) {
      displayGrowl('Calculation ran succesfully!', growlLevels.SUCCESS)
    }
  }, [isCalculateSuccess])

  useEffect(() => {
    if (isSetShowError) {
      displayGrowl('Error setting show', growlLevels.WARN)
    }
  }, [isSetShowError])

  useEffect(() => {
    if (isSetShowSuccess) {
      displayGrowl('Show set succesfully!', growlLevels.SUCCESS)
    }
  }, [isSetShowSuccess])

  if (isFetching) {
    return (
      <>
        <Loading />
      </>
    )
  }

  return (
    <div>
      <h3>{tourId}</h3>
      <Table>
        <tbody>
          {showList?.reverse()?.map(show => {
            return (
              <tr key={show._id}>
                <td>{show.date.substr(0, 10)}</td>
                <td className='hidden-xs hidden-sm venue-name'>
                  {show.venue.name}
                </td>
                <td className='hidden-xs'>{show.venue.city}</td>
                <td className='hidden-xs'>{show.venue.state}</td>
                <td>
                  <Button onClick={() => handleCalculateClick(show)}>
                    Calculate
                  </Button>
                </td>
                <td>
                  <Button onClick={() => handleSetShowClick(show)}>
                    Set Next Show
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <div style={{ textAlign: 'center' }}>
        <Button className='' onClick={() => history.goBack()}>
          Go Back
        </Button>
      </div>
    </div>
  )
}

export default AdminShowList
