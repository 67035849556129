import { tourGamesPicksFormHelper as helper } from '../tourGamesPicksFormHelper';

const getDefaultPickTypeState = (pick, songs) => {
  if (helper.getOriginalSongIds(songs).includes(pick?._id)) {
    return 'original';
  }

  if (helper.getCoverSongIds(songs).includes(pick?._id)) {
    return 'cover';
  }

  const OTHER_IDS = ['new-original', 'new-cover'];

  if (OTHER_IDS.includes(pick?._id)) {
    return 'other';
  }

  return 'original';
};

export const picksSelectorHelper = {
  getDefaultPickTypeState
};
