import sort from 'lib/sort';
import { ShowDateFilters, RecordingFilters } from 'actions/shows';

export const getRecordingsFilter = state => {
  return state.shows && state.shows.recordingFilter;
};

export const getDateFilter = state => {
  return state.shows && state.shows.dateFilter;
};

const applyDateFilter = (shows, dateFilter) => {
  const today = (new Date()).toISOString().substr(0,10);
  switch (dateFilter) {
    case ShowDateFilters.CURRENT:
      return shows.filter(show => show.date.substr(0,10) <= today);
   case ShowDateFilters.FUTURE:
      return shows.filter(show => show.date.substr(0,10) > today);
    // case ShowDateFilters.YEAR:
    //   return shows.filter(show => show.date.substr(0,4) === dateFilter.value);
    default:
      return shows;
  }
};
export const applyRecordingFilter = (shows, filter) => {
  const todayString = new Date(new Date().toISOString().substr(0,10)).toISOString();
  const isInPast = show => show.date <= todayString;

  const isStreamRecording = rec => rec.recordingData.content === "stream";
  const isAudStream = rec => rec.recordingType === "Aud" && isStreamRecording(rec);
  const isNugsRecording = rec => rec.source === 'nugs.net';
  const isVideoRecording = rec => rec.recordingType === "Video";
  const isSoundboardRecording = rec => rec.recordingType === "Soundboard";
  const isAudRecording = rec => rec.recordingType === "Aud" && ! isStreamRecording(rec);

  const isNugsSoundboardRecordings = rec => isNugsRecording(rec) && isSoundboardRecording(rec);
  const isSoundboardOrAudRecordings = rec => isSoundboardRecording(rec) || isAudRecording(rec);
  const isFreeSoundboardRecording = rec => isSoundboardRecording(rec) && ! isNugsRecording(rec);
  const isFullShowVideoRecording= rec => isVideoRecording(rec )&& ! Array.isArray(rec.recordingData.content);
  const isPartialShowVideoRecording= rec => isVideoRecording(rec) && Array.isArray(rec.recordingData.content);
  const isNugsVideoRecording = rec => isNugsRecording(rec) && isVideoRecording(rec);

  const hasNoRecordings = show => ! show.recordings || ! show.recordings.length;
  const isPastAndNoRecordings = show => isPastAndOnlyStreams(show) || ( isInPast(show) && hasNoRecordings(show) );
  const isPastAndAnyRecordings = show => ! isPastAndOnlyStreams(show) && isPastAndHasRecordings(show);
  const isPastAndOnlyStreams = show => isInPast(show) && ! hasNoRecordings(show) && show.recordings.filter(rec => !isAudStream(rec)).length === 0;
  const isPastAndHasRecordings = show => isInPast(show) && show.recordings && show.recordings.length > 0;
  const isPastAndHasNugs = show => isInPast(show) && show.recordings.find(isNugsRecording);
  const isPastAndHasSoundboardOrAud = show => isInPast(show) && show.recordings.find(isSoundboardOrAudRecordings);
  const isPastAndHasSoundboard = show => isInPast(show) && show.recordings.find(isSoundboardRecording);
  const isPastAndHasAud = show => isInPast(show) && ! isPastAndOnlyStreams(show) && show.recordings.find(isAudRecording);
  const isPastAndHasFreeSoundboard = show => isInPast(show) && show.recordings.find(isFreeSoundboardRecording);
  const isPastAndHasNugsSoundboard= show => isInPast(show) && show.recordings.find(isNugsSoundboardRecordings);
  const isPastAndHasVideo = show => isInPast(show) && show.recordings.find(isVideoRecording);
  const isPastAndHasFullShowVideo = show => isInPast(show) && show.recordings.find(isFullShowVideoRecording);
  const isPastAndHasSomeVideo = show => isInPast(show) && show.recordings.find(isPartialShowVideoRecording);
  const isPastAndHasNugsVideo = show => isInPast(show) && show.recordings.find(isNugsVideoRecording);

  switch (filter){
    case RecordingFilters.NONE:
      return shows.filter(isPastAndNoRecordings);
    case RecordingFilters.ANY:
      return shows.filter(isPastAndAnyRecordings);
    case RecordingFilters.NUGS:
      return shows.filter(isPastAndHasNugs);
    case RecordingFilters.AUDIO:
      return shows.filter(isPastAndHasSoundboardOrAud);
    case RecordingFilters.AUDIO_SBD:
      return shows.filter(isPastAndHasSoundboard);
    case RecordingFilters.AUDIO_AUD:
      return shows.filter(isPastAndHasAud);
    case RecordingFilters.AUDIO_SBD_FREE:
      return shows.filter(isPastAndHasFreeSoundboard);
    case RecordingFilters.AUDIO_NUGS:
      return shows.filter(isPastAndHasNugsSoundboard);
    case RecordingFilters.VIDEO:
      return shows.filter(isPastAndHasVideo);
    case RecordingFilters.VIDEO_ALL:
      return shows.filter(isPastAndHasFullShowVideo);
    case RecordingFilters.VIDEO_SOME:
      return shows.filter(isPastAndHasSomeVideo);
    case RecordingFilters.VIDEO_NUGS:
      return shows.filter(isPastAndHasNugsVideo);
    default:
      return shows;
  }

};
const applyStateFilter = (shows, filter) => {
  if (! filter) { return shows }

  return shows.filter(shows => shows.venue.state === filter)
};
const applyVenueFilter = (shows, filter) => {
  if (! filter) { return shows }
  return shows.filter(shows => shows.venue.name === filter)
};
const applyYearFilter = (shows, filter) => {
  if(!filter) { return shows }
  return shows.filter(show => getYear(show.date) === filter)
};

const applyFilters = (shows, state) => {
  let filtered = shows;
  filtered = applyDateFilter(filtered, getDateFilter(state));
  filtered = applyRecordingFilter(filtered, getRecordingsFilter(state));
  filtered = applyStateFilter(filtered, state.shows.stateFilter);
  filtered = applyVenueFilter(filtered, state.shows.venueFilter);
  filtered = applyYearFilter(filtered, state.shows.yearFilter)
  return filtered;
};

// TODO: Exported to support sorting temporarily, see https://gitlab.com/spaffnerds/spaffnerds-react-app/issues/33
export const applySort = (shows) => {
  let sorted = shows;
  // In order to get same day shows to sort and avoid complicated logic
  //  we simply change the time on shows listed on the same day.
  //  You could set the actual time of start (if known)
  //  or more simply, just bump the milliseconds
  // This is irrespective of the multiShowIndex which is NOT used for sorting
  //  only for identifying the show.
  sorted = sorted.sort(sort.shows.date_desc);
  return sorted;
};

export const sortAndFilterShows = state => {
  return applySort(applyFilters(state.shows.list.items, state));
};

export const filteredStates = state => {
  return new Set(sortAndFilterShows(state).map(show => show.venue.state).sort())
};
export const filteredVenues = state => {
  return new Set(sortAndFilterShows(state).map(show => show.venue.name).sort())
};

const getYear = (showDate) => {
  return showDate.substring(0, 4)
}

export const filteredYears = state => {
  return new Set(sortAndFilterShows(state).map(show => getYear(show.date)).sort().reverse())
}