import RecordingFilterContainer from './RecordingFilterContainer';
import { connect } from 'react-redux';

import { setRecordingFilter } from 'actions/shows';

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      onSelect: filter => dispatch(setRecordingFilter(filter))
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
      filter: state.shows.recordingFilter
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RecordingFilterContainer);
