import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SongListView from 'components/SongListView';
import LoadingSongListView from "components/SongListView/LoadingSongListView/LoadingSongListView";

class SongListViewContainer extends Component {
  render(){
    const { songList, songTypeFilter, isFetching } = this.props;

    if (isFetching){
      return <LoadingSongListView/>
    } else {
      return (
        <SongListView
          songList={songList}
          songTypeFilter={songTypeFilter}
        />
      )
    }
  }
}

SongListViewContainer.propTypes = {
  songList: PropTypes.array,
  songTypeFilter: PropTypes.string,
  isFetching: PropTypes.bool
};

SongListViewContainer.defaultProps = {};

export default SongListViewContainer;
