import { API_POST } from "middleware/api/post";

export const SHOWBATCH_REQUEST = 'SHOWBATCH_REQUEST';
export const SHOWBATCH_SUCCESS = 'SHOWBATCH_SUCCESS';
export const SHOWBATCH_FAILURE = 'SHOWBATCH_FAILURE';
export const SHOWBATCH_INVALIDATE = 'SHOWBATCH_INVALIDATE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function postShowBatch(showBatch) {
  return {
    [API_POST]: {
      payload: showBatch,
      authenticated: true,
      endpoint: '/admin/shows/batch',
      shouldDispatch: function(store) {
        return true;
      },
      types: [SHOWBATCH_REQUEST, SHOWBATCH_SUCCESS, SHOWBATCH_FAILURE]
    }
  }
}

export function invalidateshowBatch(){
  return {
    type: SHOWBATCH_INVALIDATE
  }
}
