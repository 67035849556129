import App from './App';
import { connect } from 'react-redux';
import { fetchShows } from 'actions/shows';
import { fetchSongs } from 'actions/songs';
import { fetchUserProfile } from 'actions/user';
import { fetchLeaderboard } from 'actions/tourGamesLeaderboard';
import { fetchNextShow } from 'actions/tourGames';
import { Auth } from 'aws-amplify'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCheckForShows: () => dispatch(fetchShows()),
    onCheckForSongs: () => dispatch(fetchSongs()),
    onCheckForUserProfile: () => dispatch(fetchUserProfile()),
    OnCheckForLeaderboard: () => dispatch(fetchLeaderboard()),
    onCheckForNextTourGamesShow: () => dispatch(fetchNextShow()),
    onCheckForUser:  () => {
      Auth.currentAuthenticatedUser()
        .then(currentAuthenticatedUser => {
          dispatch({type: 'SET_COGNITO_USER', payload: currentAuthenticatedUser})
          })
        .catch(err => console.error(err))
    }

  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
