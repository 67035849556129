import AttendanceListContainer from './AttendanceListContainer'
import { connect } from 'react-redux';

import { fetchAttendance } from 'actions/attendance';
import { getVisibleShowDate, getVisibleShowId } from 'selectors/shows';
import { getVisibleShowAttendance, isVisibleShowAttendanceError, didInvalidateAttendance } from "selectors/attendance";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAttendance: showId => dispatch(fetchAttendance(showId))
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    date: getVisibleShowDate(state),
    showId: getVisibleShowId(state),
    attendance: getVisibleShowAttendance(state),
    error: isVisibleShowAttendanceError(state),
    invalidateAttendanceList: didInvalidateAttendance(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AttendanceListContainer);
