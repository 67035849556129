import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import Link from 'components/Link';

import './PrevLinkButton.css';

const PrevLinkButton = ({url, icon, text, ...props}) => (
    <Link className="PrevLinkButton" url={url}>
      <Button {...props}>
        <span className="icon">{icon}</span>
        <span className="hidden-xs text">{text}</span>
      </Button>
    </Link>
);

PrevLinkButton.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string
};

PrevLinkButton.defaultProps = {
  icon: "<-",
  text: "Prev"
};

export default PrevLinkButton;
