const getNameForDisplay = (song) =>
  song.name.length < 35 ? song.name : `${song.name.substr(0, 40)}...`;

/**
 * Gets the options for the first set picks from songs
 * @param {Array} songs
 * @returns Array
 */
const getFirstSetOptions = (songs, pickType) => {
  if (!songs) return [];

  if (pickType === 'other') {
    return [
      <option key='select-one' value={undefined}>
        Please make a pick
      </option>,
      <option key={'new-original'} value={'new-original'}>
        New Original
      </option>,
      <option key={'new-cover'} value={'new-cover'}>
        New Cover
      </option>
    ];
  }

  const nonJamSongs = songs.filter((song) => !isJam(song));
  const originals = nonJamSongs.filter(isOriginal);
  const covers = nonJamSongs.filter(isCover);

  const songsToUse = pickType !== 'cover' ? originals : covers;

  return [
    <option key='select-one' value={undefined}>
      Please make a pick
    </option>,
    ...songsToUse.map((song) => (
      <option key={song._id} value={song._id}>
        {getNameForDisplay(song)}
      </option>
    ))
  ];
};

/**
 * Gets the options for the second set picks from the songs, excluding what was the first set pick
 * @param {Array} songs
 * @param {string} firstSetPick
 * @returns Array
 */
const getSecondSetOptions = (songs, firstSetPick) => {
  if (!songs || !firstSetPick)
    return [<option value={undefined}>Please make a first set pick</option>];
  return [
    <option value={undefined}>...</option>,
    ...songs
      .filter((song) => song._id !== firstSetPick)
      .map((song) => (
        <option value={song._id}>{getNameForDisplay(song)}</option>
      ))
  ];
};

/**
 * Gets the options for the encore picks from the songs, excluding what was the first and second set picks
 * @param {Array} songs
 * @param {string} firstSetPick
 * @param {string} secondSetPick
 * @returns
 */
const getEncoreOptions = (songs, firstSetPick, secondSetPick) => {
  if (!songs || !secondSetPick)
    return [<option value={undefined}>Please make a second set pick</option>];
  const secondSetSongs = songs.filter((song) => song._id !== firstSetPick);

  return [
    <option value={undefined}>...</option>,
    ...secondSetSongs
      .filter((song) => song._id !== secondSetPick)
      .filter((song) => song._id !== secondSetPick)
      .map((song) => (
        <option value={song._id}>{getNameForDisplay(song)}</option>
      ))
  ];
};

/**
 * Gets the song for the list of songs by its id
 * @param {String} songId
 * @param {Song[]} songs
 * @returns Object
 */
const getSongForPick = (songId, songs) => {
  if (songId === 'new-original') {
    return { _id: 'new-original' };
  }

  if (songId === 'new-cover') {
    return { _id: 'new-cover' };
  }
  return songs?.find((song) => song._id === songId);
};

/**
 * Gets the ids for orignal songs
 * @param {Song[]} songs
 * @returns Array
 */
const getOriginalSongIds = (songs) => {
  const originalSongs = songs?.filter(isOriginal);
  const originalSongIds = originalSongs?.map((song) => song._id);
  return originalSongIds ?? [];
};

/**
 * Gets the ids for orignal songs
 * @param {Song[]} songs
 * @returns Array
 */
const getCoverSongIds = (songs) => {
  const coverSongs = songs?.filter(isCover);
  const coverSongIds = coverSongs?.map((song) => song._id);
  return coverSongIds ?? [];
};

const isJam = (song) => Boolean(song.isJam);

const isOriginal = (song) => Boolean(song?.writtenBy?.band === 'Spafford');

const isCover = (song) => !Boolean(song?.writtenBy?.band === 'Spafford');

export const tourGamesPicksFormHelper = {
  getFirstSetOptions,
  getSecondSetOptions,
  getEncoreOptions,
  getSongForPick,
  getOriginalSongIds,
  getCoverSongIds
};
