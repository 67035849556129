import { TEST_TOUR_GAMES_ID } from '../../..//constants';
import { API_GET } from 'middleware/api/get';

export const TOUR_GAMES_GET_NEXT_SHOW_REQUEST =
  'TOUR_GAMES_GET_NEXT_SHOW_REQUEST';
export const TOUR_GAMES_GET_NEXT_SHOW_SUCCESS =
  'TOUR_GAMES_GET_NEXT_SHOW_SUCCESS';
export const TOUR_GAMES_GET_NEXT_SHOW_FAILURE =
  'TOUR_GAMES_GET_NEXT_SHOW_FAILURE';

/**
 * Calls the API endpoint to fetch the next show
 * @param {String} tourId
 * @returns
 */
export function fetchNextShow(tourId = TEST_TOUR_GAMES_ID) {
  return {
    [API_GET]: {
      authenticated: false,
      endpoint: `/tourGames/${tourId}/nextShow`,
      shouldDispatch: function (store) {
        return true;
      },
      types: [
        TOUR_GAMES_GET_NEXT_SHOW_REQUEST,
        TOUR_GAMES_GET_NEXT_SHOW_SUCCESS,
        TOUR_GAMES_GET_NEXT_SHOW_FAILURE
      ]
    }
  };
}
