export const getIsPlaying = (state) => {
  return state.mediaPlayer.isPlaying;
};

export const isRecordingPlaying = (state, recording) => {
  const activeRecording = getActiveRecording(state);
  if (! activeRecording || ! recording ){
    return false;
  }
  return activeRecording.recordingData.identifier === recording.recordingData.identifier;
};

export const getIsPlayerExpanded = (state) => {
  const source = getActiveRecordingSource(state);
  return source === 'YouTube';
};

export const getActiveRecording = (state) => {
  return state.mediaPlayer.activeRecording;
};

export const getActiveRecordingSource = (state) => {
  const activeRecording = getActiveRecording(state);
  return activeRecording ? activeRecording.source : null;
};

export const getActiveRecordingTitle = (state) => {
  const activeRecording = getActiveRecording(state);
  const venue = getActiveRecordingVenue(state);
  switch (activeRecording.source){
    case "Archive":
      return activeRecording.recordingData.title;
    default:
      return activeRecording.recordingData.date
        ? `${activeRecording.recordingData.date.substr(0,10)} - ${venue.name} - ${venue.city}, ${venue.state}`
        : "Live Spafford"
  }

};

export const getActiveRecordingDate = (state) => {
  const activeRecording = getActiveRecording(state);
  return activeRecording ? activeRecording.recordingData.date.substr(0,10) : null;
};

export const getActiveRecordingVenue = (state) => {
  return getActiveRecording(state).venue;
};

export const getActiveRecordingShowLink = (state) => {
  const date = getActiveRecordingDate(state);
  return date ? `/shows/${date.substr(0,10)}` : '/shows';
};

export const isActiveRecordingFetchingTracks = state => {
  return state.mediaPlayer
    && state.mediaPlayer.activeRecording
    && state.mediaPlayer.activeRecording.isFetching
};

