import GoogleAnalytics from 'react-ga';

const ENV = {
  development: 'DEVELOPMENT',
  production: 'PRODUCTION'
};

const initialize = (environment) => {
  let gaKey;
  switch (environment) {
    case ENV.development:
      gaKey = 'UA-97827321-3';
      break;
     case ENV.staging:
      gaKey = 'UA-97827321-4';
      break;
    case ENV.production:
      gaKey = 'UA-97827321-1';
      break;
    default:
      throw new Error(`Unknown environment ${environment} specified in tracker.`)
  }

  GoogleAnalytics.initialize(gaKey);
};

const trackPage = (page, options = {}) => {
  GoogleAnalytics.set({
    page,
    ...options,
  });
  GoogleAnalytics.pageview(page);
};

const trackEvent = (category, action, label) => {
  GoogleAnalytics.event({
    label: label,
    action: action || 'default',
    category: category || 'default'
  });
};

// We use this kind of export to make importing a little more clear
//  this allows somebody to import:
//     import tracker from 'lib/tracker'
//  then use it like
//     tracker.initialize(tracker.ENV.production);
//     tracker.trackEvent(...);
const tracker = {
  ENV: ENV,
  initialize: initialize,
  trackPage: trackPage,
  trackEvent: trackEvent
}
export default tracker
