import React from 'react';
import PropTypes from 'prop-types';
import { filteredYears } from 'selectors/shows';

import DropDownFilter from '../DropDownFilter';
import { useDispatch, useSelector } from 'react-redux';
import { setYearFilter } from 'actions/shows';

const YearFilter = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.shows.yearFilter)
  const years = useSelector(state => filteredYears(state))
  let keys = [
    "Any Year",
    ...years
  ];
  let values = [
    null,
    ...years
  ];

  //TODO: update
  let index = !filter ? 0 : values.findIndex(value => value === filter);
  index = index < 0 ? 0 : index;

  const handleSelect = (filter) => dispatch(setYearFilter(filter))

  return (
    <DropDownFilter
      before="during"
      text={keys[index]}
      onSelect={handleSelect}
      keys={keys}
      values={values}
    />
  )
};


YearFilter.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string,
  years: PropTypes.array.isRequired
};

YearFilter.defaultProps = {
  filter: null
};


export default YearFilter;
