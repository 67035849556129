
import { API_POST } from 'middleware/api/post';

export const TOUR_GAMES_CREATE_TICKET_REQUEST = 'TOUR_GAMES_CREATE_TICKET_REQUEST';
export const TOUR_GAMES_CREATE_TICKET_SUCCESS = 'TOUR_GAMES_CREATE_TICKET_SUCCESS';
export const TOUR_GAMES_CREATE_TICKET_FAILURE = 'TOUR_GAMES_CREATE_TICKET_FAILURE';
export const TOUR_GAMES_CREATE_TICKET_INVALIDATE = 'TOUR_GAMES_CREATE_TICKET_INVALIDATE';

/**
 * Calls the API endpoint to create a ticket
 * @param {String} userId 
 * @param {String} tourId 
 * @param {String} gameType
 * @returns 
 */
export function createTicket(userId, userName, tourId, gameType = 'SpaffnerdsTourGames') {
  return {
    [API_POST] : {
      payload: {
        userName
      },
      authenticated: true,
      endpoint: `/tourGames/${tourId}/${gameType}/tickets/${userId}`,
      shouldDispatch: function(store) {
        return true;
      },
      types:[TOUR_GAMES_CREATE_TICKET_REQUEST, TOUR_GAMES_CREATE_TICKET_SUCCESS, TOUR_GAMES_CREATE_TICKET_FAILURE]
    }
  }
}