import UserProfileContainer from './UserProfileContainer';
import { connect } from 'react-redux';
import {
  isLoggedIn,
  isAdmin,
  getUsername,
  getIconBgColor,
  getIconText,
  getUserslug,
  getPictureUrl,
  getCognitoUser
} from 'selectors/user';
import { getForumUrl } from 'selectors/settings';

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      dispatchLogout: () => {
        dispatch({type: 'LOG_OUT_COGNITO_USER'})
      }
    };
};

const mapStateToProps = (state, ownProps) => {
    return {
      forumUrl: getForumUrl(state),
      isLoggedIn: isLoggedIn(state),
      username: getUsername(state),
      userslug: getUserslug(state),
      iconBgColor: getIconBgColor(state),
      iconText: getIconText(state),
      pictureUrl: getPictureUrl(state),
      isAdmin: isAdmin(state),
      cognitoUser: getCognitoUser(state)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserProfileContainer);
