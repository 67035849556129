import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import sort from 'lib/sort';

import StatLabel from 'components/StatLabel';

import './SetCountsRow.css';

const SetCountsRow = ({sets}) => (
  <Row className="SetCountsRow">
    {
      Object.keys(sets).sort(sort.set.name).map((setName, index, arr) => {
        const lg = (12 / arr.length);
        return (
          <Col md={lg} lg={lg} sm={6} xs={12} key={`${setName}_${index}`}>
            <h3>
              <StatLabel leadText={setName} count={sets[setName].count}/>
           </h3>
          </Col>
        )
      })
    }
  </Row>
);

SetCountsRow.propTypes = {
  sets: PropTypes.object
};

SetCountsRow.defaultProps = {};

export default SetCountsRow;
