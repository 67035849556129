import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';

import ProfileIconContainer from 'containers/UserProfileContainer/ProfileIconContainer';
import NavigationLinksContainer from 'containers/UserProfileContainer/NavigationLinksContainer';

import '../MobileNav.css';
import './LoggedInMobileNav.css';

const LoggedInMobileNav = ({isAdmin, forumUrl, username, userslug, slideout}) => (
  <div className="MobileNav">
    <section>
      <ProfileIconContainer/>
    </section>
    <section>
      <h1>Navigation</h1>
      <NavigationLinksContainer slideout={slideout}/>
    </section>
    { isAdmin &&
    <section>
      <h1>Admin</h1>
      <ul>
        <li>
          <Link url="/admin/shows/upload" onClick={() => slideout && slideout.close()}> Upload Shows</Link>
        </li>
        <li>
          <Link url="/tourgames/admin" onClick={() => slideout && slideout.close()}> Tour Games Admin</Link>
        </li>
      </ul>
    </section>
    }
    <section>
      <h1>Profile</h1>
      <ul className="MobileProfileLinks">
        <li>
          <Link url={`${forumUrl}/user/${userslug}`}>
            <span>{username}</span>
          </Link>
        </li>
        <li className="divider" />
        <li>
          <Link url={`${forumUrl}/user/${userslug}/edit`}>
            <i className="fa fa-fw fa-edit"/><span> Edit Profile</span>
          </Link>
        </li>
        <li>
          <Link url={`${forumUrl}/user/${userslug}/settings`}>
            <i className="fa fa-fw fa-gear"/><span> Settings</span>
          </Link>
        </li>
        <li className="divider" />
        <li>
          <Link url="/logout">
            <i className="fa fa-fw fa-sign-out"/><span> Logout</span>
          </Link>
        </li>
      </ul>
    </section>
    <section>
      <h1>Notifications</h1>
      <ul>
        <li>
          <Link url={`${forumUrl}/notifications`}>See all notifications</Link>
        </li>
      </ul>
    </section>
    <section>
      <h1>Chats</h1>
      <ul>
        <li>
          <Link url={`${forumUrl}/user/${username}`}>See all chats</Link>
        </li>
      </ul>
    </section>
  </div>
);

LoggedInMobileNav.propTypes = {
  forumUrl: PropTypes.string,
  username: PropTypes.string,
  userslug: PropTypes.string,
  slideout: PropTypes.node,
};

LoggedInMobileNav.defaultProps = {
  forumUrl: "https://forum.spaffnerds.com"
};

export default LoggedInMobileNav;
