import { API_GET } from 'middleware/api/get';

import { fetchTicketHelper as helper } from './fetchTicketHelper';
export const TOUR_GAMES_GET_TICKET_REQUEST = 'TOUR_GAMES_GET_TICKET_REQUEST';
export const TOUR_GAMES_GET_TICKET_SUCCESS = 'TOUR_GAMES_GET_TICKET_SUCCESS';
export const TOUR_GAMES_GET_TICKET_FAILURE = 'TOUR_GAMES_GET_TICKET_FAILURE';

export const TOUR_GAMES_GET_OTHER_USER_TICKET_REQUEST =
  'TOUR_GAMES_GET_OTHER_USER_TICKET_REQUEST';
export const TOUR_GAMES_GET_OTHER_USER_TICKET_SUCCESS =
  'TOUR_GAMES_GET_OTHER_USER_TICKET_SUCCESS';
export const TOUR_GAMES_GET_OTHER_USER_TICKET_FAILURE =
  'TOUR_GAMES_GET_OTHER_USER_TICKET_FAILURE';

const SELF_FETCH_ACTIONS = [
  TOUR_GAMES_GET_TICKET_REQUEST,
  TOUR_GAMES_GET_TICKET_SUCCESS,
  TOUR_GAMES_GET_TICKET_FAILURE
];

const USER_FETCH_ACTIONS = [
  TOUR_GAMES_GET_OTHER_USER_TICKET_REQUEST,
  TOUR_GAMES_GET_OTHER_USER_TICKET_SUCCESS,
  TOUR_GAMES_GET_OTHER_USER_TICKET_FAILURE
];

/**
 * Calls the API endpoint to fetch a ticket
 * @param {String} userId
 * @param {String} tourId
 * @param {String} gameType
 * @returns
 */
export function fetchTicket(
  userId,
  tourId,
  gameType = 'SpaffnerdsTourGames',
  ticketId = 1,
  otherUser = false
) {
  return {
    [API_GET]: {
      authenticated: false,
      endpoint: `/tourGames/${tourId}/${gameType}/tickets/${userId}/${ticketId}`,
      shouldDispatch: function (store) {
        const tourGames = store?.getState()?.tourGames;

        if (!tourGames.ticket) {
          return true;
        } else if (
          !helper.doesUserTicketExist(userId, tourGames?.userTickets)
        ) {
          return true;
        } else if (tourGames.isFetching) {
          return false;
        } else {
          return tourGames.didInvalidate;
        }
      },
      types: otherUser ? USER_FETCH_ACTIONS : SELF_FETCH_ACTIONS
    }
  };
}
