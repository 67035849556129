import React from 'react';
import PropTypes from 'prop-types';

import CurrentTrackTime from "components/MediaPlayer/MediaPlayerHeading/ArchiveTitle/CurrentTrackTime";
import TextTicker from "components/TextTicker";
import Link from "components/Link";

import './ArchiveTrackHeading.css';

const ArchiveTrackHeading = ({trackTitle, recordingTitle, recordingLink, trackLength, trackTime, showLink, isBufferedPlaying, className}) => {
  return (
    <div className={`ArchiveTrackHeading ${className ? className : ''}`}>
      <span className="hidden-xs">
        <span className="track-title">{trackTitle}</span>
        <Link className="show-link" onClick={(e) => { e.stopPropagation() }} url={showLink}>
          <span className="recording-title">{recordingTitle}</span>
        </Link>
        <Link onClick={(e) => { e.stopPropagation(); }} className='archive-link' url={recordingLink}>
          <i className='fa fa-external-link' />
        </Link>
        <CurrentTrackTime
          isBufferedPlaying={isBufferedPlaying}
          time={trackTime}
          length={trackLength}
        />
      </span>
      <span className="hidden-sm hidden-md hidden-lg">
        <div className="track-title text-center">
          <div>
            {trackTitle}
            <Link onClick={(e) => { e.stopPropagation(); }} className='archive-link' url={recordingLink}>
              <i className='fa fa-external-link' />
            </Link>
          </div>
        </div>
        <div className="text-center">
          <CurrentTrackTime
            isBufferedPlaying={isBufferedPlaying}
            time={trackTime}
            length={trackLength}
          />
        </div>
        <Link className="show-link" onClick={(e) => { e.stopPropagation() }} url={showLink}>
          <TextTicker items={[recordingTitle]}/>
        </Link>
      </span>
    </div>
  )
};

ArchiveTrackHeading.propTypes = {
  showLink: PropTypes.string,
  trackTitle: PropTypes.string,
  recordingTitle: PropTypes.string,
  recordingLink: PropTypes.string,
  trackLength: PropTypes.string,
  trackTime: PropTypes.string
};

ArchiveTrackHeading.defaultProps = {
  showLink: '/shows'
};

export default ArchiveTrackHeading;
