import ShowDateFilterContainer from './ShowDateFilterContainer';
import { connect } from 'react-redux';

import { setShowDateFilter } from 'actions/shows';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSelect: filter => dispatch(setShowDateFilter(filter))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    dateFilter: state.shows.dateFilter
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowDateFilterContainer);
