import React from 'react';
import { Grid } from 'react-bootstrap';
import ErrorView from 'components/ErrorView';
import useUser from 'hooks/useUser';
import AdminShowList from './AdminShowList';
import './TourGamesAdmin.css';

const TourGamesAdmin = () => {
  const { isAdmin } = useUser();

  if (!isAdmin) {
    return <ErrorView status={404} message={'Not Found'} />;
  }

  return (
    <Grid className='TourGamesAdmin'>
      <h3>Tour Games Admin Panel</h3>
      <AdminShowList />
    </Grid>
  );
};

export default TourGamesAdmin;
