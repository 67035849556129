import React from 'react';
import PropTypes from 'prop-types';

import DetailContentRow from 'components/DetailView/DetailContentRow';
import ShowListTable from 'components/ShowListTable';

import './PlayedAtRow.css';

const PlayedAtRow = ({showList}) => (
  <div className="PlayedAtRow">
    <hr />
    <DetailContentRow heading="Played At">
      <ShowListTable showList={showList} />
    </DetailContentRow>
  </div>
);

PlayedAtRow.propTypes = {
  showList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.string.isRequired,
    recordings: PropTypes.array,
    recordingCount: PropTypes.number,
    guests: PropTypes.array,
    songNotes: PropTypes.array,
    showNotes: PropTypes.object,
    venue: PropTypes.shape({
      name: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string
    }).isRequired
  }))
};

PlayedAtRow.defaultProps = {};

export default PlayedAtRow;
