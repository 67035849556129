import React from 'react';
import PropTypes from 'prop-types';

import sort from 'lib/sort';

import './SetCounts.css';
import StatLabel from "components/StatLabel";

const SetCounts = ({setStats, className}) => {
  return (
    Object.keys(setStats).sort(sort.set.name).map( set => {
      return (
        <StatLabel
          className={className ? `SetCounts ${className}` : "SetCounts"}
          leadText="Seen"
          count={setStats[set].count}
          label="time"
          showColon={false}
          trailingText={set}
        />
      )
    })
  )
};

SetCounts.propTypes = {
  setStats: PropTypes.object,
  className: PropTypes.string
};

SetCounts.defaultProps = {
  className: "h3"
};

export default SetCounts;
