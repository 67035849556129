import { getVisibleShowId } from 'selectors/shows';
import { getUsername } from 'selectors/user'

export const isVisibleShowAttendanceError = state => {
  const selectedShowId = getVisibleShowId(state);
  return state.attendance.byShowId[selectedShowId] && state.attendance.byShowId[selectedShowId].isError
};

export const getVisibleShowAttendance = state => {
  const selectedShowId = getVisibleShowId(state);
  return state.attendance.byShowId[selectedShowId] && state.attendance.byShowId[selectedShowId].items
};

export const didUserAttendVisibleShow = state => {
  let userName = getUsername(state);
  let visibleShowAttendance = getVisibleShowAttendance(state) || [];
  return !! visibleShowAttendance.find(item => item.username === userName)
};

export const didInvalidateAttendance = state => {
  const selectedShowId = getVisibleShowId(state);
  const visibleShowAttendance = state.attendance.byShowId[selectedShowId];
  return visibleShowAttendance && visibleShowAttendance.didInvalidate;
};

