import React from 'react'
import PropTypes from 'prop-types'

import SongTypeFilter from 'components/SongListView/SongTypeFilter';

const SongTypeFilterContainer = ({onSelect, filter}) => (
  <SongTypeFilter onSelect={onSelect} filter={filter} />
);

SongTypeFilterContainer.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string
};

SongTypeFilterContainer.defaultProps = {};

export default SongTypeFilterContainer;
