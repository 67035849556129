import React from 'react';

import DetailContentRow from 'components/DetailView/DetailContentRow';

import ShowDateFilterContainer from 'containers/ShowDateFilterContainer';
import RecordingFilterContainer from 'containers/RecordingFilterContainer';
import StateFilterContainer from 'containers/StateFilterContainer';
import VenueFilterContainer from 'containers/VenueFilterContainer';
import YearFilterContainer from 'containers/YearFilterContainer';

import './ShowListFiltersRow.css';

const ShowListFiltersRow = () => (
  <DetailContentRow className="ShowListFiltersRow">
    <ShowDateFilterContainer /><span>, </span>
    <RecordingFilterContainer /><span>, </span>
    <StateFilterContainer /><span>, </span>
    <VenueFilterContainer/><span>, </span>
    <YearFilterContainer/>
  </DetailContentRow>
);

ShowListFiltersRow.propTypes = {};

ShowListFiltersRow.defaultProps = {};

export default ShowListFiltersRow;
