import React from 'react';

import UserProfileContainer from "containers/UserProfileContainer";
import ProfileLinks from 'components/Nav/DesktopNav/ProfileLinks';

const ProfileLinksContainer = ({...props}) => {
  return (
    <UserProfileContainer>
      <ProfileLinks {...props}/>
    </UserProfileContainer>
  );
};

ProfileLinksContainer.propTypes = {
};

ProfileLinksContainer.defaultProps = {
};

export default ProfileLinksContainer;
