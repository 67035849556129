import { Auth } from 'aws-amplify'

export const signUp = async (userInfo, setCognitoUser) => {
  try {
    const { user } = await Auth.signUp({
      username: userInfo?.email,
      password: userInfo?.password,
      attributes: {
        email: userInfo?.email,
        given_name: userInfo?.firstName,
        family_name: userInfo?.lastName,
        preferred_username: userInfo?.username
      },
      // Doesn't seem to work?
      // autoSignIn: { // optional - enables auto sign in after user is confirmed
      //   enabled: true,
      // }
    });
    
    setCognitoUser(user)
  } catch (error) {
    console.log('error signing up:', error);
  }
}

export const confirmSignUp  = async (userInfo, clearUser, forwardToLogin) => {
  try {
    await Auth.confirmSignUp(userInfo?.email, userInfo?.code);
    clearUser()
    forwardToLogin()
  } catch (error) {
    console.log('error confirming sign up', error);
  }
}

export const signIn = async (userInfo, setCognitoUser) => {
  try {
    const user = await Auth.signIn(userInfo?.email, userInfo?.password);
    const tok = user?.signInUserSession?.idToken?.jwtToken
    document.cookie = `jwt=${tok}`
    setCognitoUser(user)
  } catch (error) {
    console.log('error signing in', error);
  }
}

export const signOut = async (clearCognitoUser) => {
  try {
    await Auth.signOut();
    clearCognitoUser()
    console.log('signed out')
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export const resendConfirmationCode = async(username) => {
  try {
    await Auth.resendSignUp(username);
    alert('code resent successfully');
  } catch (err) {
    console.log('error resending code: ', err);
  }
}