import { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps){
    const { location: oldLocation  } = this.props;
    const { location: newLocation } = prevProps;
    if ( oldLocation !== newLocation ) {
      window.scrollTo(0,0)
    }
  }

  render(){
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.string,
  children: PropTypes.node
};

ScrollToTop.defaultProps = {
};

export default withRouter(ScrollToTop);
