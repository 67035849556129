import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import DateBadges from "components/ShowListTable/DateBadges";
import StatCard from 'components/UserDetailView/StatCard';
import SongStatSummary from "components/UserDetailView/UserSongStats/SongStatCard/SongStatSummary";
import SetCounts from "components/UserDetailView/UserSongStats/SongStatCard/SetCounts";

import './SongStatCard.css';
class SongStatCard extends Component {

  shouldComponentUpdate(nextProps, nextState){
    // right now we only care about the songId
    //  but we might, at some point, want to
    //  render when some other prop changes
    return nextProps.songId !== this.props.songId;
  };

  render(){
    const {songId, songName,
      playCount, lastPlayed, avgGap, longestGap,
      shows, setStats} = this.props;
    return (
      <StatCard
        className="SongStatCard"
        count={playCount} text={songName}
        cardHeader={
          <div>
            <h3>
              <Link url={`/songs/${songId}`}>{songName}</Link>
            </h3>
            <SetCounts setStats={setStats} className="h4"/>
          </div>
        }
        cardBody={
          <div>
            <SongStatSummary
              longestGap={longestGap}
              lastPlayed={lastPlayed}
              avgGap={avgGap}
            />
            <h4>Played At</h4>
            { shows.map( show => (
                <DateBadges key={show.date}
                            date={show.date}
                            recordingCount={show.recordingCount}
                            guests={show.guests}
                            showNotes={show.showNotes}
                            songNotes={show.songNotes.join(', ')}
                />
                )
              )
            }
          </div>
        }
      />
    )
  }
}

SongStatCard.propTypes = {
  songId: PropTypes.string.isRequired,
  songName: PropTypes.string.isRequired,
  playCount: PropTypes.number.isRequired,
  lastPlayed: PropTypes.number.isRequired,
  avgGap: PropTypes.number.isRequired,
  longestGap: PropTypes.number.isRequired,
  shows: PropTypes.array.isRequired,
  setStats: PropTypes.object.isRequired
};

SongStatCard.defaultProps = {
  shows: []
};

export default SongStatCard;
