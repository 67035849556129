import React from 'react';
import PropTypes from 'prop-types';
import ClientSideButton from "components/ClientSideButton/ClientSideButton";
import { Button } from 'react-bootstrap';
import Link from "components/Link";



const AddRecordingButtonContainer = ({onClick, type, userId, forumUrl}) => {
  let wrapper, realOnClick;
  if (! userId){
    wrapper = (el) => (
      <Link url={`${forumUrl}/login`} target="_blank">
        {el}
      </Link>
    );
    realOnClick = () => {};
  } else {
    wrapper = (el) => el;
    realOnClick = (streamOnly) => onClick(streamOnly);
  }
  switch (type) {
    case 'past':
      return wrapper(<Button onClick={() => { realOnClick() }} className="active"><i className="fa fa-volume-up"/><span>Add A Recording</span></Button>);
    case 'present':
    case 'future':
      return wrapper(<Button onClick={() => { realOnClick(true) }} className="active"><i className="fa fa-volume-up"/><span>Post A Stream</span></Button>);
    default:
      return wrapper(<ClientSideButton onClick={() => { realOnClick() }} fa='fa fa-plus-square' text='Submit Recording' />);
  }

};

AddRecordingButtonContainer.propTypes = {
  forumUrl: PropTypes.string
};

AddRecordingButtonContainer.defaultProps = {
  forumUrl: "https://forum.spaffnerds.com"
};

export default AddRecordingButtonContainer;
