import React from 'react';
import PropTypes from 'prop-types';

import './ErrorView.css';
import DetailView from "components/DetailView/DetailView";
import DetailContentRow from "components/DetailView/DetailContentRow/DetailContentRow";
import Link from "components/Link/Link";

const ErrorView = ({status, message, stack, responseType, url}) => {
  const slug= <span className="error">{message}</span>;
  return (
    <DetailView
      className="ErrorView"
      heading={`Error: ${status}`}
      slug={slug}
    >
      <DetailContentRow>
        <hr />
        <p>There was an error loading the page that you requested.  Please refresh the page or head back <Link url="/">home</Link>.</p>
        <p>You could also try checking the <Link url="https://spaffnerds.com/help">help section</Link> for more information.</p>
        <hr />
      </DetailContentRow>
    </DetailView>
  )
};

ErrorView.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  stack: PropTypes.string,
  responseType: PropTypes.string,
  url: PropTypes.string
};

ErrorView.defaultProps = {
};

export default ErrorView;
