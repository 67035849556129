import React from 'react';
import PropTypes from 'prop-types';

import ArchiveTrackHeading from 'components/MediaPlayer/ArchiveTrackHeading';

const ArchiveTrackHeadingContainer = ({trackTitle, recordingTitle, recordingLink, trackLength, trackTime, showLink, source, isPlaying, ...props}) => {
  if (source !== 'Archive'){
    return null
  }
  return (
    <ArchiveTrackHeading
      trackTitle={trackTitle}
      recordingTitle={recordingTitle}
      trackTime={trackTime}
      trackLength={trackLength}
      showLink={showLink}
      recordingLink={recordingLink}
      isPlaying={isPlaying}
      {...props}
    />
  )
};

ArchiveTrackHeadingContainer.propTypes = {
  source: PropTypes.string,
  trackTitle: PropTypes.string,
  recordingTitle: PropTypes.string,
  trackLength: PropTypes.string,
  trackTime: PropTypes.string,
  showLink: PropTypes.string,
  recordingLink: PropTypes.string
};

ArchiveTrackHeadingContainer.defaultProps = {
};

export default ArchiveTrackHeadingContainer;
