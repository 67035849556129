import React from 'react';
import PropTypes from 'prop-types';

import DetailContentRow from 'components/DetailView/DetailContentRow';

import SetCountsRow from './SetCountsRow';
import GapCountsRow from './GapCountsRow';

import './SongStatsRow.css';

const SongStatsRow = ({songStats}) => (
  <DetailContentRow className="SongStatsRow">
    <hr />
    <SetCountsRow sets={songStats.setStats} />
    <GapCountsRow playStats={songStats.playStats} />
  </DetailContentRow>
);

SongStatsRow.propTypes = {
  songStats: PropTypes.object.isRequired
};

SongStatsRow.defaultProps = {};

export default SongStatsRow;
