import { API_GET } from "middleware/api/get";

export const SHOW_REQUEST = 'SHOW_REQUEST';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SHOW_FAILURE = 'SHOW_FAILURE';

export const SHOW_ID_REQUEST = 'SHOW_ID_REQUEST';
export const SHOW_ID_SUCCESS = 'SHOW_ID_SUCCESS';
export const SHOW_ID_FAILURE = 'SHOW_ID_FAILURE';

export const SHOW_RECDATA_REQUEST = 'SHOW_RECDATA_REQUEST';
export const SHOW_RECDATA_SUCCESS = 'SHOW_RECDATA_SUCCESS';
export const SHOW_RECDATA_FAILURE = 'SHOW_RECDATA_FAILURE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function fetchShows() {
  return {
    [API_GET]: {
      endpoint: '/shows?output=redux',
      shouldDispatch: function(store) {
        const shows = store.getState().shows.list;
        if (! shows.items || ! shows.items.length ){
          return true;
        } else if (shows.isFetching) {
          return false;
        } else {
          return shows.didInvalidate;
        }
      },
      types: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE]
    }
  }
}

export function fetchShow(showId) {
  return {
    [API_GET]: {
      id: showId,
      endpoint: `/shows/${showId}`,
      shouldDispatch: function(store) {
        // TODO: Need to explore what the right shouldDispatch criteria is for fetchShow
        return true;
        // const shows = store.getState().shows.list;
        // if (! shows.items || ! shows.items.length ){
        //   return true;
        // } else if (shows.isFetching) {
        //   return false;
        // } else {
        //   return shows.didInvalidate;
        // }
        // //TODO: This is a good place to check if the cached show data is ready to be replaced
      },
      types: [SHOW_ID_REQUEST, SHOW_ID_SUCCESS, SHOW_ID_FAILURE]
    }
  }
}

export function fetchLatestShow() {
  return {
    [API_GET]: {
      id: null,
      endpoint: `/shows/latest`,
      shouldDispatch: function(store) {
        // TODO: Need to explore what the right shouldDispatch criteria is for fetchShow
        return true;
        // const shows = store.getState().shows.list;
        // if (! shows.items || ! shows.items.length ){
        //   return true;
        // } else if (shows.isFetching) {
        //   return false;
        // } else {
        //   return shows.didInvalidate;
        // }
        // //TODO: This is a good place to check if the cached show data is ready to be replaced
      },
      types: [SHOW_ID_REQUEST, SHOW_ID_SUCCESS, SHOW_ID_FAILURE]
    }
  }
}


export function fetchShowRecordings(showId) {
  return {
    [API_GET]: {
      id: showId,
      endpoint: `/shows/${showId}?output=recdata`,
      shouldDispatch: function(store) {
        // TODO: Need to explore what the right shouldDispatch criteria is for fetchShow
        return true;
        // const shows = store.getState().shows.list;
        // if (! shows.items || ! shows.items.length ){
        //   return true;
        // } else if (shows.isFetching) {
        //   return false;
        // } else {
        //   return shows.didInvalidate;
        // }
        // //TODO: This is a good place to check if the cached show data is ready to be replaced
      },
      types: [SHOW_RECDATA_REQUEST, SHOW_RECDATA_SUCCESS, SHOW_RECDATA_FAILURE]
    }
  }
}

