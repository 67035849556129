import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ArchiveTitle from 'components/MediaPlayer/MediaPlayerHeading/ArchiveTitle';

class MPArchiveTitleContainer extends Component {
  constructor(props) {
    super(props);
    this.playFirstTrack();
  }

  componentDidUpdate(prevProps) {
    const {audioTracks} = this.props;
    const {audioTracks: prevAudioTracks} = prevProps;
    // it's possible that we need to sort both arrays before comparing
    // if we notice that a particular recording is always seeing itself
    // as changed it might be worth consideration
    if (!_.isEqual(prevAudioTracks, audioTracks)) {
      this.playFirstTrack()
    }
  }

  playFirstTrack() {
    const {audioTracks, playTrack} = this.props;
    playTrack(audioTracks[0]);
  }

  render() {
    // remove the audioTracks so we don't pass them down
    const {audioTracks, ...props} = this.props;
    return (
      <ArchiveTitle
        {...props}
      />
    )
  };
}

MPArchiveTitleContainer.propTypes = {
  playTrack: PropTypes.func,
  audioTracks: PropTypes.arrayOf(
    PropTypes.shape({
      track: PropTypes.number.isRequired
    })
  ),
  updateTime: PropTypes.func,
  setPlayerState: PropTypes.func,
  isPlaying: PropTypes.bool,
  currentTrackTime: PropTypes.string,
  recordingTitle: PropTypes.string,
  currentTrack: PropTypes.object,
  nextTrack: PropTypes.object,
  prevTrack: PropTypes.object,
  currentTrackURL: PropTypes.string,
};

MPArchiveTitleContainer.defaultProps = {};

export default MPArchiveTitleContainer;
