import React from 'react';

import UserProfileContainer from "containers/UserProfileContainer";
import MobileNav from 'components/Nav/MobileNav';

const MobileNavContainer = ({...props}) => {
  return (
    <UserProfileContainer>
      <MobileNav {...props}/>
    </UserProfileContainer>
  );
};

MobileNavContainer.propTypes = {
};

MobileNavContainer.defaultProps = {
};

export default MobileNavContainer;
