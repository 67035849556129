import { API_GET } from "middleware/api/get";

export const USERPROFILE_REQUEST = 'USERPROFILE_REQUEST';
export const USERPROFILE_SUCCESS = 'USERPROFILE_SUCCESS';
export const USERPROFILE_FAILURE = 'USERPROFILE_FAILURE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function fetchUserProfile() {
  return {
    [API_GET]: {
      authenticated: true,
      endpoint: '/users',
      shouldDispatch: function(store) {
        return true;
      },
      types: [USERPROFILE_REQUEST, USERPROFILE_SUCCESS, USERPROFILE_FAILURE]
    }
  }
}

