import React, { Component } from 'react';
import tracker from 'lib/tracker';

// load the tracker environment from the config
tracker.initialize(tracker.ENV[process.env.REACT_APP_GA_TRACKER_ENV]);

const withTracker = (WrappedComponent, options = {}) => {

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      tracker.trackPage(page, options);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        tracker.trackPage(nextPage, options);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;