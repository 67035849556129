import React, { Component } from 'react'; import PropTypes from 'prop-types';
import DetailContentRow from "components/DetailView/DetailContentRow/DetailContentRow";
import { FormControl, Button } from 'react-bootstrap';
import UserSubmission from "components/ShowDetailView/AddRecordingRow/UserSubmission/UserSubmission";

import './AddRecordingRow.css';

class AddRecordingRow extends Component {
  constructor(props){
    super(props);
    this.handleRecordingTypeChange = this.handleRecordingTypeChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.FormComponent = this.FormComponent.bind(this);
    this.state = {
      recordingType: ""
    }
  }
  handleRecordingTypeChange(e) {
    this.setState({
      recordingType: e.target.value
    });
  }

  onButtonClick(e, subState){
    const { onAddRecording } = this.props;
    const { recordingType } = this.state;
    const { description, url } = subState;

    onAddRecording && onAddRecording({type:recordingType, description, url});
  }

  FormComponent({recordingType, disabled}) {
    const { onCancel } = this.props;
    switch (recordingType) {
      case "Mixlr":
        return <UserSubmission
          disabled={disabled}
          onSubmit={this.onButtonClick}
          onCancel={onCancel}
          key={recordingType}
          inputs={[
            { id: 'description',
              placeholder: "Streamer's Name",
              label: "Streamer"
            },
            {
              id: 'url',
              placeholder: "",
              label: "Mixlr Link"
            }
          ]}
        />;
      case "MixlrShowReel":
        return <UserSubmission
          disabled={disabled}
          onSubmit={this.onButtonClick}
          onCancel={onCancel}
          key={recordingType}
          inputs={[
            { id: 'description',
              placeholder: "ie: Spaffnerds: Set 1",
              label: "Streamer and Set #"
            },
            {
              id: 'url',
              placeholder: "ie: http://mixlr.com/spaffnerds/showreel",
              label: "ShowReel Link"
            }
          ]}
        />;
      case "YouTube":
        return <UserSubmission
          disabled={disabled}
          onSubmit={this.onButtonClick}
          onCancel={onCancel}
          key={recordingType}
          inputs={[
            { id: 'description',
              placeholder: "'Full Show' or song,song,song",
              label: "Content"
            },
            {
              id: 'url',
              placeholder: "",
              label: "YouTube Link"
            }]}
        />;
      case "SoundCloud":
        return <UserSubmission
          disabled={disabled}
          onSubmit={this.onButtonClick}
          onCancel={onCancel}
          key={recordingType}
          inputs={[
            { id: 'description',
              placeholder: "'Full Show' or song,song,song",
              label: "Content"
            },
            {
              id: 'url',
              placeholder: "",
              label: "SoundCloud Link"
            }]}
        />;
      case "GenericSoundboard":
      case "GenericAud":
      case "GenericVideo":
        return <UserSubmission
          disabled={disabled}
          onSubmit={this.onButtonClick}
          onCancel={onCancel}
          key={recordingType}
          inputs={[
            { id: 'description',
              placeholder: "'Full Show' or song,song,song",
              label: "Content"
            },
            {
              id: 'url',
              placeholder: "",
              label: "Link"
            }]}
        />;
      default:
        return <span />
    }
  }

  render(){
    const { recordingType } = this.state;
    const { onCancel, streamOnly, disabled } = this.props;
    return (
      <div className="AddRecordingRow">
        <hr />
        <DetailContentRow heading={
          <span className="heading">
            <span className="h3">
              { streamOnly
                 ? 'Post a Stream'
                 : 'Submit Recording'
              }
            </span>
            { disabled
              ? ''
              : <Button onClick={onCancel} className='btn-sm cancel'>X</Button>
            }
          </span>
        }>
          <FormControl disabled={disabled} componentClass="select"
                       onChange={this.handleRecordingTypeChange}>
            <option value="">Choose one</option>
            <option value="Mixlr">Mixlr Live Stream</option>
            <option value="YouTube">YouTube</option>,
            <option value="GenericAud">Aud: Any Source</option>,
            <option value="GenericVideo">Video: Any Source</option>
            { streamOnly ? '' :
              [
                <option key="MixlrShowReel" value="MixlrShowReel">Mixlr Saved Showreel</option>,
                <option key="SoundCloud" value="SoundCloud">SoundCloud</option>,
                <option key="GenericSoundboard" value="GenericSoundboard">Soundboard: Any Source</option>,
              ]
            }

          </FormControl>
          <this.FormComponent disabled={disabled} recordingType={recordingType} />
        </DetailContentRow>
      </div>
    )
  }
}

AddRecordingRow.propTypes = {
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onAddRecording: PropTypes.func,
  streamOnly: PropTypes.bool
};

AddRecordingRow.defaultProps = {
};

export default AddRecordingRow;
