import React from 'react'

import './Loading.css'

const Loading = ({className, ...props}) => (
  <div className={className ? `Loading ${className}` : 'Loading'} {...props}>
      <span className="btn-loading">
        <i className="fa fa-spinner fa-5x fa-spin" />
      </span>
  </div>
);

export default Loading
