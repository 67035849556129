import React from 'react';
import PropTypes from 'prop-types';

import PullToRefresh from 'react-simple-pull-to-refresh';

const PullDownRefreshContainer = ({children, onShowRefresh, onHomepageRefresh, selectedShowId, latestShowId, isFetching}) => (
    <PullToRefresh
        onRefresh={
            selectedShowId === latestShowId
            ? onHomepageRefresh
            : () => onShowRefresh(selectedShowId)
        }
        className="PullDownRefresh"
        maxPullDownDistance={0}
    >
        {children}
    </PullToRefresh>
);

PullDownRefreshContainer.propTypes = {
    children: PropTypes.node,
    onRefresh: PropTypes.func
};

PullDownRefreshContainer.defaultProps = {
};

export default PullDownRefreshContainer;
