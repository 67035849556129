export const getAll = state => {
  return state.userShowToggle
};

export const isFetching = state => {
  return ! state.userShowToggle.isError && state.userShowToggle.isFetching && ! state.userShowToggle.didInvalidate
};

export const isError = state => {
  return state.userShowToggle.isError
    && ! state.userShowToggle.isFetching
    && ! state.userShowToggle.didInvalidate
};

export const isDisabled = state => {
  return state.userShowToggle.disabled
};

export const isSuccess = state => {
  const data = state.userShowToggle.data;
  const isEmpty = !data || ( data && Object.keys(data).length === 0 && data.constructor === Object);
  return data && ! isEmpty
    && ! state.userShowToggle.isFetching
    && ! state.userShowToggle.didInvalidate
};