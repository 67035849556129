import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';

import ArchiveBodyContainer from "containers/MediaPlayerContainer/ArchiveBodyContainer";
import YouTubeBodyContainer from "containers/MediaPlayerContainer/YouTubeBodyContainer";

import './MediaPlayerBody.css';

const MediaPlayerBody = ({source}) => {
  return (
    <Panel.Body className="MediaPlayerBody">
      {((source) => {
        switch (source) {
          case "YouTube":
            return (
              <YouTubeBodyContainer />
            );
          case "Archive":
            return (
              <ArchiveBodyContainer />
            );
          default:
            return null;
        }
      })(source)}
    </Panel.Body>
  )
};

MediaPlayerBody.propTypes = {
  source: PropTypes.string
};

MediaPlayerBody.defaultProps = {
};

export default MediaPlayerBody;
