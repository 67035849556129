export const SET_SHOW_DATE_FILTER = 'SET_SHOW_DATE_FILTER';
export const CLEAR_SHOW_DATE_FILTER = 'CLEAR_SHOW_DATE_FILTER';

export const ShowDateFilters = {
  CURRENT: "CURRENT",
  FUTURE: "FUTURE",
  YEAR: "YEAR"
};

export function setShowDateFilter(filter) {
  return {
    type: SET_SHOW_DATE_FILTER,
    filter: filter
  }
}
export function clearShowDateFilter(){
  return {
    type: CLEAR_SHOW_DATE_FILTER
  }
}


export const SET_RECORDING_FILTER = 'SET_RECORDING_FILTER';
export const CLEAR_RECORDING_FILTER = 'CLEAR_RECORDING_FILTER';

export const RecordingFilters = {
  ANY: "yes",
  NONE: "no",
  NUGS: "nugs",
  AUDIO: "audio",
  AUDIO_SBD: "soundboard",
  AUDIO_AUD: "aud",
  AUDIO_NUGS: "nugs-audio",
  AUDIO_SBD_FREE: "free-soundboard",
  VIDEO: "video",
  VIDEO_ALL: "video-all",
  VIDEO_SOME: "video-some",
  VIDEO_NUGS: "nugs-video"
};

export function setRecordingFilter(filter){
  return {
    type: SET_RECORDING_FILTER,
    filter: filter
  }
}
export function clearRecordingFilter(){
  return {
    type: CLEAR_RECORDING_FILTER
  }
}


export const SET_STATE_FILTER = 'SET_STATE_FILTER';
export const CLEAR_STATE_FILTER = 'CLEAR_STATE_FILTER';

export function setStateFilter(filter){
  return {
    type: SET_STATE_FILTER,
    filter: filter
  }
}
export function clearStateFilter(){
  return {
    type: CLEAR_STATE_FILTER
  }
}


export const SET_VENUE_FILTER = 'SET_VENUE_FILTER';
export const CLEAR_VENUE_FILTER = 'CLEAR_VENUE_FILTER';


export function setVenueFilter(filter){
  return {
    type: SET_VENUE_FILTER,
    filter: filter
  }
}
export function clearVenueFilter(){
  return {
    type: CLEAR_VENUE_FILTER
  }
}

export const SET_YEAR_FILTER = 'SET_YEAR_FILTER';
export const CLEAR_YEAR_FILTER = 'CLEAR_YEAR_FILTER';


export function setYearFilter(filter){
  return {
    type: SET_YEAR_FILTER,
    filter: filter
  }
}
export function clearYearFilter(){
  return {
    type: CLEAR_YEAR_FILTER
  }
}
