import React from 'react';
import PropTypes from 'prop-types';

import './ProfileIcon.css';

const ProfileIcon = ({forumUrl, iconBgColor, iconText, pictureUrl}) => {
  // TODO: Figure out why this is here.  Probably because facebook images? IDK
  let picUrl = pictureUrl && pictureUrl.startsWith('http') ? pictureUrl : `${forumUrl}${pictureUrl}`;

  return (
    <div className="ProfileIcon">
      { pictureUrl
        ? <img className="icon" src={picUrl} alt="Profile Icon" />
        : <svg width="25px" height="25px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#ffffff"><path d="M5 20V19C5 15.134 8.13401 12 12 12V12C15.866 12 19 15.134 19 19V20" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
      }
      <i className="fa fa-fw fa-circle status online" title="Online" />
    </div>
  );
};

ProfileIcon.propTypes = {
  forumUrl: PropTypes.string,
  iconBgColor: PropTypes.string,
  iconText: PropTypes.string,
  pictureUrl: PropTypes.string,
};

ProfileIcon.defaultProps = {
  forumUrl: "https://forum.spaffnerds.com"
};

export default ProfileIcon;
