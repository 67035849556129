import React from 'react';
// import PropTypes from 'prop-types';

import ShowBulkUploadView from 'components/ShowBulkUploadView';
import ErrorView from "components/ErrorView";

const ShowBulkUploadViewContainer = ({isAdmin,
                                       isUploading, isUploadSuccess, isUploadError,
                                       onUpload, onUploading, onUploadSuccess, onUploadError,
                                       showBatch, ...props}) => {
  if (!isAdmin) {
    return (
      <ErrorView status={404} message={"Not Found"}/>
    )
  } else {
    if (isUploading) {
      onUploading()
    }
    if (isUploadSuccess) {
      onUploadSuccess()
    }
    if (isUploadError) {
      onUploadError()
    }

    return (
      <ShowBulkUploadView {...props}
        onUpload={onUpload}
        showBatch={showBatch}
        isUploading={isUploading}
      />
    )
  }
};

ShowBulkUploadViewContainer.propTypes = {
};

ShowBulkUploadViewContainer.defaultProps = {
};

export default ShowBulkUploadViewContainer;
