import React from 'react';
// import PropTypes from 'prop-types';

import Helmet from 'react-helmet';

import { Grid } from 'react-bootstrap';
import { Route } from 'react-router-dom';

import SubNavBar from "components/SubNavBar";
import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';
import DetailContentRow from 'components/DetailView/DetailContentRow';
import LoadingIcon from 'components/LoadingIcon';

import './LoadingShowDetailView.css';

const LoadingShowDetailView = () => {
  return (
    <Grid className="LoadingShowDetailView">
      <Helmet>
        <title>Loading Show</title>
      </Helmet>
      <Route exact path="/" component={SubNavBar} />
      <DetailNavRow placement="show">
        YYYY-MM-DD
      </DetailNavRow>
      <DetailSlugRow>
        <span className="underline">city, state @ venue</span>
      </DetailSlugRow>
      <div className="SetlistContentRow">
        <DetailContentRow heading="Set 1">
          <LoadingIcon size={20}/>
        </DetailContentRow>
        <DetailContentRow heading="Set 2">
          <LoadingIcon size={20}/>
        </DetailContentRow>
        <DetailContentRow heading="Encore">
          <LoadingIcon size={20}/>
        </DetailContentRow>
      </div>
      <div className="AvailableRecordingsRow">
        <hr />
        <DetailContentRow heading="Available Recordings" />
        <LoadingIcon size={40}/>
      </div>
    </Grid>
  )
};

LoadingShowDetailView.propTypes = {
};

LoadingShowDetailView.defaultProps = {
};

export default LoadingShowDetailView;
