import React from 'react';
import PropTypes from 'prop-types';

import './CircleButton.css';

const CircleButton = ({className, children, onClick, disabled}) => {
  return (
    <button
      type="button"
      onClick={(e) => onClick && onClick(e)}
      disabled={disabled}
      className={`CircleButton btn btn-default${className ? ` ${className}` : ''}`}
    >
      {children}
    </button>
  )
};

CircleButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

CircleButton.defaultProps = {
};

export default CircleButton;
