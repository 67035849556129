import React from 'react';
import PropTypes from 'prop-types';

import GetTicketButton from "components/ShowDetailView/NoSetlistContentRow/GetTicketButton";
import GetTicketCodeButton from "components/ShowDetailView/NoSetlistContentRow/GetTicketCodeButton";

const GetTicketsContainer = ({ available, showDate, url, outputText, availability, buyCode, ...props }) => {
  if (buyCode && available) {
    return (
      <GetTicketCodeButton
        available={available}
        availability={availability}
        buyCode={buyCode}
        showDate={showDate}
        url={url}
        outputText={outputText}
        {...props}
      />
    )
  } else {
    return (
      <GetTicketButton
        available={available}
        buyCode={buyCode}
        showDate={showDate}
        url={url}
        outputText={outputText}
        {...props}
      />
    )
  }

};

GetTicketsContainer.propTypes = {
  available: PropTypes.bool.isRequired,
  showDate: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  outputText: PropTypes.string.isRequired,
  availability: PropTypes.string.isRequired,
  buyCode: PropTypes.string
};

GetTicketsContainer.defaultProps = {
};

export default GetTicketsContainer;
