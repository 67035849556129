import React from "react";
import { withRouter } from "react-router-dom";
import { getCurrentPathnameAndQueryParams } from "selectors/pathname";

import Icon from "components/Icons/Icon";
import Popover from "components/Popover";

import SharingMenuContainer from "containers/SharingMenuContainer";

import "./SocialSharing.css";

const SocialSharing = ({ float, location }) => {
  const url = `https://spaffnerds.com${getCurrentPathnameAndQueryParams(
    location
  )}`;

  return (
    <div className="SocialSharing">
      <Popover
        body={<SharingMenuContainer url={url} />}
        heading=""
        trigger="focus"
        placement="right"
        rootClose={false}
      >
        <span className={`shareIcon`}>
          <Icon className="Share" faIconClass="fa-share-alt" text="Share This" />
        </span>
      </Popover>
    </div>
  );
};

SocialSharing.propTypes = {
};

SocialSharing.defaultProps = {
};

export default withRouter(SocialSharing);
