// Derived from: https://codepen.io/lewismcarey/pen/GJZVoG
import React from 'react';
import PropTypes from 'prop-types';

import './TextTicker.css';

const TextTicker = ({items}) => {
  return (
    <div className="TextTicker">
      <div className="ticker">
        { items.map((item, index) => {
          return (
            <div key={`${index}__${item}`} className="ticker__item">{item}</div>
          )
        })}
      </div>
    </div>
  )
};

TextTicker.propTypes = {
  items: PropTypes.array
};

TextTicker.defaultProps = {
  items: []
};

export default TextTicker;
