import ShowListViewContainer from './ShowListViewContainer';
import { connect } from 'react-redux';

import {getDateFilter, getRecordingsFilter, sortAndFilterShows} from 'selectors/shows';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};
const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.shows.list.isFetching,
    showList: sortAndFilterShows(state),
    dateFilter: getDateFilter(state),
    recordingsFilter: getRecordingsFilter(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowListViewContainer);
