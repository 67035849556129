import {
  USERSTATS_REQUEST,
  USERSTATS_SUCCESS,
  USERSTATS_FAILURE,
  USERSTATS_INVALIDATE
} from 'actions/userStats'

function userStat(state = {}, action){
  switch (action.type){
    case USERSTATS_INVALIDATE:
      return Object.assign({}, state[action.id], {
        didInvalidate: true,
        isError: false
      });
    case USERSTATS_REQUEST:
      return Object.assign({}, state[action.id], {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case USERSTATS_SUCCESS:
      return Object.assign({}, state[action.id], {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        data: action.response,
        lastUpdate: action.receivedAt
      });
    case USERSTATS_FAILURE:
      return Object.assign({}, state[action.id], {
        isFetching: false,
        isError: true,
        error: action.response,
        didInvalidate: false
      });
    default:
      return state;
  }
}

function userStats(state = {}, action){
  switch (action.type){
    case USERSTATS_INVALIDATE:
    case USERSTATS_REQUEST:
    case USERSTATS_SUCCESS:
    case USERSTATS_FAILURE:
      return Object.assign({}, state, {
        [action.id]: userStat(state[action.id], action)
      });
    default:
      return state;
  }
 }

export default userStats;