import React from 'react';
import PropTypes from 'prop-types';

import DropDownFilter from '../DropDownFilter';

const StateFilter = ({onSelect, filter, states}) => {
  let keys = [
    "Any State",
    ...states
  ];
  let values = [
    null,
    ...states
  ];

  //TODO: update
  let index = !filter ? 0 : values.findIndex(value => value === filter);
  index = index < 0 ? 0 : index;

  return (
    <DropDownFilter
      before="In"
      text={keys[index]}
      onSelect={onSelect}
      keys={keys}
      values={values}
    />
  )
};

StateFilter.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string,
  states: PropTypes.array.isRequired
};

StateFilter.defaultProps = {
  filter: null
};

export default StateFilter;
