import { TEST_TOUR_GAMES_ID } from '../../constants'
import useTourGames from 'hooks/useTourGames'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { Grid, Table, Button } from 'react-bootstrap'
import { useParams } from 'react-router'
import Loading from 'components/Loading'
import DetailSlugRow from 'components/DetailView/DetailSlugRow'
import './TourGamesUserTicketView.css'
import EditButton from 'components/TourGamesHomeView/DisplayPicksHeader/EditButton'
import sort from 'lib/sort'
import { useHistory } from 'react-router-dom'

const TourGamesUserTicketView = () => {
  const { userId: userIdParam } = useParams()
  const { userId, isUserLoggedIn } = useUser()
  const history = useHistory()

  const isCurrentUser = Boolean(userId === parseInt(userIdParam))
  const {
    ticket,
    getTicket,
    getSongNameById,
    isFetching,
    userTickets
  } = useTourGames()
  const [visibileTicket, setVisibleTicket] = useState(isCurrentUser && ticket)

  useEffect(() => {
    if (isCurrentUser && isUserLoggedIn && ticket) {
      setVisibleTicket(ticket)
      return
    }

    getTicket(userIdParam, TEST_TOUR_GAMES_ID, true)
    // eslint-disable-next-line
  }, [userId, userIdParam, isUserLoggedIn, ticket])

  useEffect(() => {
    if (isCurrentUser) {
      return
    }
    const userTicket = userTickets.find(ticket => ticket.userId === userIdParam)
    setVisibleTicket(userTicket)
    // eslint-disable-next-line
  }, [userTickets])

  if (isFetching) {
    return (
      <>
        <Loading />
      </>
    )
  }

  return (
    <Grid className='TourGamesUserTicketView'>
      <DetailSlugRow>{`${visibileTicket?.userName}'s Ticket`}</DetailSlugRow>
      <DetailSlugRow>{visibileTicket?.tourId}</DetailSlugRow>
      <DetailSlugRow>{`Current Score: ${visibileTicket?.totalScore}`}</DetailSlugRow>
      <Table>
        <thead>
          <tr>
            <td>Show</td>
            <td>Set 1 Pick</td>
            <td>Set 2 Pick</td>
            <td>Encore Pick</td>
            <td>Score</td>
          </tr>
        </thead>
        <tbody>
          {visibileTicket?.showPicks
            ?.sort(sort.tourGames.showId_desc)
            ?.map(pick => {
              return (
                <tr key={pick.showId}>
                  <td>{pick.showId}</td>
                  <td>{getSongNameById(pick.picks.set1)}</td>
                  <td>{getSongNameById(pick.picks.set2)}</td>
                  <td>{getSongNameById(pick.picks.encore)}</td>
                  <td>
                    {(isCurrentUser && pick?.score === null) ||
                    pick.score === undefined ? ( // needs to be explicit null and undefined because of the way 0 evaluates
                      <EditButton
                        show={{ showId: pick.showId }}
                        picksForShow={true}
                        useShortText={true}
                      />
                    ) : (
                      pick?.score?.toString()
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      <div style={{ textAlign: 'center' }}>
        <Button className='' onClick={() => history.goBack()}>
          Go Back
        </Button>
      </div>
    </Grid>
  )
}

export default TourGamesUserTicketView
