import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import Popover from "components/Popover";
import GetTicketButton from "components/ShowDetailView/NoSetlistContentRow/GetTicketButton";

import './GetTicketCodeButton.css';
import CopyButton from "components/CopyButton/CopyButton";

class GetTicketCodeButton extends Component {
  constructor(props){
    super(props);
    this.state = {
      clicked: false
    }
  }

  render(){
    const { buyCode, availability, ...props } = this.props;
    const { clicked } = this.state;

    return (
      <div className="GetTicketCodeButton">
          <Popover
            className="GetTicketCodeButton"
            trigger="click"
            heading={<h4>{`Use ${availability} Code`}</h4>}
            body={
              <div>
                <span><input id="buyCode" value={buyCode} size={buyCode.length + 2} /></span>
                <CopyButton textBoxId="buyCode" />
              </div>
            }
            placement="top"
            rootClose={false}
          >
            {clicked
              ? <GetTicketButton {...props} />
              : <Button className="active" onClick={() => this.setState({clicked: true})}>
                  <i className="fa fa-ticket"/>
                  <span>{`Get Tickets & ${availability} Code`}</span>
                </Button>
            }
          </Popover>
      </div>
    );
  }
}

GetTicketCodeButton.propTypes = {
  buyCode: PropTypes.string.isRequired,
  availability: PropTypes.string.isRequired

};

GetTicketCodeButton.defaultProps = {
};

export default GetTicketCodeButton;
