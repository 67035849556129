import { applySort } from './sortAndFilter';
export const getShows = state => {
  //TODO: This is a temp fix until we figure out a better solution for when to sort
  // In truth this actually might be the right place to do this
  //  it would mean that we always provide a sorted/filtered list of
  //  shows, no matter what you request
  // The alt would be to provide a second selector that does this
  //  which is a bit more explicit
  //  the trouble is what do you call in the selectors below?
  // TODO: Figure this out later :)
  return applySort(state.shows.list.items);
};

export const getShowsLastUpdateTime = state => {
  return state.shows.list && state.shows.list.lastUpdate
};

export const getLatestShow = state => {
  //TODO: This is a place where we need the raw show list, not filtered, ideally sorted
  // otherwise we have to do the sort here
  let shows = getShows(state);
  let latestShow = shows.filter(show => show.sets && show.sets.length)[0];
  return latestShow;
};

export const getLatestShowDate = state => {
  const latestShow = getLatestShow(state);
  return latestShow && latestShow.date && latestShow.date.substr(0,10);
};


export const getNextShow = state => {
  const shows = getShows(state)
  const latestShow = getLatestShow(state)
  const latestShowIndex = shows.findIndex(show => show._id === latestShow._id)
  const nextShow = shows[latestShowIndex - 1]
  return nextShow
  
}

export const getVisibleShowDate = state => {
  return state.shows.selected.date;
};

export const getVisibleShowMultiIndex = state => {
  return parseInt(state.shows.selected.index, 10) || 0;
};

export const getVisibleShowId = state => {
  const visibleShow = getVisibleShow(state);
  return visibleShow && visibleShow._id
};

export const getVisibleShowIndex = state => {
  const shows = getShows(state);
  const visibleShowDate = getVisibleShowDate(state);
  const multiIndex = getVisibleShowMultiIndex(state);
  let visibleIndex = -1;

  if (multiIndex) {
    visibleIndex = shows.findIndex((show) => {
      return (
        show.date.substr(0,10) === visibleShowDate
        && show.multiShowIndex === multiIndex
      )
    });
  } else {
    visibleIndex = shows.findIndex((show) => {
      return (
        show.date.substr(0,10) === visibleShowDate
        && !show.multiShowIndex
      )
    });
  }

  return visibleIndex
};

export const getVisibleShow = state => {
  const shows = getShows(state);
  const visibleIndex = getVisibleShowIndex(state);

  return shows[visibleIndex];
};

export const getVisibleShowVenue = state => {
  const visibleShow = getVisibleShow(state);
  return visibleShow ? visibleShow.venue : null;
};

export const getPrevVisibleShow = state => {
  const shows = getShows(state);
  const visibleIndex = getVisibleShowIndex(state);
  if (visibleIndex === shows.length - 1){
    return null;
  }
  return shows[visibleIndex + 1];
};

export const getNextVisibleShow = state => {
  const shows = getShows(state);
  const visibleIndex = getVisibleShowIndex(state);
  if (visibleIndex === 0){
    return null;
  }
  return shows[visibleIndex - 1];
};

export const isVisibleShowInPast = state => {
  const today = (new Date()).toISOString().substr(0,10);
  return getVisibleShowDate(state) < today;
};

export const isVisibleShowLive = state => {
  const visibleShow = getVisibleShow(state);
  if ( ! visibleShow || ! visibleShow.startTime || ! visibleShow.endTime ) {
    return null;
  }

  const start = new Date(visibleShow.startTime);
  const end = new Date(visibleShow.endTime);
  const now = new Date();
  return ( now >= start && now <= end);

};

export const isVisibleShowRecent = state => {
  const visibleShow = getVisibleShow(state);
  if ( ! visibleShow || ! visibleShow.startTime || ! visibleShow.endTime ) {
    return null;
  }

  const start = new Date(visibleShow.startTime);
  const end = new Date(visibleShow.endTime);
  end.setHours(end.getHours() + 24);
  const now = new Date();
  return ( now >= start && now <= end);

};

export const isVisibleShowFetching = state => {
  // const isListFetching = state.shows && state.shows.list && state.shows.list.isFetching;
  const isShowFetching = state.shows && state.shows.selected && state.shows.selected.isFetching;
  return isShowFetching //|| isListFetching
};

export const getSelectedShowId = state => {
  return state.shows && state.shows.selected && state.shows.selected.date
};

export const getVisibleShowError = state => {
  const isShowFetching = isVisibleShowFetching(state);
  const isShowFailed = state.shows && state.shows.selected && state.shows.selected.isError;
  if ( isShowFetching || ! isShowFailed ) {
    return isShowFailed
  }
  return state.shows.selected.error
};
