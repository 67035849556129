import React from 'react';
import PropTypes from 'prop-types';

import NavigationLinks from 'components/Nav/NavigationLinks';

const LoggedOutMobileNav = ({slideout}) => {
  return (
    <div className="MobileNav">
      <section>
        <div className="ProfileIcon"/>
      </section>
      <section>
        <h1>Navigation</h1>
        <NavigationLinks isLoggedIn={false} slideout={slideout}/>
      </section>
    </div>
  );
};

LoggedOutMobileNav.propTypes = {
  slideout: PropTypes.node,
};

LoggedOutMobileNav.defaultProps = {
};

export default LoggedOutMobileNav;
