import React from 'react';
import { Button } from 'react-bootstrap';
import Link from 'components/Link';
import './EditButton.css';

const EditButton = ({ show, picksForShow, useShortText = false }) => {
  return (
    <div className='EditButton'>
      <Link
        url={`/tourgames/${show.showId}/play`}
        id='edit-tour-games'
        title='Edit'
      >
        <Button bsSize='large'>
          <span className='text'>
            {!picksForShow
              ? 'Make Picks'
              : useShortText
              ? 'Edit'
              : 'Edit Picks'}
          </span>
        </Button>
      </Link>
    </div>
  );
};

export default EditButton;
