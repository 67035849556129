export * from './async_attendancelist';

/*
 * action types
 */
export const INVALIDATE_ATTENDANCE = 'INVALIDATE_ATTENDANCE';

/*
 * action creators
 */
export function invalidateAttendance(showId){
  return {
    type: INVALIDATE_ATTENDANCE,
    id: showId
  }
}
