import RecordingButtonContainer from './RecordingButtonContainer';
import { connect } from 'react-redux';

import {setActiveRecording, setPlayStatus} from 'actions/mediaPlayer';
import { getVisibleShowVenue } from "selectors/shows";
import { getActiveRecording } from "selectors/mediaPlayer";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setRecording: (recording, venue) => {
      dispatch(setActiveRecording(recording, venue));
      dispatch(setPlayStatus(true));
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    venue: getVisibleShowVenue(state),
    activeRecording: getActiveRecording(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RecordingButtonContainer);
