import React from 'react';
import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';
import { useSelector } from 'react-redux';
import { getSongs } from 'selectors/songs';
import EditButton from './EditButton';
import PickDisplayRow from './PickDisplayRow';

const DisplayPicksHeader = ({ gameShow, picksForShow, ticket }) => {
  const songs = useSelector((state) => getSongs(state));

  return (
    <>
      <DetailNavRow socialSharing={false}>
        <span>Score: </span>
        <span className='red-text'>{ticket?.totalScore ?? 0}</span>
        <div className='red-text'>Your Picks For</div>
      </DetailNavRow>
      {gameShow && (
        <DetailSlugRow>
          <div>{gameShow.date.substr(0, 10)}</div>
          <span>{`${gameShow.venue.city}, ${gameShow.venue.state} @ ${gameShow.venue.name}`}</span>
        </DetailSlugRow>
      )}
      {!picksForShow && (
        <DetailSlugRow>
          <div className='red-text'>No Picks Made Yet!</div>
        </DetailSlugRow>
      )}
      {picksForShow &&
        Object.keys(picksForShow).map((set, index) => (
          <PickDisplayRow
            set={set}
            index={index}
            songs={songs}
            picksForShow={picksForShow}
          />
        ))}
      <EditButton show={gameShow} picksForShow={picksForShow} />
    </>
  );
};

export default DisplayPicksHeader;
