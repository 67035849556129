import React from 'react';
import PropTypes from 'prop-types';

import AddRecordingRow from 'components/ShowDetailView/AddRecordingRow';
import {displayGrowl, growlLevels} from "lib/growl";

const AddRecordingRowContainer = ({ onAddRecording, onSuccess, onCancel, visibleShowId,
                                    isFetching, isError, isSuccess,
                                    ...props}) => {
  const curryAddRecordings = (payload) => {
    return onAddRecording(visibleShowId, payload)
  };

  if (isError) {
    displayGrowl(`Error submitting your recording, please try again.`, growlLevels.WARN)
  }

  if (isSuccess) {
    onSuccess();
    displayGrowl(`Your recording has been submitted for review.`, growlLevels.SUCCESS)
  }

  return <AddRecordingRow
    disabled={isFetching}
    onAddRecording={curryAddRecordings}
    onCancel={onCancel}
    {...props}
  />
};

AddRecordingRowContainer.propTypes = {
  onCancel: PropTypes.func,
  onAddRecording: PropTypes.func
};

AddRecordingRowContainer.defaultProps = {
};

export default AddRecordingRowContainer;
