import { useDispatch, useSelector } from 'react-redux';
import {
  getTicket as getTicketSelector,
  getShow as getNextShow,
  getTickets,
  tourShowIds as tourShowIdsSelector,
  tourGamesId as tourGamesIdSeletor
} from 'selectors/tourGames';
import { getLeaderboard as getTgLeaderboard } from 'selectors/tourGamesLeaderboard';
import {
  createTicket,
  fetchTicket,
  resetTourGamesError,
  createPicks,
  invalidateTourGames,
  fetchTour
} from 'actions/tourGames';
import { getShows } from 'selectors/shows';
import { displayGrowl, growlLevels } from 'lib/growl';
import { fetchLeaderboard } from 'actions/tourGamesLeaderboard';
import { getSongs } from 'selectors/songs';
import { TEST_TOUR_GAMES_ID } from '../../constants';
import { sortAndFilterShows } from 'selectors/shows';

export const useTourGames = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => getNextShow(state));
  const showId = show?.showId;
  const ticket = useSelector(getTicketSelector);
  const isCreateTicketSuccess = useSelector((state) =>
    Boolean(getTicketSelector(state))
  );
  const isError = useSelector((state) => state?.tourGames?.isError);
  const isCreateError = useSelector((state) => state?.tourGames?.isCreateError);
  const isFetching = useSelector((state) => state?.tourGames?.isFetching);
  const picksForCurrentShow =
    showId &&
    ticket?.showPicks?.find((picks) => picks.showId === showId)?.picks;
  const currentGameShow = {
    showId
  };
  const shows = useSelector((state) => getShows(state));
  const leaderboard = useSelector((state) =>
    getTgLeaderboard(state)?.sort((p1, p2) => p2.score - p1.score)
  );
  const userTickets = useSelector(getTickets);
  const songs = useSelector(getSongs);

  /**
   * Gets the current ticket from tourGames store
   * @param {String} userId
   * @param {String} tourId
   * @param {Boolean} otherUser
   */
  const getTicket = (userId, tourId, otherUser = false) => {
    dispatch(fetchTicket(userId, tourId, 'SpaffnerdsTourGames', 1, otherUser));
  };

  /**
   * Calls the action to update picks
   * @param {String} userId
   * @param {String} tourId
   * @param {String} showId
   * @param {Object} picks
   */
  const addPicks = (userId, tourId, showId, ticketId, picks) => {
    dispatch(createPicks(userId, tourId, showId, ticketId, picks));
  };

  /**
   * Creates a ticket using the userId and tourId
   * @param {String} userId
   * @param {String} userName
   * @param {String} tourId
   */
  const createTourTicket = (userId, userName, tourId) => {
    dispatch(createTicket(userId, userName, tourId));
  };

  /**
   * Sets isError in tourGames store back to false
   */
  const handleError = (message) => {
    dispatch(resetTourGamesError());
    displayGrowl(message, growlLevels.WARN);
  };

  const invalidate = () => {
    dispatch(invalidateTourGames());
  };

  /** Gets the game show fromt he list of shows
   * @param {String} showId
   * @returns gameShow
   */
  const getGameShow = (showId) => {
    const show = shows?.find((show) => show.date.substr(0, 10) === showId);

    return show ? { ...show, showId } : currentGameShow;
  };

  const getPicksForShow = (showId, ticket) =>
    ticket?.showPicks?.find((picks) => picks.showId === showId)?.picks;

  const getSongNameById = (songId) => {
    if (songId === 'new-cover') {
      return 'New Cover';
    }

    if (songId === 'new-original') {
      return 'New Original';
    }

    const song = songs?.find((song) => song._id === songId);
    return song?.name;
  };

  const getLeaderboard = (
    tourId = TEST_TOUR_GAMES_ID,
    gameType = 'SpaffnerdsTourGames',
    onDemand = false
  ) => {
    dispatch(fetchLeaderboard(tourId, gameType, onDemand));
  };

  const getTourShows = (tourId) => dispatch(fetchTour(tourId));

  const allShows = useSelector(sortAndFilterShows);
  const tourShowIds = useSelector(tourShowIdsSelector);
  const showList = allShows?.filter((show) =>
    tourShowIds?.includes(show.date.substr(0, 10))
  );

  const tourId = useSelector(tourGamesIdSeletor);

  return {
    ticket,
    currentGameShow,
    isCreateTicketSuccess,
    isError,
    isFetching,
    picksForCurrentShow,
    leaderboard,
    isCreateError,
    createTourTicket,
    getTicket,
    addPicks,
    handleError,
    invalidate,
    getGameShow,
    getPicksForShow,
    getLeaderboard,
    getSongNameById,
    userTickets,
    getTourShows,
    showList,
    tourId
  };
};
