import React from 'react';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { displayGrowl, growlLevels } from "lib/growl";
import CircleButton from "components/CircleButton";

import './ClipboardShareButton.css';

const ClipboardShareButton = ({url, beforeOnClick}) => {
  return (
    <div className="ClipboardShareButton">
      <CopyToClipboard text={url} onCopy={() => showCopySuccess(beforeOnClick, url)}>
        <CircleButton onClick={(e) => beforeOnClick && beforeOnClick()}>
          <i className="fa fa-copy" />
        </CircleButton>
      </CopyToClipboard>
    </div>
  )
};

const showCopySuccess = (beforeOnClick, value) => {
  beforeOnClick && beforeOnClick();
  displayGrowl(`${value} copied to clipboard.`, growlLevels.SUCCESS);
};

ClipboardShareButton.propTypes = {
  url: PropTypes.string.isRequired
};

ClipboardShareButton.defaultProps = {
  url: "https://spaffnerds.com"
};

export default ClipboardShareButton;
