import React from 'react';
import { Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';

import './DetailSlugRow.css';

const DetailSlugRow = ({className, children}) => (
  <Row className={className ? `${className} DetailSlugRow` : "DetailSlugRow"} >
    <Col md={12} lg={12} sm={12} xs={12}>
      <h2 className="text-center">
        {children}
      </h2>
    </Col>
  </Row>
);

DetailSlugRow.propTypes = {
  children: PropTypes.node
};

DetailSlugRow.defaultProps = {};

export default DetailSlugRow;
