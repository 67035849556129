
export const getUserStats = (state, id) => {
  return state.userStats && state.userStats[id];
};

export const getUsername = (state, id) => {
  // TODO: need to update the API to include this info
  return id;
};

export const getUserSlug = (state, id) => {
  // TODO: need to update the API to include this info
  return id;
};

export const getShowData = (state, id) => {
  const userStats = getUserStats(state, id);
  return userStats && userStats.data && userStats.data.showData;
};

export const getSongStats = (state, id) => {
  const userStats = getUserStats(state, id);
  return userStats && userStats.data && userStats.data.songStats;
};

export const getShowsByYear = (state, id) => {
  const showData = getShowData(state, id) || [];
  let yearMap = {};
  showData.forEach(show => {
      let year = show.date.substr(0,4);
      yearMap[year] = yearMap[year] || [];
      yearMap[year].push(show);
    });
  return yearMap;
};

export const getShowCount = (state, id) => {
  const showData = getShowData(state, id);
  return showData && showData.length;

};

export const getUserStatsError = (state, id) => {
  const userStats = getUserStats(state, id);
  if ( ! userStats || userStats.isFetching || ! userStats.isError ) {
    return undefined;
  }

  return userStats.error
};
