export const SET_ADD_RECORDING = 'SET_ADD_RECORDING';
export const CLEAR_ADD_RECORDING = 'CLEAR_ADD_RECORDING';

export function setAddRecording(streamOnly){
  return {
    type: SET_ADD_RECORDING,
    streamOnly: streamOnly
  }
}

export function clearAddRecording(){
  return {
    type: CLEAR_ADD_RECORDING
  }
}
