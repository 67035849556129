import GetTicketsContainer from './GetTicketsContainer';
import { getTicketInfo, getVisibleShowDate } from 'selectors/shows'
import { getUsername } from 'selectors/user';

import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  const { url, available, outputText, availability, buyCode } = getTicketInfo(state);
  return {
    showDate: getVisibleShowDate(state),
    url: url,
    outputText: outputText,
    buyCode: buyCode,
    username: getUsername(state),
    available: available,
    availability: availability
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GetTicketsContainer);
