import React from 'react';
import PropTypes from 'prop-types';

import ArchiveBody from 'components/MediaPlayer/MediaPlayerBody/ArchiveBody';

const ArchiveBodyContainer = ({tracks, playTrack, playingTrack, isPlaying}) => (
  <ArchiveBody
    tracks={tracks}
    playTrack={playTrack}
    playingTrack={playingTrack}
    isPlaying={isPlaying}
  />
);

ArchiveBodyContainer.propTypes = {
  tracks: PropTypes.array,
  playTrack: PropTypes.func,
  playingTrack: PropTypes.object,
  isPlaying: PropTypes.bool
};

ArchiveBodyContainer.defaultProps = {};

export default ArchiveBodyContainer;
