/**
 * Gets the ticket from tour games
 * @param {Object} state
 * @returns ticket
 */
export const getTicket = (state) => state?.tourGames?.ticket;

/**
 * Gets the show for tour games
 * @param {Object} state
 * @returns
 */
export const getShow = (state) => state?.tourGames?.show;

export const getTickets = (state) => state?.tourGames?.userTickets;

export const tourGamesId = (state) => state?.tourGames?.tour?.tourId;

export const tourShowIds = (state) => state?.tourGames?.tour?.showIds;
