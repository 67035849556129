import React, { Component } from 'react'
import Slideout from 'slideout';
import './Slider.css';

import PropTypes from 'prop-types';

class Slider extends Component {
  constructor(props){
    super(props);
    this.state = {
      header: this.props.header,
      menu: this.props.menu,
      children: this.props.children
    }
  }

  render() {
    let className = this.props.className;
    let panelId = this.props.panelId;
    let menuId = this.props.menuId;
    let header = this.state.header;
    let menu = this.state.menu;
    let children = this.state.children;
    return (
      <div className="Slider">
        <div id={panelId} className={`${className}`}>
          {header}
          {children}
        </div>
        <nav id={menuId}>
          {menu}
        </nav>
      </div>
    );
  }

  // Adds the slideout to header, menu, and all children
  // then returns an object which contains them and can
  // be easily assigned in a setstate call
  childrenWithSlideout(){
    let children = this.props.children;
    let childrenWithSlideout = React.Children.map(children, child =>
      React.cloneElement(child, {slideout: this.slideout})
    );
    let headerWithSlideout = React.cloneElement(this.props.header, {slideout: this.slideout});
    let menuWithSlideout = React.cloneElement(this.props.menu, {slideout: this.slideout});
    return {
      header: headerWithSlideout,
      menu: menuWithSlideout,
      children: childrenWithSlideout
    };
  }

  componentDidMount(){
    let padding = this.props.padding;
    let tolerance = this.props.tolerance;
    let side = this.props.side;

    let menuId = this.props.menuId;
    let panelId = this.props.panelId;

    this.slideout = new Slideout({
      'panel': document.getElementById(panelId),
      'menu': document.getElementById(menuId),
      'padding': padding,
      'tolerance': tolerance,
      'side': side
    });

    this.setState(this.childrenWithSlideout());
 }
}

Slider.propTypes = {
  menuId: PropTypes.string.isRequired,
  panelId: PropTypes.string.isRequired,
  header: PropTypes.element.isRequired,
  menu: PropTypes.element.isRequired,
  children: PropTypes.node,
  padding: PropTypes.number,
  tolerance: PropTypes.number,
  side: PropTypes.oneOf(['right','left'])
};

Slider.defaultProps = {
  padding: 256,
  tolerance: 70,
  side: 'right'
};

export default Slider
