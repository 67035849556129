// middleware/api.js
import { callApi } from './api'

export const API_PUT = Symbol('API PUT');

const putApi = store => next => action => {

  const callApiPut = action[API_PUT];

  // So the middleware doesn't get applied to every single action
  if (typeof callApiPut === 'undefined') {
    return next(action)
  }

  // some of our API calls are scoped to an ID (for example song stats)
  // to support this callApiPut is checked for an 'id' which is always
  // attached to the action that is generated on success
  // that id will simply be 'undefined' if the callApiPut action doesn't
  // have a unique 'id'
  let { payload, shouldDispatch, endpoint, types, authenticated, betaEndpoint, alphaEndpoint } = callApiPut;
  const [ requestType, successType, errorType ] = types;

  // allows us to make decisions based on the state of the application
  //  read: http://blog.isquaredsoftware.com/2017/01/idiomatic-redux-thoughts-on-thunks-sagas-abstraction-and-reusability/
  // I'm totally open to a better solution here but I can't spend any more time on this
  //  it's time for a "good enough" solution
  if ( ! shouldDispatch || ! shouldDispatch(store)) {
    return 0;
  }

  store.dispatch({type: requestType, payload: payload});

  const fetchConfig = {
    body: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json'
    },
    method: 'PUT',
    mode: 'cors'
  };

  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
  return callApi(endpoint, authenticated, fetchConfig, betaEndpoint, alphaEndpoint).then(
    // we package in the id to the action, undefined if it wasn't specified
    response =>
      next({
        response: response,
        payload: payload,
        type: successType,
        authenticated,
        receivedAt: Date.now()
      }),
    error => next({
      response: error,
      payload: payload,
      type: errorType,
      authenticated,
      receivedAt: Date.now()
    })
  )
}

export default putApi
