import React from 'react';

import UserProfileContainer from "containers/UserProfileContainer";
import ProfileIcon from 'components/Nav/ProfileIcon';

const ProfileIconContainer = ({...props}) => {
  return (
    <UserProfileContainer>
      <ProfileIcon {...props}/>
    </UserProfileContainer>
  );
};

ProfileIconContainer.propTypes = {
};

ProfileIconContainer.defaultProps = {
};

export default ProfileIconContainer;
