import React from 'react';
import PropTypes from 'prop-types';

import { DropdownButton, MenuItem } from 'react-bootstrap';

import PayPalDonateContainer from 'containers/PayPalDonateContainer';
import Tooltip from 'components/Tooltip';
import Link from 'components/Link';

import ProfileIconContainer from 'containers/UserProfileContainer/ProfileIconContainer';

import './LoggedInProfileLinks.css';
import { signOut } from 'lib/auth/register';

const LoggedInProfileLinks = ({ isAdmin, forumUrl, username, userslug, dispatchLogout  }) => {
  return (
    <div className='LoggedInProfileLinks hidden-xs'>
      <ul className='nav navbar-nav navbar-right'>
        <li>
          <Tooltip text='Donate' placement='bottom' className='hidden-xs'>
            <PayPalDonateContainer />
          </Tooltip>
        </li>
        <li>
          <Link url={`${forumUrl}/notifications`}>
            <i className='fa fa-fw fa-bell-o' />
          </Link>
        </li>
        <li>
          <Link url={`${forumUrl}/user/${userslug}/chats`}>
            <i className='fa fa-fw fa-comment-o' />
          </Link>
        </li>
        <li>
          <DropdownButton
            id='profile-icon-drop-down'
            noCaret
            bsStyle='link'
            title={<ProfileIconContainer />}
          >
            <MenuItem href={`${forumUrl}/user/${userslug}`} eventKey='1'>
              <i className='fa fa-fw fa-circle status online' />
              <span> {username}</span>
            </MenuItem>
            {isAdmin && <MenuItem divider />}
            {isAdmin && (
              <MenuItem eventKey='5'>
                <Link url='/admin/shows/upload'>
                  <i className='fa fa-fw fa-upload' />{' '}
                  <span> Upload Shows</span>
                </Link>
              </MenuItem>
            )}
            {isAdmin && (
              <MenuItem eventKey='6'>
                <Link url='/tourgames/admin'>
                  <i className='fa fa-fw fa-address-card' />{' '}
                  <span> Tour Games Admin</span>
                </Link>
              </MenuItem>
            )}
            <MenuItem divider />
            <MenuItem href={`${forumUrl}/user/${userslug}/edit`} eventKey='2'>
              <i className='fa fa-fw fa-edit' /> <span> Edit Profile</span>
            </MenuItem>
            <MenuItem
              href={`${forumUrl}/user/${userslug}/settings`}
              eventKey='3'
            >
              <i className='fa fa-fw fa-gear' /> <span> Settings</span>
            </MenuItem>
            <MenuItem divider />
            <MenuItem href={`${forumUrl}/user/logout`} eventKey='4' onClick={() => signOut(dispatchLogout())}>
              <i className='fa fa-fw fa-sign-out' /> <span> Logout</span>
            </MenuItem>
          </DropdownButton>
        </li>
      </ul>
    </div>
  );
};

LoggedInProfileLinks.propTypes = {
  forumUrl: PropTypes.string,
  username: PropTypes.string,
  userslug: PropTypes.string
};

LoggedInProfileLinks.defaultProps = {
  forumUrl: 'https://forum.spaffnerds.com'
};

export default LoggedInProfileLinks;
