import MediaPlayerTitleContainer from './MediaPlayerTitleContainer';
import { connect } from 'react-redux';
import {getActiveRecordingSource} from "selectors/mediaPlayer/index";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    source: getActiveRecordingSource(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MediaPlayerTitleContainer);
