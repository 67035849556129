import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import SongFactBadges from './SongFactBadges';

import './SongListTable.css';

const SongListTable = ({songList, noLinks}) => {
  const classNameCol1 = 'col-xs-8 col-sm-4 col-md-2 col-lg-3';
  const classNameCol2 = 'col-xs-4 col-sm-2 col-md-2 col-lg-2';
  const classNameCol3 = 'hidden-xs col-sm-4 col-md-5 col-lg-2';
  const classNameCol4 = 'hidden-xs hidden-sm col-md-1 col-lg-3';
  const classNameCol5 = 'hidden-xs col-sm-1 col-md-1 col-lg-1';
  const classNameCol6 = 'hidden-xs col-sm-1 col-md-1 col-lg-1';
  return (
    <Table className="SongListTable">
      <thead>
      <tr>
        <td className={classNameCol1}>Song Name</td>
        <td className={classNameCol2}/>
        <td className={classNameCol3}>First Played</td>
        <td className={classNameCol4}/>
        <td className={classNameCol5}/>
        <td className={classNameCol6}/>
      </tr>
      </thead>
      <tbody>
      {songList.map(song => {
        const {_id, name, firstPlayed, isJam, lyrics, writtenBy} = song;
        const {date, venue} = firstPlayed ? firstPlayed : {date: undefined, venue: {}};
        const {band} = writtenBy;
        return (
          <tr key={_id}>
            <td className={classNameCol1}>
              <Link url={noLinks ? "#" : `/songs/${_id}`}>{name}</Link>
            </td>
            <td className={classNameCol2}>
              <SongFactBadges hasLyrics={lyrics ? true : false}
                              isJam={isJam}
                              isCover={band === 'Spafford' ? false : true}
              />
            </td>
            {
              date
                ? <td className={classNameCol3}><Link url={noLinks ? "#" : `/shows/${date.substr(0,10)}`}>{date.substr(0,10)}</Link></td>
                : <td className={classNameCol3}><span>N/A</span></td>
            }
            <td className={classNameCol4}>{venue.name}</td>
            <td className={classNameCol5}>{venue.city}</td>
            <td className={classNameCol6}>{venue.state}</td>
          </tr>
          )
      })}
      </tbody>
    </Table>
  );
};

SongListTable.propTypes = {
  songList: PropTypes.array,
  noLinks: PropTypes.bool
};

SongListTable.defaultProps = {
  songList: []
};

export default SongListTable;
