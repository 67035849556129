import React from 'react';
import PropTypes from 'prop-types';

import './StatLabel.css';

const StatLabel = ({leadText, count, label, plural, showColon, trailingText, className}) => (
  <span className={className ? `StatLabel ${className}` : 'StatLabel' }>
    <span>{leadText}{showColon && <span className="colon">:</span>}</span>
    <span className="count">{count >= 0 ? count : 'N/A'}</span>
    <span>{label}{count !== 1 ? plural : ""}</span>
    { trailingText &&
      <span>
        <span> in</span>
        <span className="count">{trailingText}</span>
      </span>
    }
  </span>
);

StatLabel.propTypes = {
  leadText: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  label: PropTypes.string,
  plural: PropTypes.string,
  showColon: PropTypes.bool,
  className: PropTypes.string,
  trailingText: PropTypes.string
};

StatLabel.defaultProps = {
  label: "show",
  plural: "s",
  showColon: true
};

export default StatLabel;
