import { API_POST } from "middleware/api/post";

export const USERRECORDINGS_REQUEST = 'USERRECORDINGS_REQUEST';
export const USERRECORDINGS_SUCCESS = 'USERRECORDINGS_SUCCESS';
export const USERRECORDINGS_FAILURE = 'USERRECORDINGS_FAILURE';
export const USERRECORDINGS_INVALIDATE = 'USERRECORDINGS_INVALIDATE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function fetchUserRecordings(showId, payload) {
  return {
    [API_POST]: {
      payload: payload,
      authenticated: true,
      endpoint: `/recordings/show/${showId}`,
      shouldDispatch: function(store) {
        return true;
      },
      types: [USERRECORDINGS_REQUEST, USERRECORDINGS_SUCCESS, USERRECORDINGS_FAILURE]
    }
  }
}

export function invalidateUserRecordings(){
  return {
    type: USERRECORDINGS_INVALIDATE
  }
}
