import { combineReducers } from 'redux';
import {
  SET_SHOW_UPLOAD_BATCH,
  CLEAR_SHOW_UPLOAD_BATCH,
  SHOWBATCH_REQUEST,
  SHOWBATCH_SUCCESS,
  SHOWBATCH_FAILURE,
  SHOWBATCH_INVALIDATE
} from 'actions/admin';

function showUpload(state = {}, action) {
  switch (action.type) {
    case SET_SHOW_UPLOAD_BATCH:
      return Object.assign({}, state, {
        filename: action.filename,
        showBatch: action.showBatch
      });
    case CLEAR_SHOW_UPLOAD_BATCH:
      return {};
    default:
      return state
  }
}


function initialshowBatchState(){
  return {
    isFetching: false,
    isError: false,
    didInvalidate: false,
    data: {}
  }
}

function postShowBatch(state = initialshowBatchState(), action) {
  switch (action.type) {
    case SHOWBATCH_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case SHOWBATCH_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        data: action.response,
        lastUpdate: action.receivedAt
      });
    case SHOWBATCH_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });
    case SHOWBATCH_INVALIDATE:
      return {
        isFetching: false,
        isError: false,
        didInvalidate: true
      };
    default:
      return state;
  }
}

const adminReducers = combineReducers({
  showUpload,
  postShowBatch
});


export default adminReducers;