import PullDownRefreshContainer from './PullDownRefreshContainer';
import { connect } from 'react-redux';

import {fetchLatestShow, fetchShow} from "../../actions/shows";
import {getLatestShowDate, getSelectedShowId, isVisibleShowFetching} from "../../selectors/shows";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onShowRefresh: (id) => dispatch(fetchShow(id)),
    onHomepageRefresh: (id) => dispatch(fetchLatestShow()),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedShowId: getSelectedShowId(state),
    latestShowId: getLatestShowDate(state),
    isFetching: isVisibleShowFetching(state),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PullDownRefreshContainer);
