import React from 'react';
import { Button } from 'react-bootstrap';

import PropTypes from 'prop-types';

import './AttendanceButton.css';

const AttendanceButton = ({active, disabled, past, onClick}) => {
  let unchecked = {
    className: 'fa fa-hand-rock-o',
    text: past ? "You Didn't Go" : "You're Not Going"
  };
  let checked = {
    className: 'fa fa-hand-pointer-o',
    text: past ? "You Went" : "You're Going"
  };

  const { className, text } = active ? checked : unchecked;

  return (
    <div className="AttendanceButton">
      <Button onClick={disabled ? undefined : onClick}
              disabled={disabled}
              bsStyle="default" className={ active ? 'active' : ''}>
        <i className={className} />
        <span>{text}</span>
      </Button>
    </div>
  );
};

AttendanceButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  past: PropTypes.bool,
  onClick: PropTypes.func
};

AttendanceButton.defaultProps = {};

export default AttendanceButton
