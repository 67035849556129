import React from 'react';
import PropTypes from 'prop-types';

import FactBadge from 'components/FactBadge';

import './SongFactBadges.css'

const SongFactBadges = ({hasLyrics, isCover, isJam}) => (
  <span className="SongFactBadges">
    <span className="hidden-md hidden-lg hidden-xs">
    {
      isJam
        ? <FactBadge tooltip="Jam" fa="fa-fire" className={isJam ? '' : 'disabled'}/>
        : isCover
          ? <FactBadge tooltip="Cover" fa="fa-quote-left" className={isCover ? '' : 'disabled'}/>
          : <FactBadge tooltip="Original" fa="fa-snowflake-o" className={isCover ? 'disabled' : ''}/>
    }
    </span>
    <span className="hidden-sm">
      <FactBadge tooltip="Original" fa="fa-snowflake-o" className={isCover ? 'disabled' : ''}/>
      <FactBadge tooltip="Jam" fa="fa-fire" className={isJam ? '' : 'disabled'}/>
      <FactBadge tooltip="Cover" fa="fa-quote-left" className={isCover ? '' : 'disabled'}/>
    </span>
    <FactBadge tooltip="Has Lyrics" fa="fa-tag" className={hasLyrics ? '' : 'disabled'}/>
  </span>
);

SongFactBadges.propTypes = {
  hasLyrics: PropTypes.bool,
  isCover: PropTypes.bool,
  isJam: PropTypes.bool
};

SongFactBadges.defaultProps = {
  hasLyrics: false,
  isCover: false,
  isJam: false
};

export default SongFactBadges;
