import React from 'react';
import { Grid } from 'react-bootstrap';

import DetailNavRow from './DetailNavRow';
import DetailSlugRow from './DetailSlugRow';

import PropTypes from 'prop-types';


import './DetailView.css';

const DetailView = ({heading, slug, children, nextUrl, prevUrl, ...props}) => (
  <Grid {...props}>
    <DetailNavRow nextUrl={nextUrl} prevUrl={prevUrl}>{heading}</DetailNavRow>
    <DetailSlugRow>{slug}</DetailSlugRow>
    {children}
  </Grid>
);

DetailView.propTypes = {
  heading: PropTypes.node,
  slug: PropTypes.node,
  nextUrl: PropTypes.string,
  prevUrl: PropTypes.string,
  children: PropTypes.node
};

DetailView.defaultProps = {};

export default DetailView;
