import React from 'react';
import { Button } from 'react-bootstrap'
import Link from 'components/Link';
import PropTypes from 'prop-types';

import './ContentButton.css';

const ContentButton = ({href, text, img, alt, className, children, ...props}) => (
    <div className={className ? `ContentButton ${className}` : 'ContentButton'}>
      <div className="text-center hidden-xs" >
        <Link url={href} {...props}>
          <Button className="btn-content btn-block">
            <img src={img} alt={alt} />
            <span>{text}</span>
            {children}
          </Button>
        </Link>
      </div>
      <div className="text-center hidden-lg hidden-md hidden-sm">
        <Link url={href} {...props}>
          <Button className="btn-content" >
            <img src={img} alt={alt} />
            <span>{text}</span>
            {children}
          </Button>
        </Link>
      </div>
    </div>
);

ContentButton.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

ContentButton.defaultProps = {};

export default ContentButton;
