import ShowCacheTimeDisplayContainer from './ShowCacheTimeDisplayContainer';
import { connect } from 'react-redux';
import {getShowsLastUpdateTime} from "selectors/shows";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    lastUpdateTime: getShowsLastUpdateTime(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowCacheTimeDisplayContainer);
