import React from 'react';
import PropTypes from 'prop-types';

import './SongNote.css';

const SongNote = ({note}) => (
  <span className="SongNote">{note}</span>
);

SongNote.propTypes = {
  note: PropTypes.string
};

SongNote.defaultProps = {};

export default SongNote;
