import React from 'react';
import PropTypes from 'prop-types';

import './CloseButton.css';

const CloseButton = ({onClick}) => {
  return (
    <span className="CloseButton" onClick={onClick}>
      <i className="fa fa-times-circle" />
    </span>
  )
};

CloseButton.propTypes = {
  onClick: PropTypes.func
};

CloseButton.defaultProps = {
};

export default CloseButton;
