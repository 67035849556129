import { toast } from  "react-toastify";

export const growlLevels = {
    INFO: "Info",
    WARN: "Warn",
    SUCCESS: "Success",
    ALERT: "Alert"
}


export function displayGrowl(message, level, options){
    options = options ? options : {};

    switch (level) {
      case "Info":
        options.className = "Growl info";
        options.progressClassName = "growl-progress";
        break;
      case "Warn":
        options.className = "Growl warn";
        options.progressClassName = "growl-progress-alt";
        break;
      case "Success":
        options.className = "Growl success";
        options.progressClassName = "growl-progress-alt";
        break;
      case "Alert":
        options.className = "Growl alert";
        options.progressClassName = "growl-progress-alt";
        break;
      default:
        throw new Error("Invalid growl level specified")
    }

    toast(message, options);
}