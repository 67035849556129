import YearFilterContainer from './YearFilterContainer';
import { connect } from 'react-redux';

import { setYearFilter } from 'actions/shows';
import { filteredYears } from 'selectors/shows';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSelect: filter => dispatch(setYearFilter(filter))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    filter: state.shows.yearFilter,
    years: filteredYears(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(YearFilterContainer);



