import { API_GET } from "middleware/api/get";
import { getVisibleShowAttendance, didInvalidateAttendance } from "selectors/attendance";

export const ATTENDANCE_REQUEST = 'ATTENDANCE_REQUEST';
export const ATTENDANCE_SUCCESS = 'ATTENDANCE_SUCCESS';
export const ATTENDANCE_FAILURE = 'ATTENDANCE_FAILURE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function fetchAttendance(showId) {
  return {
    [API_GET]: {
      id: showId,
      endpoint: `/shows/${showId}/attendance`,
      shouldDispatch: function(store) {
        const attendance = getVisibleShowAttendance(store.getState());
        if (!attendance) {
          return true;
        } else if (attendance.isFetching) {
          return false;
        } else {
          return didInvalidateAttendance(store.getState())
        }
      },
      types: [ATTENDANCE_REQUEST, ATTENDANCE_SUCCESS, ATTENDANCE_FAILURE]
    }
  }
}

