import React from 'react';
import PropTypes from 'prop-types';

import { DropdownButton, MenuItem } from 'react-bootstrap';

import './DropDownFilter.css';

const DropDownFilter = ({onSelect, before, after, text, keys, values}) => (
  <span className="DropDownFilter">
    <span>{before}</span>
    <DropdownButton className="dropdown"
                    bsStyle="link"
                    onSelect={onSelect}
                    title={<span>[<span className="text"> {text} <i className="fa fa-caret-down" /> </span> ]</span>}
                    noCaret={true}
    >
      {keys.map((key, i) => (
        key.substr(0,2) === "--"
          ? <MenuItem divider />
          : <MenuItem key={key} eventKey={values[i]} href={null}>{key}</MenuItem>
      ))}
    </DropdownButton>
    <span>{after}</span>
  </span>
);

DropDownFilter.propTypes = {
  onSelect: PropTypes.func,
  before: PropTypes.string,
  after: PropTypes.string,
  text: PropTypes.string,
  keys: PropTypes.array,
  values: PropTypes.array
};

DropDownFilter.defaultProps = {
  keys: []
};

export default DropDownFilter;
