export * from './async_songs';
export * from './async_songstats';

/*
 * action types
 */

export const INVALIDATE_SONGS = 'INVALIDATE_SONGS';

export const SET_VISIBLE_SONG = 'SET_VISIBLE_SONG';

export const INVALIDATE_SONG_STAT = 'INVALIDATE_SONG_STAT';

export const SET_SONGTYPE_FILTER = 'SET_SONGTYPE_FILTER';
export const CLEAR_SONGTYPE_FILTER = 'CLEAR_SONGTYPE_FILTER';
export const SongTypeFilters = {
  ORIGINAL: "original",
  COVER: "cover",
  JAM: "jam",
};


/*
 * action creators
 */

export function invalidateSongs(){
  return {
    type: INVALIDATE_SONGS
  }
}

export function setVisibleSong(id, index){
  return {
    type: SET_VISIBLE_SONG,
    id: id,
    index: index
  }
}

export function invalidateSongStat(id){
  return {
    id: id,
    type: INVALIDATE_SONG_STAT
  }
}

export function setSongTypeFilter(filter) {
  return {
    type: SET_SONGTYPE_FILTER,
    filter: filter
  }
}
export function clearSongTypeFilter(){
  return {
    type: CLEAR_SONGTYPE_FILTER
  }
}

