import React from 'react';
import PropTypes from 'prop-types';
import { pickDisplayRowHelper as helper } from './pickDisplayRowHelper';
import './PickDisplayRow.css';

const PickDisplayRow = ({ set, index, songs, picksForShow }) => {
  return (
    <div className='PickDisplayRow'>
      <div className='LabelRow'>{helper.getPickDescription(index)}</div>
      <div className='NameRow'>
        {helper.getPickName(songs, picksForShow, index)}
      </div>
    </div>
  );
};

PickDisplayRow.propTypes = {
  set: PropTypes.string.isRequired,
  pick: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  songs: PropTypes.array.isRequired
};

export default PickDisplayRow;
