import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import apiGetMiddleware from "middleware/api/get";
import apiPostMiddleware from "middleware/api/post";
import apiPutMiddleware from "middleware/api/put";
// import createBrowserHistory from "history/createBrowserHistory";
import { createBrowserHistory } from "history";
import ReduxQuerySync from "redux-query-sync";

import {
  setShowDateFilter,
  setRecordingFilter,
  setStateFilter,
  setVenueFilter,
  setYearFilter
} from 'actions/shows';
import { setSongTypeFilter } from 'actions/songs';

import rootReducer from "reducers";

export const configureStore = () => {
  const loggerMiddleware = createLogger();
  const history = createBrowserHistory();
  const store = createStore(
    rootReducer,
    applyMiddleware(
      apiGetMiddleware,
      apiPostMiddleware,
      apiPutMiddleware,
      thunkMiddleware,
      loggerMiddleware
    )
  );

  setupReduxQuerySync(store, history);

  return { store, history };
};


//TODO: Figure out a way to define the params elsewhere and then load them in here
export const setupReduxQuerySync = (store, history) =>
  ReduxQuerySync({
    store,
    params: {
      dateFilter: {
        selector: state => state.shows.dateFilter,
        action: filter => setShowDateFilter(filter),
        defaultValue: null
      },
      recordings: {
        selector: state => state.shows.recordingFilter,
        action: filter => setRecordingFilter(filter),
        defaultValue: null
      },
      state: {
        selector: state => state.shows.stateFilter,
        action: filter => setStateFilter(filter),
        defaultValue: null
      },
      venue: {
        selector: state => state.shows.venueFilter,
        action: filter => setVenueFilter(filter),
        defaultValue: null
      },
      filter: {
        selector: state => state.songs.songTypeFilter,
        action: filter => setSongTypeFilter(filter),
        defaultValue: null
      },
      year: {
        selector: state => state.shows.yearFilter,
        action: filter => setYearFilter(filter),
        defaultValue: null
      }
    },
    initialTruth: "location",
    replaceState: false,
    history: history
  });

export default configureStore;
