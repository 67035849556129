import React from 'react';
import PropTypes from 'prop-types';

import DetailContentRow from 'components/DetailView/DetailContentRow';
import './LyricsRow.css';

const LyricsRow = ({lyrics}) => {
  if (! lyrics ) return <div />;
  return (
    <div className="LyricsRow">
      <hr />
      <DetailContentRow heading="Lyrics" anchor="lyrics">
        {lyrics.split('\n').map((line, n) => <div key={`lyric_line_${n}`}>{line || <br />}</div>)}
      </DetailContentRow>
    </div>
  )
};

LyricsRow.propTypes = {
  lyrics: PropTypes.string
};

LyricsRow.defaultProps = {};

export default LyricsRow;
