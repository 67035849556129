import {
  TOUR_GAMES_RUN_CALCULATE_REQUEST,
  TOUR_GAMES_RUN_CALCULATE_SUCCESS,
  TOUR_GAMES_RUN_CALCULATE_FAILURE,
  TOUR_GAMES_SET_NEXT_SHOW_REQUEST,
  TOUR_GAMES_SET_NEXT_SHOW_SUCCESS,
  TOUR_GAMES_SET_NEXT_SHOW_FAILURE
} from 'actions/tourGamesAdmin';

const initialTourGamesAdminState = () => {
  return {
    isFetching: false,
    isError: false,
    isRunSuccess: false,
    isSetNextShowSuccess: false,
    isSetNextShowError: false
  };
};

const tourGamesAdmin = (state = initialTourGamesAdminState(), action) => {
  switch (action.type) {
    case TOUR_GAMES_RUN_CALCULATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        isRunSuccess: false
      });
    case TOUR_GAMES_RUN_CALCULATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        isRunSuccess: true
      });
    case TOUR_GAMES_RUN_CALCULATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        isRunSuccess: false
      });
    case TOUR_GAMES_SET_NEXT_SHOW_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isSetNextShowError: false,
        isSetNextShowSuccess: false
      });
    case TOUR_GAMES_SET_NEXT_SHOW_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isSetNextShowError: false,
        isSetNextShowSuccess: true
      });
    case TOUR_GAMES_SET_NEXT_SHOW_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isSetNextShowError: true,
        isSetNextShowSuccess: false
      });
    default:
      return state;
  }
};

export default tourGamesAdmin;
