/**
 * Gete the label for the pick
 * @param {number} index
 * @returns 'Set 1 Pick' | 'Set 2 Pick' | 'Encore Pick' | undefined
 */
const getPickDescription = (index) => {
  switch (index) {
    case 0:
      return 'Set 1 Pick';
    case 1:
      return 'Set 2 Pick';
    case 2:
      return 'Encore Pick';
    default:
      return;
  }
};

/**
 * Looks up the name of the pick by its id
 * @param {Songs[]} songs
 * @param {Object} picksForCurrentShow
 * @param {number} index
 * @returns String | undefined
 */
const getPickName = (songs, picksForCurrentShow, index) => {
  let songId;
  switch (index) {
    case 0:
      songId = picksForCurrentShow?.set1;
      break;
    case 1:
      songId = picksForCurrentShow?.set2;
      break;
    case 2:
      songId = picksForCurrentShow?.encore;
      break;
    default:
      break;
  }
  if (songId === 'new-original') {
    return 'New Original';
  }

  if (songId === 'new-cover') {
    return 'New Cover';
  }
  const song = songs?.find((song) => song._id === songId);
  return song?.name;
};

export const pickDisplayRowHelper = {
  getPickDescription,
  getPickName
};
