import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/UserDetailView/StatCard';

import DateBadges from "components/ShowListTable/DateBadges";

import './ShowYearCard.css';

const ShowYearCard = ({count, year, shows}) => {
  return (
    <StatCard
      className="ShowYearCard"
      count={count} text={year}
      cardHeader={
        <h3>{year} show{year.length !== 1 ? "s" : ""}</h3>
      }
      cardBody={
        shows.map( show => (
            <DateBadges key={show.date}
                        date={show.date}
                        recordingCount={show.recordings ? show.recordings.length : 0}
                        guests={show.guests}
                        showNotes={show.notes}
                        songNotes={show.songNotes.map(songNote => `${songNote.name.toUpperCase()}: ${songNote.notes.join(", ")}`).join(", ")}
            />
          )
        )
      }
    />
  )
};

ShowYearCard.propTypes = {
  count: PropTypes.node,
  year: PropTypes.node.isRequired,
  shows: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    guests: PropTypes.array,
    notes: PropTypes.object,
    songNotes: PropTypes.array
  })).isRequired
};

ShowYearCard.defaultProps = {
  count: 0
};

export default ShowYearCard;
