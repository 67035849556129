import React from 'react';
import { Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';

import './DetailContentRow.css';

const DetailContentRow = ({anchor, heading, children, className, ...props}) => {
  let headingComponent;
  if (heading){
    if (React.isValidElement(heading)){
      headingComponent = <div>{heading}</div>;
    } else {
      headingComponent = <h3>{heading}</h3>;
    }
  }
  if (anchor){
    // TODO: Can we use a different HTML elemnent? I think <a> has some accesibility implications when it isn't a link
    headingComponent = <a href={anchor} className="heading-anchor" id={anchor}>{headingComponent}</a>;
  }

  return (
    <Row className={className ? `DetailContentRow ${className}` : "DetailContentRow"} {...props}>
      <Col xs={12}>
        {headingComponent}
        <Row>
          <Col md={12} lg={12} sm={12} xs={12}>
            <div className="content">{children}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

DetailContentRow.propTypes = {
  anchor: PropTypes.string,
  heading: PropTypes.node,
  children: PropTypes.node
};

DetailContentRow.defaultProps = {};

export default DetailContentRow;
