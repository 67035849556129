import React from 'react';
import PropTypes from 'prop-types';

import YearFilter from 'components/ShowListView/YearFilter';

const YearFilterContainer = ({onSelect, filter, years}) => (
  <YearFilter onSelect={onSelect} filter={filter} years={years}/>
);

YearFilterContainer.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string,
  years: PropTypes.array
};

YearFilterContainer.defaultProps = {
  years: []
};

export default YearFilterContainer;
