import React from 'react'
import { Grid, Col } from 'react-bootstrap';
import { Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './ConfirmRegistrationView.css';
import { confirmSignUp, resendConfirmationCode } from 'lib/auth/register';

const ConfirmRegistrationView = () => {
  const [loginId, setLoginId] = React.useState(null)
  const [password, setPassword] = React.useState()
  const history = useHistory();
  const dispatch = useDispatch()

  const handleResend = () => {
    if (!loginId) {
      alert("Please enter email address")
      return
    }
    resendConfirmationCode(loginId)

  }

  return (

    <Grid className='ConfirmRegistrationView'>
      <Form horizontal>
        <FormGroup controlId="formHorizontalEmail">
          <Col sm={4}>
            <ControlLabel>
              Email
            </ControlLabel>
            <FormControl
              type="text"
              value={loginId}
              onChange={e => setLoginId(e.target.value)}
              placeholder="Email"
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalPassword">
          <Col sm={4}>
            <ControlLabel>
              Confirmation Code
            </ControlLabel>
            <FormControl
              type="text"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Code"
            />
           <br />
            <div>
              <p style={{'float': 'left'}}>Don't forget to check your spam folder!</p>
              <Button style={{'marginLeft': '1rem'}} bsSize='xsmall' onClick={handleResend}>Resend code</Button>
            </div>
          </Col>
        </FormGroup>
        <Button onClick={() => {
          confirmSignUp({email: loginId, code: password}, () => dispatch({type: 'SET_COGNITO_USER', payload: undefined}),  () => history.push('/login'))
        }}>Submit</Button>
      </Form>
    </Grid>
  )
}

export default ConfirmRegistrationView