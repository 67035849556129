import React from 'react';
import PropTypes from 'prop-types';

import LoggedOutMobileNav from './LoggedOutMobileNav'
import LoggedInMobileNav from './LoggedInMobileNav'

import './MobileNav.css';

const MobileNav = ({isLoggedIn, ...props}) => {
  if (isLoggedIn){
    return <LoggedInMobileNav {...props}/>
  }
  return <LoggedOutMobileNav {...props}/>
};

MobileNav.propTypes = {
  isLoggedIn: PropTypes.bool
};

MobileNav.defaultProps = {
  forumUrl: "https://forum.spaffnerds.com",
  isLoggedIn: false
};

export default MobileNav
