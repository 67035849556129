import SongTypeFilterContainer from './SongTypeFilterContainer';
import { connect } from 'react-redux';

import { setSongTypeFilter } from "actions/songs";

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      onSelect: filter => dispatch(setSongTypeFilter(filter))
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
      filter: state.songs.songTypeFilter
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SongTypeFilterContainer);
