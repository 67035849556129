import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import PropTypes from 'prop-types';

import './CopyButton.css';

class CopyButton extends Component {
  constructor(props){
    super(props);
    this.copyClick = this.copyClick.bind(this);
    this.state = {
      copied: false
    }
  }

  copyClick(){
    const { textBoxId, callback } = this.props;
    /* Get the text field */
    let copyText = document.getElementById(textBoxId);

    /* Select the text field */
    copyText.select();

    /* Copy the text inside the text field */
    document.execCommand("Copy");
    copyText.blur();

    this.setState({copied: true});

    /* Alert the copied text */
    callback && callback(copyText.value);


  }

  render(){
    const { copied } = this.state;

    return (
      <Button className="CopyButton btn-xs" onClick={this.copyClick}>
        {copied
          ? <i className="fa fa-check" />
          : <i className="fa fa-clipboard" />
        }
      </Button>
    )
  }
}

CopyButton.propTypes = {
  textBoxId: PropTypes.string.isRequired,
  callback: PropTypes.func
};

CopyButton.defaultProps = {
};

export default CopyButton;
