import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Link from 'components/Link';

import './NextLinkButton.css'

const NextLinkButton = ({url, icon, text, ...props}) => (
    <Link className="NextLinkButton"
          url={url}>
      <Button {...props}>
        <span className="hidden-xs text">{text}</span>
        <span className="icon">{icon}</span>
      </Button>
    </Link>
);

NextLinkButton.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string
};

NextLinkButton.defaultProps = {
  icon: "->",
  text: "Next"
};

export default NextLinkButton;
