import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import DetailContentRow from 'components/DetailView/DetailContentRow';
import RecordingButtonContainer from 'containers/RecordingButtonContainer';
import AddRecordingButtonContainer from "containers/AddRecordingButtonContainer";
import ArchiveRecordingButtonContainer from "containers/ArchiveRecordingButtonContainer";

import sort from 'lib/sort';

import './AvailableRecordingsRow.css';

class AvailableRecordingsRow extends React.Component{
  render() {
    const { recordings, addRecording, isShowLive, isShowRecent, userRolesInsecure, isPastShow } = this.props;
    const isPrivileged = userRolesInsecure.includes('admin') || userRolesInsecure.includes('moderator');
    if (! isPastShow && (! recordings || ! recordings.length)) { return "" }
    
    return (
      <div className="AvailableRecordingsRow">
        <hr />
        <DetailContentRow heading="Available Recordings">
          {
            recordings
              .filter(recording => !recording.recordingData.hidden)
              .sort(sort.recordings.type)
              .map((recording, index) => {
                // if it's a recent mixlr stream, and you're an admin
                // then we will show you a link to the showreel
                // so that you can potentially add showreels
                if (
                  recording.source.toLowerCase() === 'mixlr'
                  && recording.recordingData.content === 'stream'
                  && ! isShowLive
                ) {
                  if (! isPrivileged || ! isShowRecent) {
                    // just hide it
                    return ''
                  } else {
                    recording.recordingData.content = 'hidden';
                    recording.recordingData.description = 'hidden';
                    recording.recordingData.url += '/showreel';
                  }
                }
                return (
                  <div key={`${recording.source}-${index}`}>
                    <Col xs={12} sm={4} md={3} lg={3} key={index} className="hidden-xs">
                      { recording.source === "Archive"
                        ? <ArchiveRecordingButtonContainer recording={recording}/>

                        : <RecordingButtonContainer
                            type={recording.source}
                            recording={recording}
                            date={recording.recordingData.date.substr(0,10)}
                          />
                      }

                    </Col>
                    <Row className="hidden-lg hidden-md hidden-sm">
                      <Col xs={12} sm={4} md={3} lg={3} key={index}>
                        { recording.source === "Archive"
                          ? <ArchiveRecordingButtonContainer recording={recording}/>

                          : <RecordingButtonContainer
                            type={recording.source}
                            recording={recording}
                            date={recording.recordingData.date.substr(0,10)}
                          />
                        }

                      </Col>
                    </Row>
                  </div>
                );
            })
          }
          { addRecording ? '' :
            <div>
              <Col xs={12} sm={4} md={3} lg={3} className="hidden-xs">
                <AddRecordingButtonContainer/>
              </Col>
              <Row className="hidden-lg hidden-md hidden-sm">
                <Col xs={12} sm={4} md={3} lg={3} >
                    <AddRecordingButtonContainer/>
                </Col>
              </Row>
            </div>
          }
        </DetailContentRow>
      </div>
    )
  }
}

AvailableRecordingsRow.propTypes = {
  recordings: PropTypes.arrayOf(
    PropTypes.shape({
      recordingType: PropTypes.oneOf(['Soundboard', 'Aud', 'Video', 'Audio']),
      recordingData: PropTypes.shape({
        hidden: PropTypes.bool,
        description: PropTypes.string,
        publisher: PropTypes.string,
        username: PropTypes.string,
        pageUrl: PropTypes.string,
        url: PropTypes.string,
        content: PropTypes.oneOfType([
          PropTypes.array,
          PropTypes.string
        ])
      })
    })
  ),
  isShowLive: PropTypes.bool,
  isShowRecent: PropTypes.bool,
  userRolesInsecure: PropTypes.array
};

AvailableRecordingsRow.defaultProps = {
  userRolesInsecure: []
};

export default AvailableRecordingsRow;
