export const showBulkUploadBatchFilename = state => {
  return state.admin.showUpload.filename
};

export const showBulkUploadBatch = state => {
  return state.admin.showUpload.showBatch
};

export const postShowBatchAll = state => {
  return state.admin.postShowBatch
};

export const isPostingShowBatch = state => {
  const postShowBatch = postShowBatchAll(state);
  return postShowBatch && postShowBatch.isFetching
    && ! postShowBatch.isError
    && ! postShowBatch.didInvalidate
};

export const isPostingShowBatchError = state => {
  const postShowBatch = postShowBatchAll(state);
  return postShowBatch && postShowBatch.isError
    && ! postShowBatch.isFetching
    && ! postShowBatch.didInvalidate
};

export const isPostingShowBatchSuccess = state => {
  const postShowBatch = postShowBatchAll(state);
  return postShowBatch
    && postShowBatch.data && postShowBatch.data.message === "success"
    // && postShowBatch.data.count && postShowBatch.data.count > 0
    && ! postShowBatch.isFetching
    && ! postShowBatch.isError
    && ! postShowBatch.didInvalidate
};
