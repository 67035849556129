import React from 'react';
import PropTypes from 'prop-types';

import DropDownFilter from '../DropDownFilter';
import { ShowDateFilters } from 'actions/shows';

import './ShowDateFilter.css'

const ShowDateFilter = ({dateFilter, onSelect}) => {

  let keys = [
    "All Shows",
    "Setlists",
    "Upcoming Shows",
  ];
  let values = [
    null,
    ShowDateFilters.CURRENT,
    ShowDateFilters.FUTURE,
  ];

  const index = !dateFilter ? 0 : values.findIndex(value => value === dateFilter) || 0;

  return (
    <DropDownFilter
      before="From"
      text={keys[index]}
      onSelect={onSelect}
      keys={keys}
      values={values}
    />
  )
};

ShowDateFilter.propTypes = {
  dateFilter: PropTypes.oneOf([...Object.keys(ShowDateFilters), null]),
  onSelect: PropTypes.func
};

ShowDateFilter.defaultProps = {};

export default ShowDateFilter;
