const songIdToLowerCase = songId => {
  if ( ! songId || ! songId.toLowerCase ) {
    return songId;
  }
  return songId.toLowerCase();
};
const SongNametoLowerCase =songName => {
  if (!songName || !songName.toLowerCase) {
    return songName
  }
  return songName.toLowerCase()
}
const sort = {
  set: {
    name: (a, b) => {
      let a1 = a.substr(0,2) === "En" ? "Z" + a : a.substr(0,2) === "So" ? "!" + a : a;
      let b1 = b.substr(0,2) === "En" ? "Z" + b : b.substr(0,2) === "So" ? "!" + b : b;
      return a1 > b1 ? 1 : -1;
    }
  },
  shows: {
    date_asc: byField('date', true),
    date_desc: byField('date', false)
  },
  songs: {
    id_asc: byField('_id', true, songIdToLowerCase),
    id_desc: byField('_id', false, songIdToLowerCase),
    name_asc: byField('name', true, SongNametoLowerCase),
    name_desc: byField('name', false, SongNametoLowerCase)
  },
  sortKey: {
    id_asc: byField('sortKey', true),
    id_desc: byField('sortKey', false)
  },
  songStats: {
    count_asc: byDeepField('playStats.playCount', true),
    count_desc: byDeepField('playStats.playCount', false),
    name_asc: byField('name', true),
    name_desc: byField('name', false),
    id_asc: (a, b) => { return a > b ? 1 : a === b ? 0 : -1 },
    id_desc: (a, b) => { return a > b ? -1 : a === b ? 0 : 1 }
  },
  string: {
    desc: (a, b) => {
      if (a.toString() < b.toString())
        return 1;
      if (a.toString() > b.toString())
        return -1;
      return 0;
    }
  },
  recordings: {
    type: (a, b) => {

      const a1 = makeRecordingSortKey(a);
      const b1 = makeRecordingSortKey(b);

      if (a1.toString() > b1.toString())
        return 1;
      if (a1.toString() < b1.toString())
        return -1;
      return 0;

    }
  },
  tourGames: {
    showId_asc: byField('showId', true),
    showId_desc: byField('showId', false)
  }
}

// We want to sort by a value that's deep down in a sub property
//  of the object that we want to sort
// To do this we take `sortFieldString` as a `.` delimited
//  property specification: "some.nested.property"
// Then we find that property on the objects in question
//  and compare them
export function byDeepField(sortFieldString, sortAscending) {
  // parse the string to find the value we want.
  const getValue = (sortFieldString, sortObj) => {
    let val = sortObj;
    sortFieldString.split('.').forEach(key => {
      if (val){
        val = val[key]
      }
    });
    return val;
  };

  const sortAscendingFunction = (a, b) => {
    let aValue = getValue(sortFieldString, a);
    let bValue = getValue(sortFieldString, b);

    if (aValue < bValue)
      return -1;
    if (aValue > bValue)
      return 1;
    return 0;
  };
  let sortDescendingFunction = function (a, b) {
    let aValue = getValue(sortFieldString, a);
    let bValue = getValue(sortFieldString, b);

    if (aValue < bValue)
      return 1;
    if (aValue > bValue)
      return -1;
    return 0;
  };
  let sortFunction = sortAscending ? sortAscendingFunction : sortDescendingFunction;
  return sortFunction;
};

function byField(sortField, sortAscending, fnTransform) {
  // if a transform function is provided we use it
  // otherwise we use a default that does no transformation
  fnTransform = fnTransform ? fnTransform : input => input;
  let sortAscendingFunction = function (a, b) {
    // place strings before numbers when we have mixed strings and numbers
    if (typeof fnTransform(a[sortField]) === "string" &&  typeof fnTransform(b[sortField]) === "number")
      return 1;
    if (typeof fnTransform(a[sortField]) === "number" &&  typeof fnTransform(b[sortField]) === "string")
      return -1;

    // only do direct comparison on like types
    if (fnTransform(a[sortField]) < fnTransform(b[sortField]))
      return -1;
    if (fnTransform(a[sortField]) > fnTransform(b[sortField]))
      return 1;
    return 0;
  };
  let sortDescendingFunction = function (a, b) {
    // place strings after numbers when we have mixed strings and numbers
    if (typeof fnTransform(a[sortField]) === "string" &&  typeof fnTransform(b[sortField]) === "number")
      return -1;
    if (typeof fnTransform(a[sortField]) === "number" &&  typeof fnTransform(b[sortField]) === "string")
      return 1;

    // only do direct comparison on like types
    if (fnTransform(a[sortField]) < fnTransform(b[sortField]))
      return 1;
    if (fnTransform(a[sortField]) > fnTransform(b[sortField]))
      return -1;
    return 0;
  };
  let sortFunction = sortAscending ? sortAscendingFunction : sortDescendingFunction;
  return sortFunction;

}

/*** Recording Helpers ***/
const recordingOrderTable = (type) => {
  switch(type){
    case "nugs.net":
      return "a_";
    case "Archive":
      return "b_";
    case "YouTube":
      return "c_";
   case "Mixlr":
      return "z_";
    default:
      return "m_"
  }
};
const makeRecordingDescriptionKey = (recordingData) => {
  return recordingData.username
    || recordingData.content
    || recordingData.publisher;
};
const makeRecordingSortKey = (item) => {
  return `${recordingOrderTable(item.source)}${makeRecordingDescriptionKey(item.recordingData)}`;
};

export default sort;

