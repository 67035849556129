import MPArchiveTitleContainer from './MPArchiveTitleContainer';
import { connect } from 'react-redux';
import { setCurrentTrack, setPlayStatus, setCurrentTime } from 'actions/mediaPlayer/index';
import {
  getActiveArchiveCurrentTrackURL,
  getActiveArchiveTracks, getActiveRecordingTitle,
  getCurrentTrack, getCurrentTrackTime,
  getIsPlaying, getNextTrack, getPrevTrack,
  getIsBufferedPlaying
} from "selectors/mediaPlayer/index";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    playTrack: (track) => {
      if ( track ) {
        dispatch(setCurrentTrack(track));
        dispatch(setPlayStatus(true));
      }
    },
    setPlayerState: (isPlaying) => dispatch(setPlayStatus(isPlaying)),
    updateTime: (time) => dispatch(setCurrentTime(time)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentTrack: getCurrentTrack(state),
    currentTrackTime: getCurrentTrackTime(state),
    isPlaying: getIsPlaying(state),
    isBufferedPlaying: getIsBufferedPlaying(state),
    audioTracks: getActiveArchiveTracks(state),
    currentTrackURL: getActiveArchiveCurrentTrackURL(state),
    nextTrack: getNextTrack(state),
    prevTrack: getPrevTrack(state),
    recordingTitle: getActiveRecordingTitle(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MPArchiveTitleContainer);
