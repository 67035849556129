import { API_GET } from "middleware/api/get";

export const SONGSTATS_REQUEST = 'SONGSTATS_REQUEST';
export const SONGSTATS_SUCCESS = 'SONGSTATS_SUCCESS';
export const SONGSTATS_FAILURE = 'SONGSTATS_FAILURE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function fetchSongStats(id) {
  return {
    [API_GET]: {
      id: id,
      endpoint:`/stats/all/songs/${id}`,
      shouldDispatch: function(store) {
        const songStats = store.getState().songs.stats;
        if (! songStats[id] || ! songStats[id].stat){
          return true;
        } else if (songStats[id].isFetching){
          return false;
        } else {
          return songStats[id].didInvalidate;
        }
      },
      types: [SONGSTATS_REQUEST, SONGSTATS_SUCCESS, SONGSTATS_FAILURE]
    }
  }
}

