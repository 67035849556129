import React from 'react';
import PropTypes from 'prop-types';

import DropDownFilter from '../DropDownFilter';
import { RecordingFilters } from 'actions/shows';

import './RecordingFilter.css';

const RecordingFilter = ({onSelect, filter}) => {
  let keys = [
    "Any Recordings",
    "- Audio or Video",
    "- No Audio or Video",
    "- nugs.net Audio or Video",
    "----------",
    "Any Audio",
    "- Free Soundboard Audio",
    "- nugs.net Audio",
    "- Soundboard Audio",
    "- Audience Audio",
    "----------",
    "Any Video",
    "- Full Show Video",
    "- Partial Show Video",
    "- nugs.net Video"
  ];
  let values = [
    null,
    RecordingFilters.ANY,
    RecordingFilters.NONE,
    RecordingFilters.NUGS,
    null,
    RecordingFilters.AUDIO,
    RecordingFilters.AUDIO_SBD_FREE,
    RecordingFilters.AUDIO_NUGS,
    RecordingFilters.AUDIO_SBD,
    RecordingFilters.AUDIO_AUD,
    null,
    RecordingFilters.VIDEO,
    RecordingFilters.VIDEO_ALL,
    RecordingFilters.VIDEO_SOME,
    RecordingFilters.VIDEO_NUGS,
  ];

  //TODO: update
  let index = !filter ? 0 : values.findIndex(value => value === filter);
  index = index < 0 ? 0 : index;

  return (
    <DropDownFilter
      before="With"
      text={keys[index].replace('- ','')}
      onSelect={onSelect}
      keys={keys}
      values={values}
    />
  )
};

RecordingFilter.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.oneOf([...Object.keys(RecordingFilters).map(key => RecordingFilters[key]), null])
};

RecordingFilter.defaultProps = {
  filter: null
};

export default RecordingFilter;
