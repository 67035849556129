import React from 'react';
import { Button } from 'react-bootstrap'
import tracker from 'lib/tracker';

import './ClientSideButton.css';

const ClientSideButton = ({onClick, text, img, alt, className, children, tracking, fa, ...props}) => {
  const trackedOnClick = (e) => {
    if (tracking){
      const {label, action, category} = tracking;
      tracker.trackEvent(category, action, label);
    }
    if (onClick){
      onClick(e);
    }
  };

  return (
    <div className={className ? `ContentButton ${className}` : 'ContentButton'}>
      <div className="text-center hidden-xs" >
        <Button className="btn-content btn-block" onClick={trackedOnClick} {...props}>
          { fa
            ? <i className={fa} />
            : <img src={img} alt={alt} />
          }
          <span>{text}</span>
          {children}
        </Button>
      </div>
      <div className="text-center hidden-lg hidden-md hidden-sm">
        <Button className="btn-content" onClick={trackedOnClick} {...props}>
          { fa
            ? <i className={fa} />
            : <img src={img} alt={alt} />
          }
          <span>{text}</span>
          {children}
        </Button>
      </div>
    </div>
  )
};

ClientSideButton.propTypes = {
};

ClientSideButton.defaultProps = {
};

export default ClientSideButton;
