import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ShowDetailView from 'components/ShowDetailView';
import ErrorView from "components/ErrorView";

class HomepageShowDetailViewContainer extends Component {
  constructor(props){
    super(props);
    const { visibleShow, onSetVisibleShow } = this.props;
    if (visibleShow) {
      onSetVisibleShow(visibleShow.date.substr(0,10))
    }
  }
  componentWillReceiveProps(nextProps){
    let oldId = this.props.visibleShow && this.props.visibleShow.date && this.props.visibleShow.date.substr(0,10);
    let newId = nextProps.visibleShow && nextProps.visibleShow.date && nextProps.visibleShow.date.substr(0,10);
    if (oldId !== newId){
      this.props.onSetVisibleShow && this.props.onSetVisibleShow(newId);
    }
  }
  render(){
    const {visibleShow, isFetching, onNoVisibleShow, ...props} = this.props;
    const { visibleShowError } = this.props;
    if (!visibleShow) {
      if (visibleShowError){
        const { error, status, responseType, url } = visibleShowError;
        return (
          <ErrorView
            status={status}
            message={error.message}
            stack={error.stack}
            responseType={responseType}
            url={url}
          />
        )
      }
      if (! isFetching ){
        onNoVisibleShow();
      }
    }
    return <ShowDetailView visibleShow={visibleShow} {...props}/>
  }
}

HomepageShowDetailViewContainer.propTypes = {
  visibleShow: PropTypes.shape({
    date: PropTypes.string,
    venue: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    sets: PropTypes.array
  }),
  isShowLive: PropTypes.bool,
  isShowRecent: PropTypes.bool,
  userRolesInsecure: PropTypes.array
};

HomepageShowDetailViewContainer.defaultProps = {};

export default HomepageShowDetailViewContainer;

