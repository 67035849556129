import React from 'react';
import PropTypes from 'prop-types';

import Song from '../Song';

import './Songlist.css';

const Songlist = ({songs}) => (
  <div className="Songlist">
    {
      songs.map((song, i) => (
        <Song name={song.name}
              url={song.url}
              jamChar={song.jamChar}
              flag={song.flag}
              key={`${song}_${i}`}
        />
      ))
    }
  </div>
);

Songlist.propTypes = {
  songs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    jamChar: PropTypes.string,
    url: PropTypes.string,
    flag: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired
};

Songlist.defaultProps = {};

export default Songlist;
