import React from 'react';

import './Growl.css';
import { ToastContainer } from "react-toastify";

const Growl = ({...props}) => {
    return (
        <ToastContainer
            {...props}
        />
    )
};

Growl.propTypes = {
};

Growl.defaultProps = {
};

export default Growl;
