import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';

import _ from 'lodash';

import TrackListItem from "components/MediaPlayer/TrackListItem";

import './ArchiveBody.css';

const ArchiveBody = ({tracks, playTrack, playingTrack, isPlaying}) => {
  return (
    <Grid className="ArchiveBody">
      <div>
        {tracks
          ? tracks.map((track, index) => {
            return (
              <TrackListItem
                key={index}
                track={track}
                playTrack={playTrack}
                active={_.isEqual(track, playingTrack)}
                isPlaying={isPlaying && _.isEqual(track, playingTrack)}
              />
            )
          })
          : <span>No Tracks To Play</span>
        }
      </div>
    </Grid>
  )
};

ArchiveBody.propTypes = {
  tracks: PropTypes.array,
  playTrack: PropTypes.func,
  playingTrack: PropTypes.object,
  isPlaying: PropTypes.bool
};

ArchiveBody.defaultProps = {};

export default ArchiveBody;
