import React from 'react';

import FlushShowsButton from 'components/ReloadShowsRow/FlushShowsButton';

const FlushShowsButtonContainer = ({onFlush, ...props}) => (
  <FlushShowsButton onClick={onFlush} {...props}/>
);

FlushShowsButtonContainer.propTypes = {
};

FlushShowsButtonContainer.defaultProps = {
};

export default FlushShowsButtonContainer;
