import HomepageShowDetailViewContainer from './HomepageShowDetailViewContainer';
import { connect } from 'react-redux';

import { getUsername, getUserRolesINSECURE } from "selectors/user";
import {
  getLatestShow,
  getNextVisibleShow,
  getPrevVisibleShow,
  isVisibleShowLive,
  isVisibleShowRecent,
  isVisibleShowFetching,
  getVisibleShowError
} from "selectors/shows";

import { getAddRecordings } from "selectors/recordings";
import {clearAddRecording} from "actions/recordings";

import { setVisibleShow, fetchShow } from 'actions/shows';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSetVisibleShow: id => {
      dispatch(clearAddRecording());
      dispatch(setVisibleShow(id, 0));
    },
    onNoVisibleShow: () => dispatch(fetchShow('latest'))
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: isVisibleShowFetching(state),
    visibleShow: getLatestShow(state),
    nextShow: getNextVisibleShow(state),
    prevShow: getPrevVisibleShow(state),
    username: getUsername(state),
    addRecording: getAddRecordings(state),
    isShowLive: isVisibleShowLive(state),
    isShowRecent: isVisibleShowRecent(state),
    userRolesInsecure: getUserRolesINSECURE(state),
    visibleShowError: getVisibleShowError(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomepageShowDetailViewContainer)
