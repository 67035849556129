import React from 'react';
import PropTypes from 'prop-types';

import './YouTubeBody.css';

const YouTubeBody = ({url, title, height, width}) => {
  return (
    <iframe
      className="YouTubeBody"
      src={url}
      title={title}
    />
  )
};

YouTubeBody.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

YouTubeBody.defaultProps = {
};

export default YouTubeBody;
