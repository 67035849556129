import { API_GET } from 'middleware/api/get';

export const TOUR_GAMES_GET_TOUR_REQUEST = 'TOUR_GAMES_GET_TOUR_REQUEST';
export const TOUR_GAMES_GET_TOUR_SUCCESS = 'TOUR_GAMES_GET_TOUR_SUCCESS';
export const TOUR_GAMES_GET_TOUR_FAILURE = 'TOUR_GAMES_GET_TOUR_FAILURE';

/**
 * Calls the API endpoint to fetch a ticket
 * @param {String} userId
 * @param {String} tourId
 * @param {String} gameType
 * @returns
 */
export function fetchTour(tourId) {
  return {
    [API_GET]: {
      authenticated: false,
      endpoint: `/tourGames/${tourId}/tour`,
      shouldDispatch: function (store) {
        return true;
      },
      types: [
        TOUR_GAMES_GET_TOUR_REQUEST,
        TOUR_GAMES_GET_TOUR_SUCCESS,
        TOUR_GAMES_GET_TOUR_FAILURE
      ]
    }
  };
}
