import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sort from 'lib/sort';

import SongStatCard from "components/UserDetailView/UserSongStats/SongStatCard";
import SortButtons from "components/UserDetailView/UserSongStats/SortButtons/SortButtons";

import './UserSongStats.css';

class UserSongStats extends Component {
  constructor(props){
    super(props);
    this.onSortClick = this.onSortClick.bind(this);

    this.state = {
      sortType: "numeric",
      sortDirection: "desc",
      sortFn: sort.songStats.count_desc
    }
  }

  onSortClick(type, direction){
    const sort_fn_type = type === 'alpha' ? "name" : "count";
    const sort_fn_dir = direction > 0 ? 'asc' : 'desc';
    const sortFn = sort.songStats[`${sort_fn_type}_${sort_fn_dir}`];
    this.setState({sortType: type, sortDirection: direction, sortFn: sortFn});
  }

  render(){
    const { songStats } = this.props;
    const { sortType, sortFn } = this.state;

    return (
      <div className="UserSongStats">
        <h3>Song Counts <SortButtons sortType={sortType} onSortClick={this.onSortClick}/></h3>
        {Object.keys(songStats).map(songId => songStats[songId]).sort(sortFn).map(song => {
          return (
            <SongStatCard key={song._id}
                          songId={song._id}
                          songName={song.name}
                          playCount={song.playStats.playCount}
                          lastPlayed={song.playStats.lastPlayed}
                          avgGap={song.playStats.avgGap}
                          longestGap={song.playStats.longestGap}
                          setStats={song.setStats}
                          shows={song.playStats.showList}
            />
          )
        })}
      </div>
    )
  }
}

UserSongStats.propTypes = {
  songStats: PropTypes.object.isRequired
};

UserSongStats.defaultProps = {
};

export default UserSongStats;
