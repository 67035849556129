import React from 'react';
// import PropTypes from 'prop-types';

import Helmet from 'react-helmet';
import { Grid } from 'react-bootstrap';

import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';
import DetailContentRow from 'components/DetailView/DetailContentRow';
import ShowListFiltersRow from '../ShowListFiltersRow';
import LoadingIcon from "components/LoadingIcon/LoadingIcon";
import ShowListTable from "components/ShowListTable/ShowListTable";

import './LoadingShowListView.css';

const LoadingShowListView = () => {
  const dummyShows = ["1","2","3","4","5","6","7","8","9","10"].map(x => {
    return {
        _id: x,
        date: "????-??-??",
        multiShowIndex: 0,
        showNotes: {},
        songNotes: [],
        recordingCount: 0,
        guests: [],
        venue: { name: "??????????????????????????????????????????", city: "?????????????????", state: "??"}
      }
  });
  return (
    <Grid className="LoadingShowListView">
      <Helmet>
        <title>Loading Shows</title>
      </Helmet>
      <DetailNavRow>
        <span className="hidden-xs"><LoadingIcon /> Loading <LoadingIcon /></span>
        <span className="hidden-sm hidden-md hidden-lg"><LoadingIcon /> Loading <LoadingIcon /> Setlists</span>
      </DetailNavRow>
      <DetailSlugRow>
        <div>Showing <LoadingIcon/> Shows</div>
      </DetailSlugRow>
      <ShowListFiltersRow />
      <DetailContentRow heading="Shows">
        <ShowListTable showList={dummyShows} noLinks={true}/>
      </DetailContentRow>
    </Grid>
  );
};

LoadingShowListView.propTypes = {
};

LoadingShowListView.defaultProps = {
};

export default LoadingShowListView;
