import React, { Component } from 'react';
import MediaPlayerContainer from "containers/MediaPlayerContainer";
import ReloadShowsRow from "components/ReloadShowsRow";

import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <MediaPlayerContainer />
        <ReloadShowsRow/>
      </div>
    )
  }
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer
