import React, { Component } from 'react';

import DetailContentRow from 'components/DetailView/DetailContentRow'
import Songlist from './Songlist';
import Notes from './Notes';
import Guest from './Notes/Guest';
import SongNote from './Notes/SongNote';

import PropTypes from 'prop-types';
import './SetlistContentRow.css';

class SetlistContentRow extends Component {
    constructor(props) {
      super(props);

      this.flags = [];

      this.songsToSongProps = this.songsToSongProps.bind(this);
    }

    songsToSongProps(song, i, songs){
     let jamChar;
      let flag;
      if (songs.length - 1 !== i){
        jamChar = song.jamOut < 1 ? "stop" : "jam";
      }

      if (song.guests.length || song.notes.length){
        flag = this.flags.length + 1;
        let flags = [];
        song.notes.forEach(note => flags.push(<SongNote key={note} note={note}/>));
        song.guests.forEach(guest => flags.push(<Guest key={`${guest.firstName}_${guest.lastName}`}
                                                       firstName={guest.firstName}
                                                       lastName={guest.lastName}
                                                       band={guest.band}
                                                       instrument={guest.instrument} />));
        this.flags.push(flags);
      }
      return {
        name: song.name,
        jamChar: jamChar,
        url: `/songs/${song._id}`,
        flag: flag
      }
    }

    makeSetlistToDisplay(sets){
     // figure out if there's exactly 1 encore
      let numOfEncores = sets.filter((set) => set.name.substr(0,6).toLowerCase() === 'encore').length;

      return sets.map((set) => {
        let setDisplayName = set.name;
        if (numOfEncores === 1 && set.name.substr(0,6).toLowerCase() === 'encore'){
          setDisplayName = 'Encore';
        }
        return (
          <DetailContentRow key={setDisplayName} heading={setDisplayName} anchor={setDisplayName}>
            <Songlist songs={set.songs.map(this.songsToSongProps)} />
          </DetailContentRow>
        )
      });
    }
    render() {
      // reset this.flags
      this.flags = [];
      let setsToDisplay = this.makeSetlistToDisplay(this.props.sets);

      return (
        <div className="SetlistContentRow">
          {setsToDisplay}
          <Notes flags={this.flags} />
        </div>
      );
    }
}

SetlistContentRow.propTypes = {
    sets: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      songs: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        jamOut: PropTypes.number,
        guests: PropTypes.array,
        notes: PropTypes.array
      }))
    }))
};

SetlistContentRow.defaultProps = {};

export default SetlistContentRow;
