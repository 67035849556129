//TODO: Add this reducer to the combineReducers call in src/reducers/index.js then remove this comment
import {
  USERRECORDINGS_REQUEST,
  USERRECORDINGS_SUCCESS,
  USERRECORDINGS_FAILURE,
  USERRECORDINGS_INVALIDATE
} from 'actions/userRecordings';

import {
  CLEAR_ADD_RECORDING
} from 'actions/recordings';

function initialUserRecordingsState(){
  return {
    isFetching: false,
    isError: false,
    didInvalidate: false,
  }
}

function userRecordings(state = initialUserRecordingsState(), action) {
  switch (action.type) {
    case CLEAR_ADD_RECORDING:
      return initialUserRecordingsState();

    case USERRECORDINGS_REQUEST:
       return Object.assign({}, state, {
         isFetching: true,
         isError: false,
         didInvalidate: false
       });
    case USERRECORDINGS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        data: action.response,
        lastUpdate: action.receivedAt
      });
    case USERRECORDINGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });
    case USERRECORDINGS_INVALIDATE:
      return {
        isFetching: false,
        isError: false,
        didInvalidate: true
      };
    default:
      return state;
  }
}

export default userRecordings;