import { getToken } from 'middleware/api/api';
import { isLoggedIn, getLoginPollInterval, getCognitoUser } from 'selectors/user';
import { fetchUserProfile, invalidateUserProfile } from 'actions/user';
import { Auth } from 'aws-amplify'

// promise that resolves after an interval and calls the resolve method
let sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

// run a promise, if it succeeds, print it, if it fail, sleep and try again
let poll = (promiseFn, time) =>
  promiseFn().then((result) => {
    sleep(time).then(() => poll(promiseFn, time));
  });

// In hindsight this could probably have been done more simply
//  by not using promises and just using a single call to
//  setTimeout.
// The only advantage that I see to using promises the way we
//  are is that we're ready to run async stuff inside the body
//  of the function below.
// I don't know if we're going to need that in the future
//  but it's there if we do
// I'd refactor this to be setTimeout only
//  but it is working and I don't see the need to rewrite it
const pollAuthStatus = (store) =>
  poll(
    () =>
      new Promise((resolve) => {
        if (isLoggedIn(store.getState())) {
          if (getCognitoUser(store.getState())) return
          if (!getToken()) {
            store.dispatch(invalidateUserProfile());
            resolve('invalidate user profile');
          }
        } else if (getToken()) {
          store.dispatch(fetchUserProfile());
          resolve('fetch user profile');
          Auth.currentAuthenticatedUser()
            .then(currentAuthenticatedUser => {
              store.dispatch({ type: 'SET_COGNITO_USER', payload: currentAuthenticatedUser })
            })
            .catch(err => console.error(err))
        }
        resolve('nothing to do');
      }),
    getLoginPollInterval(store.getState())
  );
export default pollAuthStatus;
