import React from 'react';
import Helmet from 'react-helmet';
import { Grid } from 'react-bootstrap';

import PropTypes from 'prop-types';

import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';
import DetailContentRow from 'components/DetailView/DetailContentRow';
import SongListTable from 'components/SongListTable';
import StatLabel from 'components/StatLabel';

import SongTypeFilterContainer from "containers/SongTypeFilterContainer";

import './SongListView.css';
import { songListViewMetaData } from "lib/html/meta";

const SongListView = ({songList, songTypeFilter}) => {
  const metaData = songListViewMetaData(songTypeFilter);
  return (
    <Grid className="SongListView">
      <Helmet>
        <title>{ metaData.title }</title>
        <meta name="keywords" content={ metaData.keywords }/>
        <meta name="description" content={ metaData.description } />
      </Helmet>
      <DetailNavRow>
        <span>Spafford Songs</span>
      </DetailNavRow>
      <DetailSlugRow>
        <StatLabel showColon={false} leadText="Showing" label="Song" count={songList ? songList.length : 0}/>
      </DetailSlugRow>
      <DetailContentRow className="text-center">
        <SongTypeFilterContainer/>
      </DetailContentRow>
      <DetailContentRow heading="Songs">
        <SongListTable songList={songList}/>
      </DetailContentRow>
    </Grid>
  );
};

SongListView.propTypes = {
  songList: PropTypes.array,
  songTypeFilter: PropTypes.string
};

SongListView.defaultProps = {};

export default SongListView;
