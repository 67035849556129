import MediaPlayerBodyContainer from './MediaPlayerBodyContainer';
import { connect } from 'react-redux';
import { getActiveRecordingSource } from "selectors/mediaPlayer";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    source: getActiveRecordingSource(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MediaPlayerBodyContainer);
