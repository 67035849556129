import React from 'react';
import PropTypes from 'prop-types';

import PayPalDonate from 'components/PayPalDonate';

const PayPalDonateContainer = ({user, ...props}) => (
  <PayPalDonate user={user} {...props}/>
);

PayPalDonateContainer.propTypes = {
  user: PropTypes.string
};

PayPalDonateContainer.defaultProps = {
};

export default PayPalDonateContainer;
