import { API_POST } from 'middleware/api/post';

export const TOUR_GAMES_CREATE_PICKS_REQUEST =
  'TOUR_GAMES_CREATE_PICKS_REQUEST';
export const TOUR_GAMES_CREATE_PICKS_SUCCESS =
  'TOUR_GAMES_CREATE_PICKS_SUCCESS';
export const TOUR_GAMES_CREATE_PICKS_FAILURE =
  'TOUR_GAMES_CREATE_PICKS_FAILURE';
export const TOUR_GAMES_CREATE_PICKS_INVALIDATE =
  'TOUR_GAMES_CREATE_PICKS_INVALIDATE';

/**
 * Calls the API endpoint to create a ticket
 * @param {String} userId
 * @param {String} tourId
 * @param {String} gameType
 * @returns
 */
export function createPicks(
  userId,
  tourId,
  showId,
  ticketId,
  picks,
  gameType = 'SpaffnerdsTourGames'
) {
  return {
    [API_POST]: {
      payload: {
        picks
      },
      authenticated: true,
      endpoint: `/tourGames/${tourId}/${gameType}/tickets/${userId}/${ticketId}/showPicks/${showId}`,
      shouldDispatch: function (store) {
        return true;
      },
      types: [
        TOUR_GAMES_CREATE_PICKS_REQUEST,
        TOUR_GAMES_CREATE_PICKS_SUCCESS,
        TOUR_GAMES_CREATE_PICKS_FAILURE
      ]
    }
  };
}
