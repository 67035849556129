import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import './SliderHeader.css';

const LogoButton = ({logo, link, slideout}) => {
  return (
    <div className="navbar-header">
      <Link className="navbar-brand" to={link}>
        <img alt="Spaffnerds" src={logo}/>
      </Link>
      <button type="button" className="navbar-toggle collapsed" onClick={() => slideout.toggle()}>
        <span className="sr-only" >Toggle navigation</span>
        <span className="icon-bar" />
        <span className="icon-bar" />
        <span className="icon-bar" />
      </button>
    </div>
  )
};

LogoButton.propTypes = {
  logo: PropTypes.string.isRequired,
  link: PropTypes.string,
  slideout: PropTypes.object
};

LogoButton.defaultProps = {
  link: "/"
};

const SliderHeader = ({brandLinkUrl, brandLogoUrl, children, slideout}) => {
  return (
    <nav className="SliderHeader navbar navbar-inverse">
      <div className="container">
        <LogoButton link={brandLinkUrl} logo={brandLogoUrl} slideout={slideout}/>
        <div className="navbar-collapse collapse">
          {children}
        </div>
      </div>
    </nav>
  )
};

SliderHeader.propTypes = {
  brandLogoUrl: PropTypes.string.isRequired,
  brandLinkUrl: PropTypes.string,
  children: PropTypes.node,
  slideout: PropTypes.object
};

SliderHeader.defaultProps = {
  brandLinkUrl: "/"
};

export default SliderHeader;