import React from 'react';
// import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';

import './ReloadShowsRow.css';
import ShowCacheTimeDisplayContainer from "containers/ShowCacheTimeDisplayContainer";
import FlushShowsButtonContainer from "containers/FlushShowsButtonContainer";

const ReloadShowsRow = () => {
  return (
    <Grid className="ReloadShowsRow">
      <Row>
        <Col className="flush-controls col" xsHidden>
          <ShowCacheTimeDisplayContainer/>
          <FlushShowsButtonContainer text="Reload"/>
        </Col>
        <Col className="col" lgHidden mdHidden smHidden xs={10}>
          <ShowCacheTimeDisplayContainer/>
        </Col>
        <Col className="col" lgHidden mdHidden smHidden xs={2}>
          <FlushShowsButtonContainer text="Reload"/>
        </Col>
      </Row>
    </Grid>
  )
};

ReloadShowsRow.propTypes = {
};

ReloadShowsRow.defaultProps = {
};

export default ReloadShowsRow;
