import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Row, Col, Button} from 'react-bootstrap';
import Link from 'components/Link';

import './SubNavBar.css';

const SubNavBar = ({forumUrl}) => (
  <Grid fluid className="SubNavBar">
    <Row className="navigation-row">
      <Col sm={2} md={2} lg={2} className="text-center hidden-xs">
        <Link url="/shows">
          <Button>Setlists</Button>
        </Link>
      </Col>
      <Col xs={3} sm={2} md={2} lg={2} className="text-center">
        <Link url="/shows/?recordings=yes">
          <Button>Recordings</Button>
        </Link>
      </Col>
      <Col xs={6} sm={4} md={4} lg={4} className="text-center">
        <h2>Latest Show</h2>
      </Col>
      <Col sm={2} md={2} lg={2} className="text-center hidden-xs">
        <Link url="/songs">
          <Button>Songs</Button>
        </Link>
      </Col>
      <Col xs={3} sm={2} md={2} lg={2} className="text-center">
        <Link url={forumUrl}>
          <Button>Forum</Button>
        </Link>
      </Col>
    </Row>
  </Grid>
);

SubNavBar.propTypes = {
  forumUrl: PropTypes.string
};

SubNavBar.defaultProps = {
  forumUrl: "https://forum.spaffnerds.com"
};

export default SubNavBar;
