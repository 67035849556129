import React from 'react';
import PropTypes from 'prop-types';
import tracker from 'lib/tracker';

import { Link as ReactLink } from 'react-router-dom';

const Link = ({url, children, tracking, onClick, ...props}) => {
  const trackClick = (e) => {
    if (tracking){
      const {label, action, category} = tracking;
      tracker.trackEvent(category, action, label);
    }
    if (onClick){
      onClick(e);
    }
  };

  if (url && url.startsWith('http')){
    return (
      <a href={url} {...props} onClick={e => trackClick(e)}>
        {children}
      </a>
    )
  } else {
    return (
      <ReactLink to={url} {...props} onClick={e => trackClick(e)}>
        {children}
      </ReactLink>
    )
  }
};

Link.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
  tracking: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string,
    category: PropTypes.string
  })
};

Link.defaultProps = {};

export default Link
