import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, Button, Radio } from 'react-bootstrap';
// import PropTypes from 'prop-types';

import './ShowBulkUploadView.css';

import JSONTree from 'react-json-tree';
import { JsonToTable } from 'react-json-to-table';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { readFileAndCallback } from "lib/fileUpload";
import './ShowBulkUploadView.css';
import DetailNavRow from "components/DetailView/DetailNavRow";
import DetailSlugRow from "components/DetailView/DetailSlugRow";

import Link from "components/Link";
import {makeShowLink} from "lib/shows";
import HowTo from "components/ShowBulkUploadView/HowTo";

const treeTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e', // strings
  base0C: '#a1efe4',
  base0D: '#66d9ef', // keys
  base0E: '#ae81ff',
  base0F: '#cc6633'
};

class ShowBulkUploadView extends Component {
  constructor(props){
    super(props);
    this.handleFileChange= this.handleFileChange.bind(this);
    this.handleUploadButton= this.handleUploadButton.bind(this);
    this.state = {
      display: "table"
    }
  }

  handleFileChange(e) {
    //TODO: error checking?
    const { onJsonLoaded }  = this.props;
    const fileName = e.target.files[0];
    readFileAndCallback(fileName, onJsonLoaded)

  }

  handleUploadButton(showBatch) {
    const { onUpload } = this.props;
    confirmAlert({
      title: 'Confirm Upload',
      message: 'Are you sure to Upload this batch? This will make these shows available to the public.  There is no undo.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onUpload(showBatch)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  render(){
    const { filename, showBatch, onClear, isUploading } = this.props;
    const { display } = this.state;
    return (
      <Grid className="ShowBulkUploadView">
        <DetailNavRow socialSharing={false}>Bulk Upload</DetailNavRow>
        <Row>
          <Col mdOffset={3} md={6}>
            <HowTo/>
          </Col>
        </Row>
        { !showBatch &&
        <Row className="ButtonRow">
          <Col className="text-center">
            <Button type="button" onClick={() => this.fileInput.click()}>Choose File</Button>
            <input type="file" id="file"
                   className="input-file" accept=".csv"
                   onChange={this.handleFileChange}
                   ref={ref => this.fileInput = ref}
                   style={{display: 'none'}}
            />
          </Col>
        </Row>
        }
        { showBatch &&
        <Row className="ButtonRow">
          <Col md={6} className="text-center">
            <Button type="button" onClick={onClear}>Reset</Button>
          </Col>
          <Col md={6} className="text-center">
            <Button type="button" className={isUploading ? 'disabled' : ""} onClick={() => !isUploading && this.handleUploadButton(showBatch)}>Upload Shows</Button>
          </Col>
        </Row>
        }
        <DetailSlugRow>{filename}</DetailSlugRow>
        {showBatch &&
        <Row>
          <Col className="h4 text-center">
            <FormGroup>
              <Radio name="radioGroup" checked={this.state.display === "table"} inline onChange={() => this.setState({display: "table"})}>
                Table View
              </Radio>
              <Radio name="radioGroup" checked={this.state.display === "tree"} inline onChange={() => this.setState({display: "tree"})}>
                Tree View
              </Radio>
            </FormGroup>
          </Col>
        </Row>
        }
        <Row>
          { display === "tree" &&
          <Col sm={12} md={8} mdOffset={2} className="BatchTree">
            {showBatch &&
            <JSONTree hideRoot={true} theme={treeTheme} data={showBatch}
                      shouldExpandNode={(k, d, l) => ['venue'].includes(k[0]) || l < 2}
            />
            }
          </Col>
          }
          { display === "table" &&
          <Col sm={12} md={8} mdOffset={2} className="BatchTable">
            { showBatch && showBatch.map((show, index)=> {
              return (
                <Row key={index}>
                  <Col>
                    <h2><Link url={makeShowLink(show.date, show.multiShowIndex)}>{show.date.substr(0,10)} - {show.venue.name}</Link></h2>
                    <JsonToTable json={show}/>
                  </Col>
                </Row>
              )})
            }
          </Col>
          }
        </Row>
      </Grid>
    )
  }
}

ShowBulkUploadView.propTypes = {};

ShowBulkUploadView.defaultProps = {};

export default ShowBulkUploadView;
