import React from 'react';
import PropTypes from 'prop-types';

import RecordingFilter from 'components/ShowListView/RecordingFilter';

const RecordingFilterContainer = ({onSelect, filter}) => (
  <RecordingFilter onSelect={onSelect} filter={filter}/>
);

RecordingFilterContainer.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string
};

RecordingFilterContainer.defaultProps = {};

export default RecordingFilterContainer;
