import React from 'react';
import PropTypes from 'prop-types';

// import the link component, not the index, so that we can wrap it
import Link from 'components/Link/Link';

const LinkContainer = ({isMediaPlaying, url, ...props}) => {
  return (
    isMediaPlaying && url && url.startsWith('http')
      ? <Link {...props} url={url} target="_blank" />
      : <Link {...props} url={url} />
  );
};

LinkContainer.propTypes = {
  isMediaPlaying: PropTypes.bool
};

LinkContainer.defaultProps = {
  isMediaPlaying: false
};

export default LinkContainer;
