import React, { useEffect } from 'react';
import { Grid } from 'react-bootstrap';
import LeaderBoard from '../LeaderBoard';
import PlayHeader from './PlayHeader';
import Loading from 'components/Loading';
import useTourGames from 'hooks/useTourGames';
import useUser from 'hooks/useUser';
import { TEST_TOUR_GAMES_ID } from '../../constants';
import './TourGamesWelcomeView.css';
import { useHistory } from 'react-router-dom';

const TourGamesWelcomeView = () => {
  const {
    ticket,
    isCreateError,
    isFetching,
    currentGameShow,
    handleError,
    getTicket,
    getLeaderboard
  } = useTourGames();
  const { isUserLoggedIn, userId } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (ticket) {
      getLeaderboard(TEST_TOUR_GAMES_ID, 'SpaffnerdsTourGames', true);
      history.push(`/tourgames/${currentGameShow.showId}`);
    }
    // eslint-disable-next-line
  }, [ticket]);

  useEffect(() => {
    if (isUserLoggedIn && !ticket) {
      getTicket(userId, TEST_TOUR_GAMES_ID);
    }
    // eslint-disable-next-line
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (isCreateError) {
      handleError('There was an error creating your ticket.');
    }
    // eslint-disable-next-line
  }, [isCreateError]);

  if (isFetching) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <Grid className='TourGamesWelcomeView'>
      <PlayHeader />
      <LeaderBoard />
    </Grid>
  );
};

export default TourGamesWelcomeView;
