import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoadingSongDetailView from "components/SongDetailView/LoadingSongDetailView";
import SongDetailView from 'components/SongDetailView';
import ErrorView from "components/ErrorView/ErrorView";

class SongDetailViewContainer extends Component {
  constructor(props){
    super(props);
    let id = this.props.match && this.props.match.params && this.props.match.params.id;
    const { selectedSongId } = this.props;
    if (id !== selectedSongId){
      this.props.onIdChange && this.props.onIdChange(id);
    }
  }
  componentWillReceiveProps(nextProps){
    let oldId = this.props.match && this.props.match.params && this.props.match.params.id;
    let newId = nextProps.match && nextProps.match.params && nextProps.match.params.id;
    if (oldId !== newId){
      this.props.onIdChange && this.props.onIdChange(newId);
    }
  }
  render(){
    const { songStat, songStatError, next, prev, ...props } = this.props;
    if (!songStat && !songStatError){
      return <LoadingSongDetailView />
    } else if ( songStatError ){
      const { error, status, responseType, url } = songStatError;
      return (
        <ErrorView
          status={status}
          message={error.message}
          stack={error.stack}
          responseType={responseType}
          url={url}
        />
      )

    } else {
      return <SongDetailView
        song={songStat}
        next={next}
        prev={prev}
        {...props}/>
    }
  }
}

SongDetailViewContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  selectedSong: PropTypes.string,
  songStat: PropTypes.object,
  next: PropTypes.object,
  prev: PropTypes.object
};

SongDetailViewContainer.defaultProps = {};

export default SongDetailViewContainer;
