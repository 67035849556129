import React from 'react';
import PropTypes from 'prop-types';

import AttendanceButton from 'components/ShowDetailView/AttendanceListRow/AttendanceListHeading/AttendanceButton';
import AttendanceButtonLoggedOut from "components/ShowDetailView/AttendanceListRow/AttendanceListHeading/AttendanceButtonLoggedOut";

import tracker from 'lib/tracker';

const AttendanceButtonContainer = ({didAttend, past,
                                     userId, showId, showDate,
                                     isError, isDisabled, isSuccess,
                                     onClick, onError, onSuccess,
                                     ...props }) => {

  if (isError) {
    onError(`Failed to ${didAttend ? 'remove' : 'add'} ${showDate} ${didAttend ? 'from' : 'to'} your shows, please try again.`)
  }
  if (isSuccess) {
    onSuccess(userId, showId, `${didAttend ? 'Added' : 'Removed'} ${showDate} ${didAttend ? 'to' : 'from'} your shows.`)
  }

  if (! userId) {
    return (
      <AttendanceButtonLoggedOut
        showId={showId}
        showDate={showDate}
      />
    )
  } else {
    return (
      <AttendanceButton
        onClick={() => {
          tracker.trackEvent(`Show: ${showDate}`, didAttend ? `remove show: ${showId}` : `add show: ${showId}`, userId);
          onClick(userId, showId, ! didAttend)
        }}
        disabled={isError || isDisabled}
        active={didAttend}
        past={past}
        {...props}
      />
    )
  }
};

AttendanceButtonContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  didAttend: PropTypes.bool.isRequired,
  past: PropTypes.bool.isRequired,
  showId: PropTypes.string.isRequired,
  showDate: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSuccess: PropTypes.bool,
  userId: PropTypes.string
};

AttendanceButtonContainer.defaultProps = {
};

export default AttendanceButtonContainer;
