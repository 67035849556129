import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Button } from 'react-bootstrap';
import Link from 'components/Link';
import GetTicketsContainer from "containers/GetTicketsContainer";

import './NoSetlistContentRow.css';
import AddRecordingButtonContainer from "containers/AddRecordingButtonContainer";

const NoSetlistContentRow = ({showDate, tickets, username}) => {

  let nowdate = new Date().toISOString().substr(0,10);

  let reviewLink = "https://forum.spaffnerds.com/topic/129/howto-volunteer-to-write-a-review";
  let meetupLink = "https://forum.spaffnerds.com/topic/128/howto-starting-a-meetup";
  let addSetlistLink = "https://forum.spaffnerds.com/topic/131/howto-add-or-update-a-setlist";

  if (nowdate > showDate) {
    return (
      <Row className="NoSetlistContentRow">
        <Col xs={12}>
          <h3>No Setlist Available</h3>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <Link url={addSetlistLink}
                target="_blank"
                tracking={
                  {
                    category: `Show: ${showDate}`,
                    action: 'Add Setlist',
                    label: username
                  }
                }
          >
            <Button className="active"><i className="fa fa-list-ol"/><span>Add Setlist</span></Button>
          </Link>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <AddRecordingButtonContainer type="past" />
        </Col>
      </Row>
    )
  } else if (nowdate === showDate) {
    return (
      <Row className="NoSetlistContentRow">
        <Col xs={12}>
          <h3>This is today's show!</h3>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <GetTicketsContainer/>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <AddRecordingButtonContainer type="present" />
        </Col>
        <Col xs={12} md={4} className="text-center">
          <Link url={addSetlistLink}
                target="_blank"
                tracking={
                  {
                    category: `Show: ${showDate}`,
                    action: 'Add Setlist',
                    label: username
                  }
                }
          >
            <Button className="active"><i className="fa fa-list-ol"/><span>Add Setlist</span></Button>
          </Link>
        </Col>
      </Row>
    )
  } else {
    // future
    return (
      <Row className="NoSetlistContentRow">
        <Col xs={12}>
          <h3>This show hasn't happened yet</h3>
        </Col>
        <Col xs={12} md={3} className="text-center">
          <GetTicketsContainer/>
        </Col>
        <Col xs={12} md={3} className="text-center">
          <Link url={meetupLink}
                target="_blank"
                tracking={
                  {
                    category: `Show: ${showDate}`,
                    action: 'Add Recording',
                    label: username
                  }
                }
          >
            <Button className="active"><i className="fa fa-users"/><span>Start A Meetup</span></Button>
          </Link>
        </Col>
        <Col xs={12} md={3} className="text-center">
          <Link url={reviewLink}
                target="_blank"
                tracking={
                  {
                    category: `Show: ${showDate}`,
                    action: 'Add Recording',
                    label: username
                  }
                }
          >
            <Button className="active"><i className="fa fa-pencil-square-o"/><span>Volunteer To Review</span></Button>
          </Link>
        </Col>
        <Col xs={12} md={3} className="text-center">
          <AddRecordingButtonContainer type="future"/>
        </Col>
      </Row>
    )
  }

};

NoSetlistContentRow.propTypes = {
  showDate: PropTypes.string,
  tickets: PropTypes.shape({
    url: PropTypes.string,
    availability: PropTypes.string,
    source: PropTypes.string
  }),
  username: PropTypes.string,
};

NoSetlistContentRow.defaultProps = {
  username: "unkonwn"
};

export default NoSetlistContentRow;
