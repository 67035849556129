import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Grid } from 'react-bootstrap';
import PropTypes from 'prop-types';
import sort from 'lib/sort';

import DetailNavRow from "components/DetailView/DetailNavRow";
import DetailSlugRow from "components/DetailView/DetailSlugRow";
import DetailContentRow from "../DetailView/DetailContentRow/DetailContentRow";
import ShowYearCard from "components/UserDetailView/ShowYearCard/ShowYearCard";
import UserSongStats from "components/UserDetailView/UserSongStats";

import { userDetailViewMetaData } from "lib/html/meta";

import './UserDetailView.css';

class UserDetailView extends Component {
  render(){
    const { username, userslug, showCount, showsByYear, songStats, forumUrl } = this.props;
    const metaData = userDetailViewMetaData(username);
    return (
      <Grid className="UserDetailView">
        <Helmet>
          <title>{ metaData.title }</title>
          <meta name="keywords" content={ metaData.keywords }/>
          <meta name="description" content={ metaData.description } />
        </Helmet>
        <DetailNavRow nextUrl={`${forumUrl}/users`}
                      nextText="All Users"
                      nextIcon={<i className="fa fa-group" />}
                      prevUrl={`${forumUrl}/user/${userslug}`}
                      prevText={`${username}'s Profile`}
                      prevIcon={<i className="fa fa-user" />}
        >
          {username}'s Stats
        </DetailNavRow>
        <DetailSlugRow>
          <span>{`${showCount} show${showCount !== 1 ? "s" : ""} attended`}</span>
        </DetailSlugRow>
        <DetailContentRow>
          {Object.keys(showsByYear).sort(sort.string.desc).map(year => {
            return <ShowYearCard key={year} year={year} shows={showsByYear[year]} count={showsByYear[year].length} />
          })}
        </DetailContentRow>
        <DetailContentRow>
          <div>
            <UserSongStats songStats={songStats}/>
          </div>
        </DetailContentRow>
        <div className="footer-padding" />
      </Grid>
    )
  }
}

UserDetailView.propTypes = {
  username: PropTypes.string.isRequired,
  userslug: PropTypes.string.isRequired,
  showCount: PropTypes.number.isRequired,
  showsByYear: PropTypes.object.isRequired,
  songStats: PropTypes.object.isRequired,
  forumUrl: PropTypes.string
};

UserDetailView.defaultProps = {
  forumUrl: "https://forum.spaffnerds.com/"
};

export default UserDetailView;
