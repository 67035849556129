import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Grid } from 'react-bootstrap';

import PropTypes from 'prop-types';

import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';

import CoverOrOriginalRow from './CoverOrOriginalRow';
import StatLabel from '../StatLabel';
import SongStatsRow from './SongStatsRow';
import PlayedDatesRow from './PlayedDatesRow';
import PlayedAtRow from './PlayedAtRow';
import LyricsRow from './LyricsRow';

import { songDetailViewMetaData } from "lib/html/meta";

import './SongDetailView.css';

class SongDetailView extends Component {
  makeSongUrl(songId){
    return `/songs/${songId}`;
  }
  render(){
    const {song, next, prev} = this.props;
    const metaData = songDetailViewMetaData({
      name: song.name,
      isJam: song.isJam,
      writtenBy: {
        band: song.band
      }
    });

    return (
      <Grid className="SongDetailView">
        <Helmet>
          <title>{ metaData.title }</title>
          <meta name="keywords" content={ metaData.keywords }/>
          <meta name="description" content={ metaData.description } />
        </Helmet>
        <DetailNavRow nextUrl={next && this.makeSongUrl(next._id)}
                      nextText={next && next.name}
                      prevUrl={prev && this.makeSongUrl(prev._id)}
                      prevText={prev && prev.name}
                      placement="song"
        >
          <span>{song && song.name}</span>
        </DetailNavRow>
        <CoverOrOriginalRow band={song.band} cover={song.band !== 'Spafford'}/>
        <DetailSlugRow>
          <StatLabel leadText="Played At" count={song.playStats.playCount}/>
        </DetailSlugRow>
        <SongStatsRow songStats={song}/>
        <PlayedDatesRow firstPlayedDate={song.firstShowPlayed}
                        lastPlayedDate={song.lastShowPlayed}
                        hasLyrics={song.lyrics ? true : false}
        />
        <PlayedAtRow showList={song.playStats.showList} />
        <LyricsRow lyrics={song.lyrics} />
      </Grid>
    )
  }
}

SongDetailView.propTypes = {
  song: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    band: PropTypes.string,
    isJam: PropTypes.bool,
    lyrics: PropTypes.string,
    firstShowPlayed: PropTypes.string,
    lastShowPlayed: PropTypes.string,
    playStats: PropTypes.shape({
      playedCount: PropTypes.number,
      showList: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        date: PropTypes.string.isRequired,
        recordings: PropTypes.array,
        recordingCount: PropTypes.number,
        guests: PropTypes.array,
        songNotes: PropTypes.array,
        showNotes: PropTypes.object,
        venue: PropTypes.shape({
          name: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          country: PropTypes.string
        }).isRequired
      }))
    })
  }),
  next: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  }),
  prev: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  })
};

SongDetailView.defaultProps = {
};

export default SongDetailView;
