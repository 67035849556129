//TODO: Add this reducer to the combineReducers call in src/reducers/index.js then remove this comment
import {
  USERSHOWTOGGLE_REQUEST,
  USERSHOWTOGGLE_SUCCESS,
  USERSHOWTOGGLE_FAILURE,
  USERSHOWTOGGLE_INVALIDATE,
  USERSHOWTOGGLE_DISABLE,
  USERSHOWTOGGLE_ENABLE
} from 'actions/userShowToggle'

function initialUserShowToggleState(){
  return {
    isFetching: false,
    isError: false,
    didInvalidate: false,
    disabled: false,
    data: {}
  }
}

function userShowToggle(state = initialUserShowToggleState(), action) {
  switch (action.type) {
   case USERSHOWTOGGLE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case USERSHOWTOGGLE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        data: action.response,
        lastUpdate: action.receivedAt
      });
    case USERSHOWTOGGLE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        disabled: true,
        didInvalidate: false
      });
    case USERSHOWTOGGLE_INVALIDATE:
      return {
        isFetching: false,
        isError: false,
        disabled: state.disabled,
        didInvalidate: true
      };
    case USERSHOWTOGGLE_DISABLE:
      return Object.assign({}, state, {
        disabled: true,
      });
     case USERSHOWTOGGLE_ENABLE:
      return Object.assign({}, state, {
        disabled: false,
      });
    default:
      return state;
  }
}

export default userShowToggle;