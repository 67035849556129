import React from 'react';
import { Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import './Notes.css';

const Notes = ({flags}) => {
  let formatFlag = function(flag){
    if (Array.isArray(flag)){
      let formatted = [];
      flag.forEach((flag, i, multiFlag) => {
        formatted.push(flag);
        if (i !== multiFlag.length -1){
          formatted.push(", ");
        }
      });
      return formatted;
    } else {
      return flag;
    }
  };
  return <Row className="Notes">
    {
      flags.map((flag, n) => {
        return <Col xs={12} key={`${flag}_${n}`}>[ {n + 1} ]: {formatFlag(flag)}</Col>
      })
    }
  </Row>
};

Notes.propTypes = {
  flags: PropTypes.array
};

Notes.defaultProps = {
  flags: []
};

export default Notes;
