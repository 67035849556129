import React from 'react';
// import PropTypes from 'prop-types';

import ClientSideButton from 'components/ClientSideButton';
import LoadingIcon from "components/LoadingIcon/LoadingIcon";

/* EXTRACT */

import archiveIcon from 'images/recordingicons/archive.png';

const textFn = (recordingType, publisher) => {
  switch(recordingType) {
    case "Aud":
      return "Aud: " + publisher;
    case "Soundboard":
      return "Soundboard";
    default:
      return "Other"
  }
};

const ArchiveRecordingButton = ({recording, date, url, children, ...props}) => {
  const recordingType = recording.recordingType;
  const publisher = recording.recordingData.publisher;
  return (
    <ClientSideButton
      className="RecordingButton"
      img={archiveIcon}
      alt="Archive"
      text={textFn(recordingType, publisher)}
      tracking={{category: `Show: ${date}`, action: 'Listen', label: url}}
      {...props}
    >
      { children }
    </ClientSideButton>
  )
};
/* EXTRACT */

const ArchiveRecordingButtonContainer = ({ recording,
                                           show, tracks, isPlaying,
                                           fnFetchRecordingData, fnSetRecording,
                                           isFetching,
                                           ...props} ) => {

  if ( isPlaying && tracks ) {
    return (
      <ArchiveRecordingButton
        {...props}
        recording={recording}
        onClick={() => { return false; }}
        children={
          <i className={`play-status fa ${isPlaying ? 'fa-stop' : 'fa-play'}`} />
        }
      />
    );
  }

  // no tracks, but fetching, so show loading stuff
  if ( isPlaying && isFetching ) {
    return (
      <ArchiveRecordingButton
        {...props}
        recording={recording}
        onClick={() => { return false; }}
        children={
          <LoadingIcon size={30} />
        }
      />
    );
  }

  // no tracks, not fetching, show ready to play but onclick is for loading
  const { _id, venue } = show;
  return (
    <ArchiveRecordingButton
      {...props}
      recording={recording}
      onClick={() => {
        fnSetRecording(recording, venue);
        fnFetchRecordingData(_id);
        return false;
      }}
      children={
        <i className={`play-status fa ${isPlaying ? 'fa-stop' : 'fa-play'}`} />
      }
    />
  );

};

ArchiveRecordingButtonContainer.propTypes = {
};

ArchiveRecordingButtonContainer.defaultProps = {
};

export default ArchiveRecordingButtonContainer;
