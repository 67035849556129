import ShowDetailViewContainer from './ShowDetailViewContainer';
import { connect } from 'react-redux';

import { getUsername, getUserRolesINSECURE } from "selectors/user";
import {
  getVisibleShow,
  getSelectedShowId,
  getNextVisibleShow,
  getPrevVisibleShow,
  isVisibleShowLive,
  isVisibleShowRecent,
  isVisibleShowInPast,
  isVisibleShowFetching,
  getVisibleShowError
} from "selectors/shows";

import { setVisibleShow, fetchShow } from 'actions/shows';
import {getAddRecordings, isStreamOnly} from "selectors/recordings";
import { clearAddRecording } from "actions/recordings";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onIdChange: (id, index) => {
      const idx = index || 0;
      dispatch(clearAddRecording());
      dispatch(setVisibleShow(id, idx));
    },
    onNoVisibleShow: (id) => dispatch(fetchShow(id))
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: isVisibleShowFetching(state),
    selectedShowId: getSelectedShowId(state),
    visibleShow: getVisibleShow(state),
    nextShow: getNextVisibleShow(state),
    prevShow: getPrevVisibleShow(state),
    username: getUsername(state),
    addRecording: getAddRecordings(state),
    streamOnly: isStreamOnly(state),
    isShowLive: isVisibleShowLive(state),
    isShowRecent: isVisibleShowRecent(state),
    isPastShow: isVisibleShowInPast(state),
    userRolesInsecure: getUserRolesINSECURE(state),
    visibleShowError: getVisibleShowError(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowDetailViewContainer)
