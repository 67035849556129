import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import RecordingButton from 'components/ShowDetailView/AvailableRecordingsRow/RecordingButton';

class RecordingButtonContainer extends Component {
  render() {
    const {activeRecording, type, recording, date, venue, setRecording} = this.props;

    return (
      <RecordingButton
        isPlaying={this.compareRecordings(activeRecording, recording)}
        type={type}
        recording={recording}
        date={date}
        clickHandler={() => setRecording(recording, venue)}/>
    );
  }

  compareRecordings(a, b) {
    if (! a || ! b) {
      return false;
    }
    // we need to remove the 'venue' that we added so that we can make a 1:1 comparison
    // but we've got to copy so that we don't do destructive things
    const a1 = Object.assign({}, a);
    delete a1.venue;
    return _.isEqual(a1,b);
  }
};

RecordingButtonContainer.propTypes = {
  type: PropTypes.oneOf([
    "Archive", "Audio", "Mixlr",
    "nugs.net", "SoundCloud", "Video",
    "YouTube"]).isRequired,
  recording: PropTypes.shape({
    recordingType: PropTypes.oneOf(['Soundboard', 'Aud', 'Video', 'Audio']),
    recordingData: PropTypes.shape({
      data: PropTypes.string,
      description: PropTypes.string,
      publisher: PropTypes.string,
      username: PropTypes.string,
      pageUrl: PropTypes.string,
      url: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string
      ])
    })
  }).isRequired,
  date: PropTypes.string.isRequired,
  clickCallBack: PropTypes.func,
  activeRecording: PropTypes.shape({})
};

RecordingButtonContainer.defaultProps = {};

export default RecordingButtonContainer;
