import UserDetailViewContainer from './UserDetailViewContainer';
import { connect } from 'react-redux';
import { fetchUserStats } from "actions/userStats";
import {
  getUserStats,
  getUsername,
  getUserSlug,
  getShowCount,
  getShowsByYear,
  getSongStats,
  getUserStatsError
} from "selectors/userStats";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      onLoadUserStats: () => {
        if (ownProps.match.params.id) {
          dispatch(fetchUserStats(ownProps.match.params.id))
        }
      }
  };
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  return {
    id: id,
    userStats: getUserStats(state, id),
    userStatsError: getUserStatsError(state, id),
    username: getUsername(state, id),
    userslug: getUserSlug(state, id),
    showCount: getShowCount(state, id),
    showsByYear: getShowsByYear(state, id),
    songStats: getSongStats(state, id)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserDetailViewContainer);
