import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Panel, Row } from 'react-bootstrap';

import OpenButton from "components/MediaPlayer/MediaPlayerHeading/OpenButton";
import CloseButton from "components/MediaPlayer/MediaPlayerHeading/CloseButton";
import MediaPlayerTitleContainer from "containers/MediaPlayerContainer/MediaPlayerTitleContainer";
import ArchiveTrackHeadingContainer from "containers/MediaPlayerContainer/ArchiveTrackHeadingContainer";
import MPYouTubeTitleContainer from "containers/MediaPlayerContainer/MPYouTubeTitleContainer";

import './MediaPlayerHeading.css';

const MediaPlayerHeading = ({isOpen, closePlayer}) => {
  return (
    <Panel.Heading className="MediaPlayerHeading">
      <Panel.Title toggle>
        <OpenButton isOpen={isOpen}/>
        <CloseButton onClick={closePlayer}/>
        <Grid>
          <Row>
            <ArchiveTrackHeadingContainer className="hidden-sm hidden-md hidden-lg" />
            <MPYouTubeTitleContainer className="hidden-sm hidden-md hidden-lg" />
            <MediaPlayerTitleContainer />
          </Row>
        </Grid>
      </Panel.Title>
    </Panel.Heading>
  )
};

MediaPlayerHeading.propTypes = {
  isOpen: PropTypes.bool,
  closePlayer: PropTypes.func.isRequired
};

MediaPlayerHeading.defaultProps = {
};

export default MediaPlayerHeading;
