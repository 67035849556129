import React from 'react';
import Helmet from 'react-helmet';
import { Grid } from 'react-bootstrap';

import PropTypes from 'prop-types';

import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';
import DetailContentRow from 'components/DetailView/DetailContentRow';
import ShowListTable from 'components/ShowListTable';

import StatLabel from 'components/StatLabel';

import ShowListFiltersRow from './ShowListFiltersRow';

import { showListViewMetaData } from "lib/html/meta";
import './ShowListView.css';

const ShowListView = ({ showList, recordingsFilter }) => {
  const metaData = showListViewMetaData(recordingsFilter);
  return (
    <Grid className="ShowListView">
      <Helmet>
        <title>{ metaData.title }</title>
        <meta name="keywords" content={ metaData.keywords }/>
        <meta name="description" content={ metaData.description } />
      </Helmet>
      <DetailNavRow>
        <span>Spafford Setlists</span>
      </DetailNavRow>
      <DetailSlugRow>
        <StatLabel showColon={false} leadText="Showing" count={showList ? showList.length : 0}/>
      </DetailSlugRow>
      <ShowListFiltersRow />
      <DetailContentRow heading="Shows">
        <ShowListTable showList={showList} />
      </DetailContentRow>
    </Grid>
  );
};

ShowListView.propTypes = {
  showList: PropTypes.array,
  recordingsFilter: PropTypes.string
};

ShowListView.defaultProps = {};

export default ShowListView;
