import React from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share"

import tracker from 'lib/tracker';

import ClipboardShareButton from "../ClipboardShareButton"

import "./SharingMenu.css"

const SharingMenu = ({ url, user }) => {
  return (
    <div className="SharingMenu">
      <ClipboardShareButton
        url={url}
        beforeOnClick={() => tracker.trackEvent('Share: CB', url, user)}
      />
      <FacebookShareButton
        url={url}
        beforeOnClick={() => tracker.trackEvent('Share: FB', url, user)}
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        via="spaffnerds_com"
        beforeOnClick={() => tracker.trackEvent('Share: TW', url, user)}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
    </div>
  )
}

SharingMenu.propTypes = {
  url: PropTypes.string.isRequired
}

SharingMenu.defaultProps = {}

export default SharingMenu
