import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import auth from './auth';
import shows from './shows';
import songs from './songs';
import attendance from './attendance';
import user from './user';
import userStats from './userStats';
import userShowToggle from './userShowToggle';
import mediaPlayer from './mediaPlayer';
import recordings from './recordings';
import userRecordings from './userRecordings';
import admin from './admin';
import tourGames from './tourGames';
import tourGamesLeaderboard from './tourGamesLeaderboard';
import tourGamesAdmin from './tourGamesAdmin';

const showsPersistConfig = {
  key: 'shows',
  storage: storage,
  whitelist: ['list']
};

const rootReducer = combineReducers({
  auth,
  shows: persistReducer(showsPersistConfig, shows),
  songs,
  attendance,
  user,
  userStats,
  userShowToggle,
  mediaPlayer,
  recordings,
  userRecordings,
  admin,
  tourGames,
  tourGamesLeaderboard,
  tourGamesAdmin
});

export default rootReducer;
