import { API_GET } from "middleware/api/get";
import { getUserStats } from 'selectors/userStats'

export const USERSTATS_REQUEST = 'USERSTATS_REQUEST';
export const USERSTATS_SUCCESS = 'USERSTATS_SUCCESS';
export const USERSTATS_FAILURE = 'USERSTATS_FAILURE';
export const USERSTATS_INVALIDATE = 'USERSTATS_INVALIDATE';

// Uses the API middleware to get a quote
//  includes boolean shouldDispatch which returns false if the dispatch shouldn't fire (for cached data use)
export function fetchUserStats(id) {
  return {
    [API_GET]: {
      id: id,
      endpoint: `/stats/users/${id}`,
      shouldDispatch: function(store) {
        const stats = getUserStats(store.getState(), id);
        if (! stats ) {
          return true;
        } else if (stats.isFetching) {
          return false;
        } else {
          return stats.didInvalidate;
        }
      },
      types: [USERSTATS_REQUEST, USERSTATS_SUCCESS, USERSTATS_FAILURE]
    }
  }
}

export function invalidateUserStats(id){
  return {
    type: USERSTATS_INVALIDATE,
    id: id
  }
}
