import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import DateBadges from './DateBadges';
import './ShowListTable.css';

const removeNonLiveStreams = (show) => {
  const nowString = new Date().toISOString();
  // if the show isn't happening now
  if (
    ! show.recordings ||
    (show.startTime <= nowString && show.endTime > nowString)
  ){
    // no changes
    return show;
  }

  const changes = {};
  changes.recordings = show.recordings.filter(rec => rec.recordingData.content !== "stream");
  changes.recordingCount = changes.recordings.length;
  return Object.assign({}, show, changes)
};

const ShowListTable = ({showList, noLinks}) => (
  <Table className="ShowListTable">
    <tbody>
    {showList.map((show) => {
      const {
        _id,
        date,
        multiShowIndex,
        showNotes,
        songNotes,
        recordingCount,
        guests,
        venue
      } = removeNonLiveStreams(show);
      return (
        <tr key={_id}>
          <td className="song-page">
            <DateBadges
              noLink={noLinks}
              date={date.substr(0,10)}
              multiShowIndex={multiShowIndex}
              songNotes={songNotes.join(", ")}
              showNotes={showNotes}
              recordingCount={recordingCount}
              guests={guests}
              venue={venue}
            />
          </td>
          <td className="hidden-xs hidden-sm venue-name">{venue.name}</td>
          <td className="hidden-xs">{venue.city}</td>
          <td className="hidden-xs">{venue.state}</td>
        </tr>
      )
    })}
    </tbody>
  </Table>
);

ShowListTable.propTypes = {
  noLinks: PropTypes.bool,
  showList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.string.isRequired,
    recordings: PropTypes.array,
    recordingCount: PropTypes.number,
    guests: PropTypes.array,
    songNotes: PropTypes.array,
    showNotes: PropTypes.object,
    venue: PropTypes.shape({
      name: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string
    }).isRequired
  }))
};

ShowListTable.defaultProps = {};

export default ShowListTable;
