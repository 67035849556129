import { ROLES } from '../../constants';

export const isLoggedIn = (state) => {
  return (
    (state.user.profile && state.user.profile.username) || 
    state?.user?.cognitoUser?.attributes?.preferred_username || 
    state?.user?.cognitoUser?.attributes?.email )
    ? true : false;
};

export const isAdmin = (state) => {
  return (
    state.user.profile &&
    state.user.profile.roles &&
    state.user.profile.roles.includes('admin')
  );
};

export const isTourGamesBeta = (state) => {
  return (
    state.user.profile &&
    state.user.profile.roles &&
    state.user.profile.roles.includes(ROLES.TG_BETA)
  );
};

export const getLoginPollInterval = (state) => {
  //TODO: Make the poll interval part of state and allow setting it to 0 to turn off auto login
  // The idea is that eventually we'd offer a user control panel where
  // the user can change some of the react app's settings.
  return 3000; // 3 seconds
};

export const getIconBgColor = (state) => {
  if (!isLoggedIn(state)) {
    return 'grey';
  }
  return state.user.profile.iconBgColor;
};

export const getIconText = (state) => {
  if (!isLoggedIn(state)) {
    return '';
  }
  return state.user.profile.iconText;
};

export const getPictureUrl = (state) => {
  if (!isLoggedIn(state)) {
    return '';
  }
  return state.user.profile.picture;
};

export const getUsername = (state) => {
  if (!isLoggedIn(state)) {
    return null;
  }
  return state.user.profile?.attributes?.preferred_username ?? state.user.profile.username ?? state?.user?.cognitoUser?.attributes?.preferred_username;
};

export const getUserslug = (state) => {
  if (!isLoggedIn(state)) {
    return '';
  }
  return state.user.profile.userslug ?? state?.user?.cognitoUser?.attributes?.preferred_username;
};

export const didUserAttendShow = (state, showId) => {
  if (!isLoggedIn(state)) {
    return false;
  }

  return (
    state.user.profile.shows &&
    !!state.user.profile.shows.find((show) => show.showId === showId)
  );
};

export const getUserRolesINSECURE = (state) => {
  return state?.user?.profile?.roles ?? [];
};

/**
 * Gets the user profile from redux
 * @param {Object} state
 * @returns userProfile | undefined
 */
export const getUserProfile = (state) => state?.user?.profile;

export const getUserId = (state) => state?.user?.profile?._id;

export const getCognitoUser = (state) => state?.user?.cognitoUser
