import React from 'react';
import PropTypes from 'prop-types';

import { DropdownButton } from 'react-bootstrap';

import './StatsDropdownButton.css';

const StatsDropdownButton = ({ count, text, children }) => (
  <span className="StatsDropdownButton">
    <DropdownButton className="stat btn-block" title={
      <span className="btn-stat">
        <span className="stat-num">{count}</span>
        <span className="stat-name">{text}</span>
      </span>
    }>
      {children}
    </DropdownButton>
  </span>
);

StatsDropdownButton.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node
};

StatsDropdownButton.defaultProps = {
};

export default StatsDropdownButton;
