import React, { useState } from 'react'
import { tourGamesPicksFormHelper as helper } from '../tourGamesPicksFormHelper'
import { ControlLabel, FormControl, Radio } from 'react-bootstrap'
import { picksSelectorHelper } from './picksSelectorHelper'

const PicksSelector = ({ label, name, songs, onChange, pick }) => {
  const [pickType, setPickType] = useState(
    picksSelectorHelper.getDefaultPickTypeState(pick, songs)
  )

  return (
    <>
      <ControlLabel>{label}</ControlLabel>
      <div>
        <Radio
          name={`radioGroup-${name}`}
          value={'original'}
          onClick={e => setPickType(e.target.value)}
          inline
          checked={pickType === 'original'}
        >
          Original
        </Radio>{' '}
        <Radio
          name={`radioGroup-${name}`}
          value={'cover'}
          onClick={e => setPickType(e.target.value)}
          checked={pickType === 'cover'}
          inline
        >
          Cover
        </Radio>{' '}
        <Radio
          name={`radioGroup-${name}`}
          value={'other'}
          onClick={e => setPickType(e.target.value)}
          checked={pickType === 'other'}
          inline
        >
          Other
        </Radio>
      </div>
      <FormControl
        componentClass='select'
        placeholder='select'
        onChange={e => onChange(helper.getSongForPick(e.target.value, songs))}
        value={pick?._id}
      >
        {helper.getFirstSetOptions(songs, pickType)}
      </FormControl>
    </>
  )
}

export default PicksSelector
