import React from 'react';
import PropTypes from 'prop-types';

import './CacheTimeDisplay.css';

const CacheTimeDisplay = ({dataPoint, lastUpdateTime, locale, tz}) => {
  return (
    <div className="CacheTimeDisplay">
      {dataPoint} updated: {new Date(lastUpdateTime).toLocaleString(locale, tz)}
    </div>
  )
};

CacheTimeDisplay.propTypes = {
  dataPoint: PropTypes.string.isRequired,
  lastUpdateTime: PropTypes.number.isRequired,
  locale: PropTypes.string,
  tz: PropTypes.object
};

CacheTimeDisplay.defaultProps = {
};

export default CacheTimeDisplay;
