import React, {Component} from 'react';
import PropTypes from 'prop-types';

import MediaPlayer from 'components/MediaPlayer';

class MediaPlayerContainer extends Component {
  render() {
    const {recording, tracks, source, clearRecording, ...props} = this.props;
    if (! recording || ( source === "Archive" && ! tracks ) ) {
      return null
    }

    return (
      <MediaPlayer
        source={source}
        closePlayer={clearRecording}
        {...props}
      />
    );

  }
}

MediaPlayerContainer.propTypes = {
  recording: PropTypes.object,
  expanded: PropTypes.bool,
  clearRecording: PropTypes.func,
};

MediaPlayerContainer.defaultProps = {};

export default MediaPlayerContainer;
