import React from 'react';

import UserProfileContainer from 'containers/UserProfileContainer';
import NavigationLinks from 'components/Nav/NavigationLinks';

const NavigationLinksContainer = ({...props}) => {
  return (
    <UserProfileContainer>
      <NavigationLinks {...props} />
    </UserProfileContainer>
  );
};

export default NavigationLinksContainer;
