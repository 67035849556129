import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import './TrackListItem.css';

const TrackListItem = ({playTrack, track, isPlaying, active}) => {

  return (
    <Row className={`TrackListItem${active ? ' active' : ''}`}>
      <Col xs={12} sm={12} md={12} lg={12}>
        <button className="btnTrackPlayPause" onClick={() => playTrack(track)}>
          <span className="trackTitle">{track.title}</span>
          <span className="trackLength">{track.length}</span>
        </button>
      </Col>
    </Row>
  )
};

TrackListItem.propTypes = {
  active: PropTypes.bool,
  playTrack: PropTypes.func,
  track: PropTypes.shape({}),
  isPlaying: PropTypes.bool
};

TrackListItem.defaultProps = {};

export default TrackListItem;
