import csv from 'csvtojson';

export const readFileAndCallback = (file, callback) => {
  const fileReader = new FileReader();
  fileReader.onloadend = (e) => {
    convertCsvToJson(file, fileReader.result, callback);
  };
  fileReader.readAsText(file);
};

export const convertCsvToJson = async (filename, csvContent, callback) => {
  const jsonContent = await csv().fromString(csvContent);
  callback(filename, transformUploadFileToShows(jsonContent))
};

export const transformUploadFileToShows = (uploadFileJson) => {
  if ( ! uploadFileJson || ! uploadFileJson.length ) {
    return null
  }

  return uploadFileJson.map(uploadShow => {
    const parseDate = (showDate, multiShowIndex) => {
      const dayOnly = new Date(showDate).toISOString().substr(0,10);
      const time =  `T00:00:00.00${uploadShow["MultiShowIndex"]}Z`;
      return dayOnly + time
    };
    const defaultStartTime = (showDate) => {
      const dayOnly = new Date(showDate).toISOString().substr(0,10);
      const time =  `T16:00:00.000Z`;
      return dayOnly + time
    };
    const defaultEndTime = (showDate) => {
      const nextDay = new Date(showDate);
      nextDay.setDate(nextDay.getDate() + 1);
      const dayOnly = nextDay.toISOString().substr(0,10);
      const time =  `T15:59:00.000Z`;
      return dayOnly + time
    };

    const parseNotes = (notes) => {
      const notesArray = notes.split(";");
      const objNotes = {};
      notesArray.forEach(note => {
        const noteParts = note.split(":");
        if (noteParts.length === 2) {
          objNotes[noteParts[0].trim()] = noteParts[1].trim();
        }
      });
      return objNotes;
    };

    const spaffShow = {
      date: parseDate(uploadShow["ShowDate"], uploadShow["MultiShowIndex"]),
      multiShowIndex: parseInt(uploadShow["MultiShowIndex"], 10) || 0,
      startTime: (uploadShow["StartTime"] && new Date(uploadShow["StartTime"]).toISOString()) || defaultStartTime(uploadShow["ShowDate"]) ,
      endTime: (uploadShow["EndTime"] && new Date(uploadShow["EndTime"]).toISOString()) || defaultEndTime(uploadShow["ShowDate"]) ,
      venue: {
        name: uploadShow["VenueName"],
        city: uploadShow["VenueCity"],
        state: uploadShow["VenueState"],
        country: uploadShow["VenueCountry"] || "USA"
      },
      notes: parseNotes(uploadShow["ShowNotes"]),
      tickets: {
        "1970-01-01T00:00:00Z": {
            "availability" : "COMINGSOON",
            "url" : "",
            "source" : "UNKNOWN"
        }
      },
      guests: [],
      recordings: [],
      sets: []
    };
    if (uploadShow["Festival"]) {
      spaffShow.notes["Festival"] = uploadShow["Festival"]
    }
    if (uploadShow["SpecialGuest"]) {
      spaffShow.notes["Special Guest"] = uploadShow["SpecialGuest"]
    }
    if (uploadShow["Supporting"]) {
      spaffShow.notes["Supporting"] = uploadShow["Supporting"]
    }

    if (uploadShow["PresaleStartDate"]) {
      const presaleStartDate = new Date(uploadShow["PresaleStartDate"]).toISOString().replace(".000Z","Z");
      spaffShow.tickets[presaleStartDate] = {
        "url": uploadShow["PresaleURL"],
        "availability": "PRESALE",
        "source": "OTHER"
      };
      if (uploadShow["PresaleCode"]) {
        spaffShow.tickets[presaleStartDate].code = uploadShow["PresaleCode"]
      }
    }
    if (uploadShow["PresaleEndDate"]) {
      const presaleEndDate = new Date(uploadShow["PresaleEndDate"]).toISOString().replace(".000Z","Z");
      spaffShow.tickets[presaleEndDate] = {
        "url": "",
        "availability": "COMINGSOON",
        "source": "OTHER"
      }
    }
    if (uploadShow["OnsaleDate"]) {
      const onsaleDate = new Date(uploadShow["OnsaleDate"]).toISOString().replace(".000Z","Z");
      spaffShow.tickets[onsaleDate] = {
        "url": uploadShow["OnsaleURL"],
        "availability": "ONSALE",
        "source": "OTHER"
      }
    }
    return spaffShow;
  })

};

export const transformShowsForDryRun = (showBatch) => {
  return showBatch.map((show, index) => {
    const reactShow = Object.assign({}, show);
    reactShow.recordingCount = 0;
    reactShow.songNotes = [];
    reactShow.showNotes = reactShow.notes;
    reactShow._id = `dry-run-${index}`;
    return reactShow;
  })
};

/*
TODO:
  Then, wire up the bulk uploader so that it shows
   the loaded list of shows in a sane manner
  Pressing the button dispatches a post event
  The results are shown on the confirmation panel

  So it's basically:
  - choose file
  - show loaded csv without button press
  - allow for some kind of review / select process
  - button press posts to serverless api
  - result is shown in a confirmation panel w/link to shows page

  I'm not sure WHERE this code should live but

  Next steps are to build out the reducer/selector/actions
  things will take better shape after that.
 */


