export * from './createTicket';
export * from './fetchTicket';
export * from './updatePicks';
export * from './fetchNextShow';
export * from './fetchTour';

export const RESET_ERROR = 'RESET_ERROR';
export const TOUR_GAMES_INVALIDATE = 'TOUR_GAMES_INVALIDATE';

export const resetTourGamesError = () => {
  return {
    type: RESET_ERROR
  };
};

export const invalidateTourGames = () => {
  return {
    type: TOUR_GAMES_INVALIDATE
  };
};
