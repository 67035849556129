import React from 'react';

import './FlushShowsButton.css';
import {Button} from "react-bootstrap";
import {confirmAlert} from "react-confirm-alert";

const handleFlushButton = (onClick) => {
  confirmAlert({
    title: 'WARNING',
    message: `
    Flushing the show cache will refresh all show data.
    
    This is costly to the site and to your data plan.
    Consider using the Reload button on the show page instead to refresh a single show.
    Only use this if you know what you're doing.`,
    buttons: [
      {
        label: 'Ok',
        onClick: onClick
      },
      {
        label: 'Cancel',
      }
    ],
    customUI: ({ onClose }) => {
      return (
        <div className='flush-modal'>
          <h1>BIG SCARY WARNING</h1>
          <h3>This will force-reload all the shows.</h3>
          <p>
            Spaffnerds saves the show database in your browser to reduce costs.  The shows will update on their own over time.  Use this feature with caution and only when appropriate.</p>
          <h3>
            Please force-reload ONLY if you know what you're doing.  Otherwise press "Cancel".
          </h3>
          <div className="buttons">
          <Button className="btn-danger"
                  onClick={() => {
                    onClick();
                    onClose();
                  }}
          >
            Force-Reload
          </Button>
          <Button className="pull-right btn-success" onClick={onClose}>Cancel</Button>
          </div>
        </div>
      );
    }
  });
};

const FlushShowsButton = ({text, onClick, className, disabled}) => {
  return (
    <Button
      className={`FlushShowsButton btn-sm${className ? ` ${className}` : ""}`}
      onClick={disabled ? () => {} : () => handleFlushButton(onClick)}
      disabled={disabled}>{text}
    </Button>
  )
};

FlushShowsButton.propTypes = {
};

FlushShowsButton.defaultProps = {
};

export default FlushShowsButton;
