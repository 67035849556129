import  React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import DetailContentRow from 'components/DetailView/DetailContentRow';
import ContentButton from '../ContentButton';
import AttendanceListHeading from './AttendanceListHeading';

import './AttendanceListRow.css';
import atSign from 'images/at_sign.png'

const AttendanceListRow = ({attendance, past}) => {
  return (
    <div className="AttendanceListRow">
      <hr />
      <DetailContentRow heading={
        <AttendanceListHeading attendanceCount={attendance.length} past={past}/>
      }>
        {
          attendance.map((user, index) => (
            <Col lg={3} md={4} sm={6} xs={12} key={`${user.username}_${index}`}>
              <ContentButton img={atSign}
                             alt={user.username}
                             href={`/users/${user.username}`}
                             text={user.username}
              />
            </Col>
          ))
        }
      </DetailContentRow>
    </div>
  )
};

AttendanceListRow.propTypes = {
  past: PropTypes.bool,
  attendance: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string
  }))
};

AttendanceListRow.defaultProps = {
  past: false
};

export default AttendanceListRow;
