import AddRecordingButtonContainer from './AddRecordingButtonContainer';
import { connect } from 'react-redux';
import {setAddRecording} from "actions/recordings";
import {getUsername} from "selectors/user";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: (streamOnly) => {
      dispatch(setAddRecording(streamOnly))
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    userId: getUsername(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddRecordingButtonContainer);
