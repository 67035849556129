export const getYoutubePlaylistId = (url) => {
  const qsRegex = /[?&]list(=([^&#]*)|&|#|$)/;
  let results = qsRegex.exec(url);
  return results && results[2] ? results[2].replace(/\+/g, " ") : null;
};

export const getYoutubeEmbedUrl = (url) => {
  let playlistId = getYoutubePlaylistId(url);
  if (playlistId) {
    return `https://www.youtube.com/embed/videoseries?list=${playlistId}&hl=en_US&autoplay=1`;
  }
  return `https://www.youtube.com/embed/${url.split("/").pop()}?autoplay=1`;
};
