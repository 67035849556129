import React from 'react';
import CircleButton from "components/CircleButton";

import './Icon.css';

const Icon = ({className, faIconClass, text, onClick, disabled}) => {
  return (
    <span className={className ? `${className} Icon` : "Icon"}>
      <CircleButton className={`hidden-xs hidden-sm${disabled ? " disabled" : ""}`}
                    disabled={disabled}
                    onClick={onClick}>
        <i className={`fa ${faIconClass}`} />
      </CircleButton>
      <button type="button" className={`btn btn-default hidden-lg hidden-md`}
              disabled={disabled}
              onClick={onClick}>
        {text ? `${text} ` : ""}<i className={`fa ${faIconClass}`} />
      </button>
    </span>
  );
};

Icon.propTypes = {};

Icon.defaultProps = {};

export default Icon;
