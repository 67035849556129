import React from 'react';

import ReloadButton from "components/ReloadButton";

const ShowDetailReloadButtonContainer = ({onShowRefresh, onHomepageRefresh, selectedShowId, latestShowId, isFetching}) => {
  console.log(selectedShowId, latestShowId);
  // if we're on the homepage
  return selectedShowId === latestShowId
    ? <ReloadButton onClick={() => onHomepageRefresh()} loading={isFetching}/>
    : <ReloadButton onClick={() => onShowRefresh(selectedShowId)} loading={isFetching}/>
};

ShowDetailReloadButtonContainer.propTypes = {
};

ShowDetailReloadButtonContainer.defaultProps = {
};

export default ShowDetailReloadButtonContainer;
