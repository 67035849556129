import { getVisibleShow } from './visibleShows'

export const ticketSources = {
  UNKNOWN: "UNKNOWN",
  TICKETFLY: "TICKETFLY",
  FRONTGATE: "FRONTGATE",
  TICKETWEB: "TICKETWEB",
  TICKETMASTER: "TICKETMASTER",
  EVENTBRITE: "EVENTBRITE",
  AXS: "AXS",
  OTHER: "OTHER"
};

export const ticketAvailability = {
  UNAVAILABLE: "UNAVAILABLE",
  SOLDOUT: "SOLDOUT",
  NOONLINE: "NOONLINE",
  COMINGSOON: "COMINGSOON",

  PRESALE: "PRESALE",
  ONSALE: "ONSALE",
  ALMOSTOUT: "ALMOSTOUT",
};

export const getTicketObj = state => {
  let { tickets } = getVisibleShow(state);
  if (! tickets ) {
    tickets = {
      source: ticketSources.UNKNOWN,
      availability: ticketAvailability.UNAVAILABLE
    }
  }

  // if it's an old style ticket object
  if (tickets.source) {
    return {
      source: tickets.source,
      url: tickets.url,
      availability: tickets.availability,
      code: tickets.codes && tickets.codes[tickets.availability]
    }
  } else {
    let ticketDates = Object.keys(tickets)
      .sort()
      .filter(date => date <= (new Date()).toISOString());
    return tickets[ticketDates[ticketDates.length - 1]];
  }
};

export const getTicketInfo = state => {
  const tickets = getTicketObj(state);
  if (! tickets ) {
    return null;
  }

  let url;
  let available;
  let availability;
  let outputText;

  switch (tickets.source){
    case ticketSources.UNKNOWN:
      url = "http://www.spafford.net/tour";
      break;

    case ticketSources.TICKETFLY:
      url = `http://shareasale.com/r.cfm?b=234786&u=1714700&m=27601&urllink=${tickets.url}&afftrack=`;
      break;

    case ticketSources.FRONTGATE:
    case ticketSources.TICKETWEB:
    case ticketSources.TICKETMASTER:
    case ticketSources.EVENTBRITE:
    case ticketSources.AXS:
    case ticketSources.OTHER:
      url = tickets.url;
      break;

    default:
      throw new Error("Invalid ticket source");
  }

  switch (tickets.availability){

    case ticketAvailability.UNAVAILABLE:
      available = false;
      outputText = "Unavailable";
      availability = "Unavailable";
      break;
    case ticketAvailability.SOLDOUT:
      available = false;
      outputText = "Sold Out";
      availability = "Sold Out";
      break;
    case ticketAvailability.NOONLINE:
      available = false;
      outputText = "No Online Ticket Sales";
      availability = "No Online";
      break;
    case ticketAvailability.COMINGSOON: available = false;
      outputText = "Coming Soon";
      availability = "Coming Soon";
      break;

    case ticketAvailability.ALMOSTOUT:
      available = true;
      outputText = "Get Tickets: Few Left";
      availability = "Almost Out";
      break;
    case ticketAvailability.ONSALE:
      available = true;
      outputText = "Get Tickets";
      availability = "On Sale";
      break;
    case ticketAvailability.PRESALE:
      available = true;
      outputText = "Get Tickets: Pre-Sale";
      availability = "Pre-Sale";
      break;

    default:
      throw new Error("Invalid ticket availability");
  }

  let buyCode = tickets.code;
  return {url, available, outputText, buyCode, availability}
};