import React from 'react';
// import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import LoadingIcon from 'components/LoadingIcon';

import './LoadingNavButton.css';

const LoadingNavButton = (props) => {
  return (
    <span className="LoadingNavButton">
      <Button {...props}>
        <span className="icon"><LoadingIcon size={20} /></span>
      </Button>
    </span>
  )
};

LoadingNavButton.propTypes = {
};

LoadingNavButton.defaultProps = {
};

export default LoadingNavButton;
