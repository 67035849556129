import React from 'react';
import { Tooltip as BootstrapTooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Tooltip.css';

const Tooltip = ({id, text, placement, children, ...props}) => {
  let tooltip = <BootstrapTooltip id={id} {...props}>{text}</BootstrapTooltip>;
  return (
    <OverlayTrigger placement={placement} overlay={tooltip}>
      {children}
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['bottom', 'top', 'left', 'right']).isRequired,
  children: PropTypes.node.isRequired
};

Tooltip.defaultProps = {
  id: "ToolTip"
};

export default Tooltip;
