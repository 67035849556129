import { combineReducers } from 'redux';
import { REHYDRATE } from 'redux-persist';
import {
  INVALIDATE_SHOWS,
  SHOW_SUCCESS,
  SHOW_FAILURE,
  SHOW_REQUEST,
  SHOW_ID_SUCCESS,
  SHOW_ID_FAILURE,
  SHOW_ID_REQUEST,
  SET_SHOW_DATE_FILTER,
  CLEAR_SHOW_DATE_FILTER,
  ShowDateFilters,
  SET_VISIBLE_SHOW,
  SET_RECORDING_FILTER,
  CLEAR_RECORDING_FILTER,
  SET_STATE_FILTER,
  CLEAR_STATE_FILTER,
  SET_VENUE_FILTER,
  CLEAR_VENUE_FILTER,
  SET_YEAR_FILTER,
  CLEAR_YEAR_FILTER
} from 'actions/shows'

import { SET_SHOW_UPLOAD_BATCH, CLEAR_SHOW_UPLOAD_BATCH } from "actions/admin";
import {transformShowsForDryRun} from "lib/fileUpload";

// every 12 hours
const CACHE_TIMEOUT = 12 * 60 * 60;

function initialShowState(){
  return {
    isFetching: false,
    isError: false,
    didInvalidate: false,
    items: []
  }
}

function list(state = initialShowState(), action) {
  switch (action.type) {
    case INVALIDATE_SHOWS:
      return Object.assign({}, state, {
        didInvalidate: true,
        isError: false
      });

    case SHOW_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case SHOW_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        items: action.response,
        lastUpdate: action.receivedAt
      });
    case SHOW_ID_SUCCESS: {
      const items = (state.items && state.items.slice(0)) || [];
      action.response.forEach(newShow => {
        const index = items.findIndex(old => old._id === newShow._id);
        if (index < 0) {
          items.push(newShow)
        } else {
          items[index] = newShow
        }
      });
      return Object.assign({}, state, {
        items: items
      });
    }
    case SHOW_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });
    case SET_SHOW_UPLOAD_BATCH: {
      const items = state.items.filter(show => show._id.substr(0,7) !== "dry-run");
      const transformedBatch = transformShowsForDryRun(action.showBatch);
      transformedBatch.forEach(newShow => {
        const index = items.findIndex(old => old._id === newShow._id);
        if (index < 0) {
          items.push(newShow)
        } else {
          items[index] = newShow
        }
      });
      return Object.assign({}, state, {
        items: items
      });
    }
    case CLEAR_SHOW_UPLOAD_BATCH: {
      const items = state.items.filter(show => show._id.substr(0,7) !== "dry-run");
      return Object.assign({}, state, {
        items: items
      })
    }
    case REHYDRATE:
      if ( ! action.payload || ! action.payload.list ) {
        return state;
      }
      if ( ! action.payload.list.lastUpdate ) {
        console.warn("no lastUpdate in payload, clearing cache");
        return initialShowState();
      }
      const dateDiffMilli = (new Date() - new Date(action.payload.list.lastUpdate));
      const timeDiffSec = dateDiffMilli / 1000;
      if (timeDiffSec > CACHE_TIMEOUT) {
        console.warn("Cache has timed out, clearing cache");
        return initialShowState();
      }
      console.warn(`Time Remaining: ${CACHE_TIMEOUT - timeDiffSec} seconds`);
      return Object.assign({}, action.payload.list);
    default:
      return state;
  }
}

function dateFilter(state = ShowDateFilters.CURRENT, action){
  switch (action.type){
    case SET_SHOW_DATE_FILTER:
      return action.filter;
    case CLEAR_SHOW_DATE_FILTER:
      return null;
    default:
      return state;
  }
}
function recordingFilter(state = null, action){
  switch (action.type){
    case SET_RECORDING_FILTER:
      return action.filter;
    case CLEAR_RECORDING_FILTER:
      return null;
    default:
      return state;
  }
}
function stateFilter(state = null, action){
  switch (action.type){
    case SET_STATE_FILTER:
      return action.filter;
    case CLEAR_STATE_FILTER:
      return null;
    default:
      return state
  }
}
function venueFilter(state = null, action){
  switch (action.type){
    case SET_VENUE_FILTER:
      return action.filter;
    case CLEAR_VENUE_FILTER:
      return null;
    default:
      return state
  }
}
function yearFilter(state = null, action){
  switch (action.type){
    case SET_YEAR_FILTER:
      return action.filter;
    case CLEAR_YEAR_FILTER:
      return null;
    default:
      return state
  }
}

//TODO: Make the default 'latest' and then setup something to parse that
function selected(state = {}, action){
  switch (action.type) {
    case SET_VISIBLE_SHOW:
      if ( state.date === action.date && state.index === action.index ) {
        return state
      }
      return {
        date: action.date,
        index: action.index
      };
    case SHOW_ID_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case SHOW_ID_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        lastUpdate: action.receivedAt
      });
    case SHOW_ID_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.response,
        didInvalidate: false
      });
    default:
      return state;
  }
}

const showsReducers = combineReducers({
  list,
  dateFilter,
  recordingFilter,
  stateFilter,
  venueFilter,
  yearFilter,
  selected
});

export default showsReducers;
