import { getActiveRecording } from "selectors/mediaPlayer/mediaPlayer";
import { getYoutubeEmbedUrl } from "lib/youTube";

export const getActiveYouTubeContent = (state) => {
  const activeRecording = getActiveRecording(state);

  return activeRecording.source === "YouTube"
    ? activeRecording.recordingData.content
    : null
};

export const getActiveYouTubeUrl = (state) => {
  const activeRecording = getActiveRecording(state);
  if ( ! activeRecording ) {
    return null;
  }

  return activeRecording.recordingData.url;
};

export const getActiveYouTubeEmbedUrl = (state) => {
  const url = getActiveYouTubeUrl(state);

  if ( ! url ) {
    return ""
  }

  return getYoutubeEmbedUrl(url);
};
