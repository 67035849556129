import React from 'react';
import PropTypes from 'prop-types';

import './Guest.css';

const Guest = ({firstName, lastName, band, instrument}) => (
  <span className="Guest">
    With: {firstName} {lastName}{band ? ` from ${band}` : ''}{instrument ? ` on ${instrument}` : ''}
  </span>
);

Guest.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  band: PropTypes.string,
  instrument: PropTypes.string
};

Guest.defaultProps = {};

export default Guest
