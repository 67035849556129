import {
  runCalculate as runTgCalculate,
  setNextShow as setTgNextShow
} from 'actions/tourGamesAdmin/';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFetching as fetchingSelector,
  isError as errorSelector,
  isCalculateSuccess as calcSuccessSelector,
  isSetNextShowError as nextShowErrSelector,
  isSetNextShowSuccess as nextshowSuccessSelector
} from 'selectors/tourGamesAdmin';

export const useTourGamesAdmin = () => {
  const dispatch = useDispatch();

  const isFetching = useSelector(fetchingSelector);
  const isError = useSelector(errorSelector);
  const isCalculateSuccess = useSelector(calcSuccessSelector);
  const isSetShowError = useSelector(nextShowErrSelector);
  const isSetShowSuccess = useSelector(nextshowSuccessSelector);

  const runCalculate = (tourId, showId) =>
    dispatch(runTgCalculate(tourId, showId));

  const setNextshow = (tourId, showId) =>
    dispatch(setTgNextShow(tourId, showId));

  return {
    isFetching,
    isError,
    isCalculateSuccess,
    isSetShowError,
    isSetShowSuccess,
    runCalculate,
    setNextshow
  };
};
