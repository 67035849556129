import {
  TOUR_GAMES_CREATE_TICKET_REQUEST,
  TOUR_GAMES_CREATE_TICKET_SUCCESS,
  TOUR_GAMES_CREATE_TICKET_FAILURE,
  TOUR_GAMES_GET_OTHER_USER_TICKET_REQUEST,
  TOUR_GAMES_GET_OTHER_USER_TICKET_SUCCESS,
  TOUR_GAMES_GET_OTHER_USER_TICKET_FAILURE,
  TOUR_GAMES_GET_TICKET_REQUEST,
  TOUR_GAMES_GET_TICKET_SUCCESS,
  TOUR_GAMES_GET_TICKET_FAILURE,
  TOUR_GAMES_CREATE_PICKS_REQUEST,
  TOUR_GAMES_CREATE_PICKS_SUCCESS,
  TOUR_GAMES_CREATE_PICKS_FAILURE,
  TOUR_GAMES_GET_NEXT_SHOW_REQUEST,
  TOUR_GAMES_GET_NEXT_SHOW_SUCCESS,
  TOUR_GAMES_GET_NEXT_SHOW_FAILURE,
  TOUR_GAMES_INVALIDATE,
  RESET_ERROR,
  TOUR_GAMES_GET_TOUR_REQUEST,
  TOUR_GAMES_GET_TOUR_SUCCESS,
  TOUR_GAMES_GET_TOUR_FAILURE
} from 'actions/tourGames';

function initialTourGamesState() {
  return {
    isFetching: false,
    isError: false,
    isCreateError: false,
    didInvalidate: false,
    userTickets: [],
    isFetchTourError: false,
    tour: []
  };
}

// const MOCK_CREATE_TICKET = {
//   gameType: 'SpaffnerdsTourGames',
//   showPicks: [],
//   ticketNumber: 1,
//   tourId: 'TEST_TOURID',
//   userId: 68
// }

// const MOCK_GET_TICKET = {
//   gameType: 'SpaffnerdsTourGames',
//   showPicks: [],
//   ticketNumber: 1,
//   tourId: 'TEST_TOURID',
//   userId: 68
// }

// const MOCK_UPDATE_TICKET = {
//   gameType: 'SpaffnerdsTourGames',
//   showPicks:
// [
//   ...state?.ticket?.showPicks,
//   { picks: {set1: 'all-in',
//   set2:'weasel',
//   encore: 'walls'}, showId: '2021-09-01', score: 0 }
// ]
//   ticketNumber: 1,
//   tourId: 'TEST_TOURID',
//   totalScore:0,
//   userId: 68
// }

function tourGames(state = initialTourGamesState(), action) {
  switch (action.type) {
    case TOUR_GAMES_CREATE_TICKET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case TOUR_GAMES_CREATE_TICKET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        ticket: action.response,
        lastUpdate: action.receivedAt
      });
    case TOUR_GAMES_CREATE_TICKET_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        isCreateError: true
      });
    case TOUR_GAMES_GET_TICKET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case TOUR_GAMES_GET_TICKET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        ticket: action.response,
        lastUpdate: action.receivedAt
      });
    case TOUR_GAMES_GET_TICKET_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });

    case TOUR_GAMES_GET_OTHER_USER_TICKET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case TOUR_GAMES_GET_OTHER_USER_TICKET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        userTickets: [...state?.userTickets, action.response],
        lastUpdate: action.receivedAt
      });
    case TOUR_GAMES_GET_OTHER_USER_TICKET_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });
    case TOUR_GAMES_CREATE_PICKS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case TOUR_GAMES_CREATE_PICKS_SUCCESS:
      const existingPick = state?.ticket?.showPicks?.find(
        (showPick) => showPick.showId === action.response.showId
      );
      const showPicks = existingPick
        ? state?.ticket?.showPicks?.map((showPick) =>
            showPick.showId === action.response.showId
              ? { ...action.response }
              : showPick
          )
        : [...state?.ticket?.showPicks, { ...action.response }];

      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        ticket: {
          ...state.ticket,
          showPicks
        },
        lastUpdate: action.receivedAt
      });
    case TOUR_GAMES_CREATE_PICKS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        isCreateError: true
      });
    case TOUR_GAMES_GET_NEXT_SHOW_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        didInvalidate: false
      });
    case TOUR_GAMES_GET_NEXT_SHOW_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        didInvalidate: false,
        show: action.response,
        lastUpdate: action.receivedAt
      });
    case TOUR_GAMES_GET_NEXT_SHOW_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: false
      });
    case RESET_ERROR:
      return Object.assign({}, state, {
        isError: false,
        isCreateError: false
      });
    case TOUR_GAMES_INVALIDATE: {
      return Object.assign({}, state, {
        ...initialTourGamesState(),
        didInvalidate: true
      });
    }
    case TOUR_GAMES_GET_TOUR_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetchTourError: false
      });
    case TOUR_GAMES_GET_TOUR_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchTourError: false,
        tour: action.response
      });
    case TOUR_GAMES_GET_TOUR_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchTourError: true,
        tour: []
      });
    default:
      return state;
  }
}

export default tourGames;
