import React from 'react';
import PropTypes from 'prop-types';

import DropDownFilter from '../DropDownFilter';

const VenueFilter = ({onSelect, filter, venues}) => {
  let keys = [
    "Any Venue",
    ...venues
  ];
  let values = [
    null,
    ...venues
  ];

  //TODO: update
  let index = !filter ? 0 : values.findIndex(value => value === filter);
  index = index < 0 ? 0 : index;

  return (
    <DropDownFilter
      before="at"
      text={keys[index]}
      onSelect={onSelect}
      keys={keys}
      values={values}
    />
  )
};


VenueFilter.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string,
  venues: PropTypes.array.isRequired
};

VenueFilter.defaultProps = {
  filter: null
};


export default VenueFilter;
