import { API_POST } from 'middleware/api/post';

export const TOUR_GAMES_SET_NEXT_SHOW_REQUEST =
  'TOUR_GAMES_SET_NEXT_SHOW_REQUEST';
export const TOUR_GAMES_SET_NEXT_SHOW_SUCCESS =
  'TOUR_GAMES_SET_NEXT_SHOW_SUCCESS';
export const TOUR_GAMES_SET_NEXT_SHOW_FAILURE =
  'TOUR_GAMES_SET_NEXT_SHOW_FAILURE';

/**
 * Calls the API endpoint to set the next show for tour gamesgit stat
 * @param {String} tourId
 * @param {String} showId
 * @returns
 */
export function setNextShow(tourId, showId) {
  return {
    [API_POST]: {
      authenticated: true,
      payload: {
        showId
      },
      endpoint: `/tourGames/admin/${tourId}/nextShow`,
      shouldDispatch: function (store) {
        return true;
      },
      types: [
        TOUR_GAMES_SET_NEXT_SHOW_REQUEST,
        TOUR_GAMES_SET_NEXT_SHOW_SUCCESS,
        TOUR_GAMES_SET_NEXT_SHOW_FAILURE
      ]
    }
  };
}
