// middleware/api.js

function getCookieValue(a) {
  let b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

export function getToken() {
  return typeof window !== 'undefined' ? getCookieValue('jwt') : undefined;
}

// there are 3 api endpoints we care about
// BASE_URL: The original API running off the express server
export const BASE_URL = process.env.REACT_APP_SPAFFNERDS_API;
// BASE_BETA_URL: The BETA api, tested, proven, but maybe not quite ready for prod
export const BASE_BETA_URL = process.env.REACT_APP_SPAFFNERDS_BETA_API;
// BASE_ALPHA_URL: The ALPHA api, bleeding edge, usually for testing only
export const BASE_ALPHA_URL = process.env.REACT_APP_SPAFFNERDS_ALPHA_API;

export function callApi(
  endpoint,
  authenticated,
  fetchConfig,
  betaEndpoint,
  alphaEndpoint
) {
  // let token = localStorage.getItem('access_token') || null;
  let token = getToken() || null;
  let config = fetchConfig || {};

  if (authenticated) {
    if (token) {
      const authorizationHeader = 'Authorization';
      const bearerTokenString = `Bearer ${token}`;
      if (config.headers) {
        config.headers[authorizationHeader] = bearerTokenString;
      } else {
        config.headers = { [authorizationHeader]: bearerTokenString };
      }
    } else {
      return Promise.reject({
        error: new Error(
          'Authenticated request specified but no saved token available.'
        )
      });
    }
  }

  // URL precedence: If alpha, use alpha, beta, use beta, otherwise use old
  let url;
  if (BASE_ALPHA_URL && alphaEndpoint) {
    url = `${BASE_ALPHA_URL}${alphaEndpoint}`;
  } else if (BASE_BETA_URL && betaEndpoint) {
    url = `${BASE_BETA_URL}${betaEndpoint}`;
  } else if (!BASE_URL || !endpoint) {
    throw new Error(
      `Must have a valid BASE_URL and endpoint to call api: BU: ${BASE_URL}, EP: ${endpoint}`
    );
  } else {
    url = `${BASE_URL}${endpoint}`;
  }

  return fetch(url, config).then((response) => {
    if (!response.ok) {
      let error = {
        status: response.status,
        responseType: response.type,
        url: response.url,
        error: new Error(response.statusText)
      };
      return Promise.reject(error);
    }
    return response.json();
  });
}
