import React from 'react';
import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';
import PropTypes from 'prop-types';

const TourGamesPicksHeader = ({ show }) => {
  return (
    <>
      <DetailNavRow socialSharing={false}>
        <span>Making Picks For</span>
      </DetailNavRow>
      {show?.showId && (
        <DetailSlugRow>
          <div>{show.date.substr(0, 10)}</div>
          <span>{`${show.venue.city}, ${show.venue.state} @ ${show.venue.name}`}</span>
        </DetailSlugRow>
      )}
    </>
  );
};

TourGamesPicksHeader.propTypes = {
  show: PropTypes.object.isRequired
};

export default TourGamesPicksHeader;
