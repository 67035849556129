import React from 'react';
import PropTypes from 'prop-types';

import './SortButtons.css';
import SortButton from "components/UserDetailView/UserSongStats/SortButtons/SortButton";

const SortButtons = ({sortType, onSortClick}) => {
  return (
    <div className="SortButtons">
      <SortButton
        sortType="numeric"
        startDir="desc"
        isActive={sortType === "numeric"}
        onSortClick={onSortClick}
      />

      <SortButton
        sortType="alpha"
        startDir="asc"
        isActive={sortType === "alpha"}
        onSortClick={onSortClick}
      />
    </div>
  )
};

SortButtons.propTypes = {
  sortType: PropTypes.oneOf(['alpha', 'numeric']),
  onSortClick: PropTypes.func
};

SortButtons.defaultProps = {
  sortType: 'numeric'
};

export default SortButtons;
