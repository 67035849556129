import React from 'react';
import PropTypes from 'prop-types';

import YouTubeTitle from 'components/MediaPlayer/MediaPlayerHeading/YouTubeTitle/index';

const MPYouTubeTitleContainer = ({showDate, content, title, showLink, source, ...props}) => {
  if (source !== 'YouTube') {
    return null;
  }
  return (
    <YouTubeTitle
      showDate={showDate}
      content={content}
      title={title}
      showLink={showLink}
      {...props}
    />
  )
};

MPYouTubeTitleContainer.propTypes = {
  showDate: PropTypes.string.isRequired,
  showLink: PropTypes.string,
  content: PropTypes.node
};

MPYouTubeTitleContainer.defaultProps = {};

export default MPYouTubeTitleContainer;
