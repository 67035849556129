import React from 'react';
import PropTypes from 'prop-types';

import './OpenButton.css';

const OpenButton = ({isOpen}) => {
  return (
    <span className='OpenButton'>
      { isOpen
          ? <i className="fa fa-chevron-circle-down"/>
          : <i className="fa fa-chevron-circle-right"/>
      }
    </span>
)
};

OpenButton.propTypes = {
  isOpen: PropTypes.bool
};

OpenButton.defaultProps = {
  isOpen: false
};

export default OpenButton;
