import React, { useState, useEffect } from 'react';
import { Grid } from 'react-bootstrap';
import TourGamesPicksForm from './TourGamesPicksForm';
import TourGamesPicksHeader from './TourGamesPicksHeader';
import './TourGamesPicksView.css';
import useTourGames from 'hooks/useTourGames';
import { useLocation, useParams } from 'react-router-dom';

const TourGamesPicksView = () => {
  const { showId } = useParams();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const { getGameShow } = useTourGames();
  const show = getGameShow(showId);

  useEffect(() => {
    setIsEdit(location.pathname.includes('edit'));
  }, [location]);

  return (
    <Grid className='TourGamesPicksView'>
      <TourGamesPicksHeader show={show} />
      <TourGamesPicksForm show={show} isEdit={isEdit} setIsEdit={setIsEdit} />
    </Grid>
  );
};

export default TourGamesPicksView;
