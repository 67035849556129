import YouTubeBodyContainer from './YouTubeBodyContainer';
import { connect } from 'react-redux';
import { getActiveRecordingTitle, getActiveYouTubeEmbedUrl } from "selectors/mediaPlayer/index";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    url: getActiveYouTubeEmbedUrl(state),
    title: getActiveRecordingTitle(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(YouTubeBodyContainer);
