import SongDetailViewContainer from './SongDetailViewContainer';
import { connect } from 'react-redux';
import {
  getSelectedSongId,
  getPrevSong,
  getNextSong,
  getSongStat,
  getSongStatError
} from 'selectors/songs';

import {fetchSongStats, setVisibleSong} from 'actions/songs';

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      onIdChange: id => {
        dispatch(setVisibleSong(id, 0));
        dispatch(fetchSongStats(id))
      }
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
      selectedSongId: getSelectedSongId(state),
      songStat: getSongStat(state),
      songStatError: getSongStatError(state),
      next: getNextSong(state),
      prev: getPrevSong(state)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SongDetailViewContainer);
