export * from './async_middleware';
export * from './filters';

export const INVALIDATE_SHOWS = 'INVALIDATE_SHOWS';
export const SET_VISIBLE_SHOW = 'SET_VISIBLE_SHOW';

export function invalidateShows(){
  return {
    type: INVALIDATE_SHOWS
  }
}
export function setVisibleShow(date, index = 0) {
  return {
    type: SET_VISIBLE_SHOW,
    date: date,
    index: index
  }
}
