import React from 'react';
import Helmet from 'react-helmet';
import { Grid } from 'react-bootstrap';

// import PropTypes from 'prop-types';

import DetailNavRow from 'components/DetailView/DetailNavRow';
import DetailSlugRow from 'components/DetailView/DetailSlugRow';
import DetailContentRow from 'components/DetailView/DetailContentRow';
import StatLabel from 'components/StatLabel';
import LoadingIcon from 'components/LoadingIcon';

import CoverOrOriginalRow from '../CoverOrOriginalRow';
import PlayedDatesRow from '../PlayedDatesRow';

import './LoadingSongDetailView.css';

const LoadingSongDetailView = () => {
  return (
    <Grid className="LoadingSongDetailView">
      <Helmet>
        <title>Loading Song</title>
      </Helmet>
      <DetailNavRow nextUrl="#"
                    nextText={<LoadingIcon size={20}/>}
                    prevUrl="#"
                    prevText={<LoadingIcon size={20}/>}
                    placement="song"
      >
        <span><LoadingIcon size={20}/> Loading <LoadingIcon size={20}/></span>
      </DetailNavRow>
      <CoverOrOriginalRow band="..." cover={false}/>
      <DetailSlugRow>
        <StatLabel leadText="Played At" count={-1}/>
      </DetailSlugRow>
      <div className="SongStatsRow">
        <hr />
        <DetailContentRow>
          <h3 className="text-center"><StatLabel leadText="Stats" count={-1}/></h3>
        </DetailContentRow>
      </div>
      <PlayedDatesRow firstPlayedDate={<LoadingIcon size={20}/>}
                      lastPlayedDate={<LoadingIcon size={20}/>}
                      hasLyrics={false}
      />
      <div className="PlayedAtRow">
        <hr />
        <DetailContentRow heading="Played At">
          <LoadingIcon size={20} />
        </DetailContentRow>
      </div>
      <div className="LyricsRow">
        <hr />
        <DetailContentRow heading="Lyrics" anchor="lyrics">
          <LoadingIcon size={20} />
        </DetailContentRow>
      </div>
    </Grid>
  )
};

LoadingSongDetailView.propTypes = {
};

LoadingSongDetailView.defaultProps = {
};

export default LoadingSongDetailView;
