import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { roundToPlaces } from 'lib/math';
import StatLabel from 'components/StatLabel';

import './GapCountsRow.css';

const GapCountsRow = ({playStats}) => {
  const lastPlayed = playStats.lastPlayed;
  const avgGap = !playStats.avgGap || playStats.avgGap === 0 ? -1 : playStats.avgGap;
  const lastThreeGap = playStats.lastThreeGap;
  const longestGap = playStats.longestGap === 0 || lastPlayed > playStats.longestGap ? lastPlayed : playStats.longestGap;
  return (
    <Row className="text-center">
      <Col md={6} lg={6} sm={6} xs={12}>
        <h3><StatLabel leadText="Longest Gap" count={longestGap} /></h3>
      </Col>
      <Col md={6} lg={6} sm={6} xs={12}>
        <h3><StatLabel leadText="Average Gap" count={roundToPlaces(avgGap, 2)} /></h3>
      </Col>
      <Col md={6} lg={6} sm={6} xs={12}>
        <h3><StatLabel leadText="Current Gap" count={lastPlayed} /></h3>
      </Col>
      <Col md={6} lg={6} sm={6} xs={12}>
        <h3><StatLabel leadText="Last 3 Show Gap" count={roundToPlaces(lastThreeGap, 2)} /></h3>
      </Col>
    </Row>
  )
};

GapCountsRow.propTypes = {
  playStats: PropTypes.shape({
    lastPlayed: PropTypes.number,
    avgGap: PropTypes.number,
    lastThreeGap: PropTypes.number,
    longestGap: PropTypes.number
  }).isRequired
};

GapCountsRow.defaultProps = {};

export default GapCountsRow;
