import React from 'react';
import PropTypes from 'prop-types';

import YouTubeBody from 'components/MediaPlayer/MediaPlayerBody/YouTubeBody/index';

const YouTubeBodyContainer = ({url, title}) => (
  <YouTubeBody url={url} title={title}/>
);

YouTubeBodyContainer.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string
};

YouTubeBodyContainer.defaultProps = {
};

export default YouTubeBodyContainer;
