import React from 'react'
import { Row, Col } from 'react-bootstrap';
import NextLinkButton from './NextLinkButton';
import PrevLinkButton from './PrevLinkButton';
import LoadingNavButtonContainer from "containers/LoadingNavButtonContainer";
import SocialSharing from '../../SocialSharing';
import PropTypes from 'prop-types'

import './DetailNavRow.css'

const DetailNavRow = ({prevUrl, prevIcon, prevText,
                       nextUrl, nextIcon, nextText,
                       className, children,
                       placement, reloadButton, socialSharing, ...props}) => (
  <Row className={`${!className ? 'DetailNavRow' : 'DetailNavRow ' + className}`} {...props}>
    <Col>
      <Row>
        <Col xs={2} sm={3} md={3} lg={3}>
          { ! prevUrl
            ? <LoadingNavButtonContainer placement={placement} className="link-button" /> :
            <PrevLinkButton className="link-button" url={prevUrl} icon={prevIcon} text={prevText} />
          }
       </Col>
        <Col xs={8} sm={6} md={6} lg={6} className="text-center">
          <h1>{children}</h1>
          { socialSharing &&
          <SocialSharing />
          }
          { reloadButton }
        </Col>
        <Col xs={2} sm={3} md={3} lg={3}>
          { ! nextUrl
            ? <LoadingNavButtonContainer placement={placement} className="pull-right link-button"/> :
            <NextLinkButton url={nextUrl} icon={nextIcon} text={nextText}
                            className="pull-right link-button"/>
          }
       </Col>
      </Row>
    </Col>
  </Row>
);

DetailNavRow.propTypes = {
  prevUrl: PropTypes.string,
  prevIcon: PropTypes.node,
  prevText: PropTypes.node,
  nextUrl: PropTypes.string,
  nextIcon: PropTypes.node,
  nextText: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  socialSharing: PropTypes.bool
};

DetailNavRow.defaultProps = {
  socialSharing: true
};

export default DetailNavRow
