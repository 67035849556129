import ShowBulkUploadViewContainer from './ShowBulkUploadViewContainer';
import { connect } from 'react-redux';

import { displayGrowl, growlLevels } from "lib/growl";

import {isAdmin} from "selectors/user";
import {setShowUploadBatch, clearShowUploadBatch, postShowBatch, invalidateshowBatch } from "actions/admin";
import {
  showBulkUploadBatchFilename,
  showBulkUploadBatch,
  isPostingShowBatch,
  isPostingShowBatchError,
  isPostingShowBatchSuccess
} from "selectors/admin";

import { invalidateShows, fetchShows } from "actions/shows";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onJsonLoaded: (file, json) => {
      dispatch(invalidateshowBatch());
      dispatch(setShowUploadBatch(file.name, json))
    },
    onClear: () => {
      dispatch(invalidateshowBatch());
      dispatch(clearShowUploadBatch())
    },
    onUpload: (showBatch) => dispatch(postShowBatch(showBatch)),
    onUploading: () => {
      displayGrowl("Uploading Show Batch", growlLevels.INFO);
    },
    onUploadSuccess: () => {
      displayGrowl("Show Batch uploaded successfully.", growlLevels.SUCCESS);
      dispatch(invalidateshowBatch());
      dispatch(clearShowUploadBatch());
      dispatch(invalidateShows());
      dispatch(fetchShows());
    },
    onUploadError: () => {
      displayGrowl("Error uploading Show Batch", growlLevels.WARN);
      dispatch(invalidateshowBatch());
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    showBatch: showBulkUploadBatch(state),
    filename: showBulkUploadBatchFilename(state),
    isAdmin: isAdmin(state),
    isUploading: isPostingShowBatch(state),
    isUploadSuccess: isPostingShowBatchSuccess(state),
    isUploadError: isPostingShowBatchError(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowBulkUploadViewContainer);
