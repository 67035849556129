import React from 'react';
import PropTypes from 'prop-types';

import UserDetailView from 'components/UserDetailView';
import LoadingUserDetailView from "components/UserDetailView/LoadingUserDetailView";
import ErrorView from "components/ErrorView";

const UserDetailViewContainer = ({userStats, userStatsError, onLoadUserStats, ...props}) => {
  // if you're fetching
  if (! userStats || userStats.isFetching || userStats.didInvalidate) {
    onLoadUserStats();
    return <LoadingUserDetailView />
  } else if ( userStatsError ){
    const { error, status, responseType, url } = userStatsError;
    return (
      <ErrorView
        status={status}
        message={error.message}
        stack={error.stack}
        responseType={responseType}
        url={url}
      />
    )

  } else {
    return <UserDetailView {...props} />
  }
};

UserDetailViewContainer.propTypes = {
  hasStats: PropTypes.bool
};

UserDetailViewContainer.defaultProps = {
  hasStats: false
};

export default UserDetailViewContainer;
