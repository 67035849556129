import React from 'react';
import PropTypes from 'prop-types';

import './SongStatSummary.css';

const SongStatSummary = ({headline, lastPlayed, avgGap, longestGap}) => {
  let roundedAvg = Math.round(avgGap * 100) / 100 || "N/A";
  return (
    <div className="SongStatSummary">
      <h4>{headline}</h4>
      <ul className="list-inline">
        <li>Cur. <span className="count">{lastPlayed}</span></li>
        <li>Avg. <span className="count">{roundedAvg}</span></li>
        <li>Lng. <span className="count">{longestGap}</span></li>
      </ul>
    </div>
  )
};

SongStatSummary.propTypes = {
  lastPlayed: PropTypes.number.isRequired,
  avgGap: PropTypes.number.isRequired,
  longestGap: PropTypes.number.isRequired,
  headline: PropTypes.node
};

SongStatSummary.defaultProps = {
  headline: "Your Gaps"
};

export default SongStatSummary;
