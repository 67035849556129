import React from 'react';
// import PropTypes from 'prop-types';
import LoadingNavButton from 'components/DetailView/DetailNavRow/LoadingNavButton';

const LoadingNavButtonContainer = ({isLoading, ...props}) => (
  isLoading ? <LoadingNavButton {...props} /> : ""
);

LoadingNavButtonContainer.propTypes = {
};

LoadingNavButtonContainer.defaultProps = {
};

export default LoadingNavButtonContainer;
