import AddRecordingRowContainer from './AddRecordingRowContainer';
import { connect } from 'react-redux';
import { getVisibleShowId } from "selectors/shows";
import * as userRecordingsSelectors from "selectors/userRecordings";
import { clearAddRecording } from "actions/recordings";
import { fetchUserRecordings } from "actions/userRecordings";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddRecording: (id, payload) => {
      dispatch(fetchUserRecordings(id, payload));
    },
    onCancel: () => dispatch(clearAddRecording()),
    onSuccess: () => dispatch(clearAddRecording())
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    visibleShowId: getVisibleShowId(state),
    isError: userRecordingsSelectors.isError(state),
    isFetching: userRecordingsSelectors.isFetching(state),
    isSuccess: userRecordingsSelectors.isSuccess(state),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddRecordingRowContainer);
