import React from 'react';
import { Button } from 'react-bootstrap';
import useUser from 'hooks/useUser';
import { getForumUrl } from 'selectors/settings';
import useTourGames from 'hooks/useTourGames';
import { useSelector } from 'react-redux';
import Link from 'components/Link';
import { TEST_TOUR_GAMES_ID } from '../../../../constants';
import './PlayButton.css';

const PlayButton = () => {
  const { isUserLoggedIn, userProfile } = useUser();
  const { ticket, createTourTicket } = useTourGames();
  const forumUrl = useSelector(getForumUrl);

  const handlePlayClick = () => {
    if (!ticket) {
      createTourTicket(
        userProfile._id,
        userProfile.username,
        TEST_TOUR_GAMES_ID
      );
    }
  };

  if (isUserLoggedIn) {
    return (
      <div className='PlayButton'>
        {/* <Link url={`/tourgames/play/${gameShow.showId}`} id="play-tour-games" title="Play"> */}
        <Button bsSize='large' onClick={() => handlePlayClick()}>
          <span className='icon'>
            <i className='fa fa-gamepad' aria-hidden='true'></i>
          </span>
          <span className='text'>Play</span>
        </Button>
        {/* </Link> */}
      </div>
    );
  }

  return (
    <div className='PlayButton'>
      <Link className='PlayButton' url={`${forumUrl}/login`}>
        <Button bsSize='large'>
          <span className='text'>Login to Play</span>
        </Button>
      </Link>
    </div>
  );
};

export default PlayButton;
