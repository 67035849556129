import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import AttendanceListRow from 'components/ShowDetailView/AttendanceListRow';
import AttendanceListErrorRow from 'components/ShowDetailView/AttendanceListErrorRow';

class AttendanceListContainer extends Component {
  constructor(props){
    super(props);
    const { updateAttendance, showId } = this.props;
    updateAttendance && updateAttendance(showId);
  }

  componentWillReceiveProps(nextProps){
    const { showId: oldShowId, updateAttendance, invalidateAttendanceList: oldInvalidate} = this.props;
    const { showId: newShowId, invalidateAttendanceList: newInvalidate } = nextProps;
    if ( ( oldShowId !== newShowId )
         || ( !oldInvalidate && newInvalidate )){
      updateAttendance && updateAttendance(newShowId);
    }
  }


  render(){
    const { attendance, error, date, today } = this.props;
    if (! attendance){
      if (! error) {
        return <Loading />
      } else {
        return <AttendanceListErrorRow />
      }
    } else {
      return (
        <AttendanceListRow
          attendance={attendance}
          past={date < today}
        />
      )
    }
  }
}

AttendanceListContainer.propTypes = {
  updateAttendance: PropTypes.func,
  attendance: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string
    })
  ),
  date: PropTypes.string.isRequired,
  showId: PropTypes.string.isRequired,
  today: PropTypes.string.isRequired,
  invalidateAttendanceList: PropTypes.bool
};

AttendanceListContainer.defaultProps = {
  today: (new Date()).toISOString().substr(0,10)
};

export default AttendanceListContainer;
