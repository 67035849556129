import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { makeShowLink } from "lib/shows";

import Link from 'components/Link';
import FactBadge from 'components/FactBadge';

import './DateBadges.css';

const DateBadges = ({date, multiShowIndex, recordingCount, guests, songNotes, showNotes, noLink}) => {
  let shortDate = date.substr(0,10);
  let recDisabled = recordingCount === 0 ? "disabled" : "";

  let songNoteDisabled = songNotes.length === 0 ? "disabled" : "";
  let guestDisabled = guests.length === 0 ? "disabled" : "";
  let processedShowNotes = Object.keys(showNotes).reduce(function (str, k) {
    return (str ? str + ", " : "") + `${k}: ${showNotes[k]}`
  }, "");
  let showNoteDisabled = ! processedShowNotes || processedShowNotes.length === 0 ? "disabled" : "";

  const showLink = makeShowLink(shortDate, multiShowIndex);

  return (
   <Row className="DateBadges">
      <Col sm={6} xs={5} className="show-date">
        <Link url={noLink ? "#" : showLink}>{shortDate}</Link>
      </Col>
      <Col sm={6} xs={7} className="show-link">
        <FactBadge className={guestDisabled}
                       fa="fa-group"
                       tooltip={[...new Set(guests.map((guest) => `${guest.firstName} ${guest.lastName}`))].join(", ")}
        />
        <FactBadge className={songNoteDisabled}
                       fa="fa-comment-o"
                       tooltip={songNotes}
        />
        <FactBadge className={showNoteDisabled}
                       fa="fa-sticky-note-o"
                       tooltip={processedShowNotes}
        />
        <FactBadge className={recDisabled}
                       fa="fa-volume-up"
        />
      </Col>
    </Row>
    )
};

DateBadges.propTypes = {
  date: PropTypes.string.isRequired,
  recordingCount: PropTypes.number.isRequired,
  guests: PropTypes.array,
  songNotes: PropTypes.string,
  showNotes: PropTypes.object,
  noLink: PropTypes.bool
};

DateBadges.defaultProps = {
  recordingCount: 0,
  guests: [],
  songNotes: "",
  showNotes: {}
};

export default DateBadges;
