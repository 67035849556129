import { TEST_TOUR_GAMES_ID } from '../../constants';
import { API_GET } from 'middleware/api/get';

export const TOUR_GAMES_GET_LEADERBOARD_REQUEST =
  'TOUR_GAMES_GET_LEADERBOARD_REQUEST';
export const TOUR_GAMES_GET_LEADERBOARD_SUCCESS =
  'TOUR_GAMES_GET_LEADERBOARD_SUCCESS';
export const TOUR_GAMES_GET_LEADERBOARD_FAILURE =
  'TOUR_GAMES_GET_LEADERBOARD_FAILURE';

/**
 * Calls the API endpoint to fetch the leaderboard
 * @param {String} gameType
 * @returns
 */
export function fetchLeaderboard(
  tourId = TEST_TOUR_GAMES_ID,
  gameType = 'SpaffnerdsTourGames',
  onDemand = false
) {
  return {
    [API_GET]: {
      authenticated: false,
      endpoint: `/tourGames/${tourId}/${gameType}/leaderboard`,
      shouldDispatch: function (store) {
        const tgLeaderboard = store?.getState()?.tourGamesLeaderboard;
        if (!tgLeaderboard.leaderboard || onDemand) {
          return true;
        } else if (tgLeaderboard.isFetching) {
          return false;
        } else {
          return tgLeaderboard.didInvalidate;
        }
      },
      types: [
        TOUR_GAMES_GET_LEADERBOARD_REQUEST,
        TOUR_GAMES_GET_LEADERBOARD_SUCCESS,
        TOUR_GAMES_GET_LEADERBOARD_FAILURE
      ]
    }
  };
}
