import React from 'react';
import PropTypes from 'prop-types';

import './UserProfileContainer.css';

const UserProfileContainer = ({isLoggedIn, userProfile, forumUrl, children, ...props}) => {
  const childrenWithProps = React.Children.map(children, child => (
    React.cloneElement(child, { isLoggedIn, userProfile, forumUrl, ...props })
  ));
  return <div className="UserProfileContainer">{childrenWithProps}</div>
};

UserProfileContainer.propTypes = {
  isLoggedIn: PropTypes.bool,
  username: PropTypes.string,
  userslug: PropTypes.string,
  forumUrl: PropTypes.string,
  iconBgColor: PropTypes.string,
  iconText: PropTypes.string,
  pictureUrl: PropTypes.string
};

UserProfileContainer.defaultProps = {
  isLoggedIn: false,
  username: "Not Logged In",
  forumUrl: "https://forum.spaffnerds.com"
};

export default UserProfileContainer;
