export * from './async_showBatch';

export const SET_SHOW_UPLOAD_BATCH = 'SET_SHOW_UPLOAD_BATCH';
export const CLEAR_SHOW_UPLOAD_BATCH = 'CLEAR_SHOW_UPLOAD_BATCH';

export function setShowUploadBatch(filename, showBatch){
  return {
    type: SET_SHOW_UPLOAD_BATCH,
    filename: filename,
    showBatch: showBatch
  }
}

export function clearShowUploadBatch(){
  return {
    type: CLEAR_SHOW_UPLOAD_BATCH
  }
}