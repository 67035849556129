import React from 'react'
// import PropTypes from 'prop-types';

import NavigationLinksContainer from 'containers/UserProfileContainer/NavigationLinksContainer';
import ProfileLinksContainer from 'containers/UserProfileContainer/ProfileLinksContainer';

const DesktopNav = () => (
  <div>
    <NavigationLinksContainer className="nav navbar-nav" />
    <ProfileLinksContainer/>
  </div>
);

DesktopNav.propTypes = {
};

DesktopNav.defaultProps = {
};

export default DesktopNav
